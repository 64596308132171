import { Outlet } from "react-router";
import { MeshGradient } from "@mcvod-apps/ui-components";
import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router";
import { maintenanceMiddleware } from "@/utils/maintenance-check";


export const loader = (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    // Check for maintenance mode first
    const maintenanceCheck = maintenanceMiddleware(request);
    if (maintenanceCheck) return maintenanceCheck;

    return null;
  };

export default function CommonLayout() {
  return (
    <div className="min-h-screen w-full overflow-hidden relative">
      <div className="fixed inset-0 w-full h-full">
        <MeshGradient
          className="absolute inset-0"
          foreground="#1f2937"
          background="#030712"
          primary="#4338ca"
        />
      </div>
      <div className="relative z-10 min-h-screen w-full">
        <Outlet />
      </div>
    </div>
  );
}
