import React from "react";
import cn from "classnames";

const Card = ({ background, className, children }) => (
  <div className={cn(`z-0 m-auto w-full`, background, className)}>
    <div className="flex w-full items-center justify-center py-12">
      <div className="w-full max-w-md">{children}</div>
    </div>
  </div>
);

export default Card;
