import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { APP_NAME } from "@/utils/constants";
import Spinner from "@/components/common/Spinner";
import { Icon } from "@mcvod-apps/icons";

// Search
import { useSearchComplete } from "@/api/useSearchComplete";

// const searchResults = results.map(item => item.title.toLowerCase());

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

const SearchBar = () => {
  const navigate = useNavigate();
  const searchInput = useRef(null);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [noSuggestions, setNoSuggestions] = useState(false);
  const [search, setSearch] = useState(false);
  const [showClearSearch, setShowClearSearch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const debouncedQuery = useDebounce(value, 500);

  const queryInfo = useSearchComplete(debouncedQuery, shouldRenderSuggestions);

  function shouldRenderSuggestions(value) {
    return value.trim().length > 2;
  }

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
    noSuggestions,
  }) => (
    <div {...containerProps}>
      {children}
      <div className="mt-2 text-center text-sm text-gray-600">
        Press Enter to search <strong>{value}</strong>
      </div>
    </div>
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${value}`);
  };

  function handleFocus() {
    searchInput.current.focus();
    setShowClearSearch(false);
  }

  const renderInputComponent = (inputProps) => (
    <div className="relative">
      <div className="absolute inset-y-0 left-0 z-10 ml-2 flex h-auto w-6 items-center justify-center">
        <Icon
          name="search"
          weight="bold"
          size={20}
          alt="search"
          className="text-gray-500"
        />
      </div>
      <form onSubmit={handleSubmit}>
        <input type="search" {...inputProps} ref={searchInput} />
      </form>
      {queryInfo.isFetching ? (
        <div className="absolute  inset-y-0 right-0 z-10 mr-2 flex h-auto w-6 items-center justify-center">
          <Spinner size={6} />
        </div>
      ) : showClearSearch ? (
        <div className="absolute inset-y-0 right-0 z-10 mr-2 flex h-auto w-6 items-center justify-center">
          <button
            onClick={() => {
              setValue("");
              handleFocus();
            }}
          >
            <Icon
              name="close"
              weight="bold"
              size={20}
              alt="close"
              className="text-gray-500 hover:text-gray-300"
            />
          </button>
        </div>
      ) : null}
    </div>
  );

  function renderSuggestion(suggestion) {
    const suggestionType = suggestion.content;
    const isMovie = suggestionType === "movie";
    const isShow = suggestionType === "show";
    const isTopic = suggestionType === "topic";

    let classStyle = "suggestion-item-type";

    if (isMovie) {
      classStyle += " color1";
    } else if (isShow) {
      classStyle += " color2";
    } else {
      classStyle += " color3";
    }
    return (
      <div className="suggestion-item-container">
        <div className="suggestion-item-text">{suggestion.title}</div>
        {/* <div className={classStyle}>{suggestionType.toUpperCase()}</div> */}
      </div>
    );
  }

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    // setValue('')
  };

  return (
    <div>
      <Autosuggest
        suggestions={suggestions}
        inputProps={{
          placeholder: `Search ${APP_NAME}`,
          autoComplete: "off",
          value,
          onChange: (_event, { newValue, method }) => {
            setValue(newValue);
          },
        }}
        onSuggestionsFetchRequested={async ({ value }) => {
          queryInfo.isSuccess
            ? setSuggestions(
                queryInfo.data.suggestions.map((item) => ({
                  title: item.suggestion,
                  content: item.id,
                })),
              )
            : setSuggestions([]);

          if (!queryInfo.data) {
            setNoSuggestions(true);
          }
          setNoSuggestions(noSuggestions);
          if (value) {
            setShowClearSearch(true);
            return;
          }
          if (queryInfo.isError) {
            setSuggestions([]);
          }
        }}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={(event, { suggestion, method }) => {
          if (method === "enter") {
            event.preventDefault();
            // Set seleced query as keySearch value ( Consider using query param instead)
            localStorage.setItem("keySearch", value);
            // Go to navigate
            navigate(`/search?q=${value}`);
          }
          setValue(suggestion.title);
          setSearch(true);
          setIsLoading(false);
        }}
        getSuggestionValue={(suggestion) => suggestion.title}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderInputComponent={renderInputComponent}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
      {noSuggestions ? (
        <div className="no-suggestions">No suggestions</div>
      ) : null}
    </div>
  );
};

export default SearchBar;
