import { useRef, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { Icon } from "@mcvod-apps/icons";
import { IMG_URI } from "@/utils/constants";
import { useNavigate } from "react-router-dom";

interface Image {
  thumbnail: string;
}

interface LiveStreamButtonProps {
  onClick?: () => void;
  _detail?: string;
  _play: string;
  children?: React.ReactNode;
  images: Image;
  style?: React.CSSProperties;
  thumbnail?: string;
  title: string;
  slideNumber?: number;
  content_model?: string;
  pk: string;
}

export function LiveStreamButton({
  onClick,
  _detail,
  _play,
  title,
  style,
  children,
  thumbnail,
  images,
  slideNumber,
  content_model: contentModel,
  ...props
}: LiveStreamButtonProps) {
  const resizeTarget = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [dimensions, setDimensions] = useState<{
    height?: number;
    width?: number;
  }>({});
  const [initSize, setInitSize] = useState(false);

  const handleResize = debounce(() => {
    if (resizeTarget.current) {
      setDimensions({
        height: resizeTarget.current.offsetWidth,
        width: resizeTarget.current.offsetWidth,
      });
    }
  }, 500);

  useEffect(() => {
    if (!initSize && resizeTarget.current) {
      setInitSize(true);
      handleResize();
    }

    window.addEventListener("resize", handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener("resize", handleResize);
    };
  }, [initSize]);

  const handleThumbnailClick = () => {
    navigate(
      `/watch/${props.pk}?p_l=${_play}&title=${title}&type=${contentModel}`,
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      handleThumbnailClick();
    }
  };

  return (
    <div className="flex w-full flex-col" ref={resizeTarget}>
      <div className="relative h-auto cursor-pointer overflow-hidden rounded">
        {images?.thumbnail && (
          <div
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
            onClick={handleThumbnailClick}
            className="_LivestreamThumbnail"
          >
            <div
              className={`group relative flex items-center justify-center overflow-hidden rounded-full bg-cover bg-center bg-no-repeat after:absolute after:inset-0 after:transition after:duration-100 after:ease-in after:hover:bg-gray-800/80`}
            >
              <img
                src={images.thumbnail}
                alt={title}
                className="w-full object-cover transition-all duration-500 ease-out"
                style={{ height: dimensions.width }}
                onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                  e.currentTarget.src = IMG_URI;
                }}
              />
              <Icon
                name="play"
                weight="fill"
                alt="play"
                className="absolute z-10 h-[32px] w-[32px] text-white/80 opacity-0 transition-transform duration-200 ease-in-out hover:scale-150 hover:text-white hover:shadow-white/80 group-hover:opacity-100"
              />
            </div>
            {children && (
              <h4 className="pt-6 text-center text-xs uppercase tracking-wider text-gray-100 md:text-sm">
                {children}
              </h4>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
