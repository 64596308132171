import React from "react";
import cn from "classnames";

export default function Label(props) {
  const { name, as = "label", className, text, ...restProps } = props;

  const Element = as;

  return (
    <Element
      {...restProps}
      className={cn(`text-sm font-semibold text-gray-300`, className)}
    >
      {text}
    </Element>
  );
}
