import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
} from "react-router-dom";
// pages

import Login, {
  loader as loginLoader,
  action as loginAction,
} from "@/page/login/Login";
import Signup, { loader as signupLoader } from "@/page/signup/Signup";
import Verify, { loader as verifyLoader } from "@/page/signup/Verify";
import VerifySuccess, {
  loader as verifySuccessLoader,
} from "@/page/signup/VerifySuccess";
import VerifyRequest, {
  loader as verifyRequestLoader,
} from "@/page/signup/VerifyRequest";
import ResetPassword, {
  loader as resetPasswordLoader,
} from "@/page/resetPassword/ResetPassword";
import ResetSuccess, {
  loader as resetPasswordSuccessLoader,
} from "@/page/resetPassword/ResetSuccess";
import ResetConfirm, {
  loader as resetPasswordConfirmLoader,
} from "@/page/resetPassword/ResetConfirm";
import TrailerDetail from "@/page/Trailer";
import NotFound, { loader as notFoundLoader } from "@/page/404/NotFound";
import Discover, { loader as discoverLoader } from "@/page/discover/Discover";
import Movies, { loader as moviesLoader } from "@/page/movie/Movies";
import MovieDetail, {
  loader as moviesDetailLoader,
} from "@/page/movie/MovieDetail";
import Shows, { loader as showsLoader } from "@/page/show/Shows";
import { ShowDetail, loader as showDetailLoader } from "@/page/show/ShowDetail";
import Topic, { loader as topicsLoader } from "@/page/topic/Topic";
import TopicDetail, {
  loader as topicsDetailLoader,
} from "@/page/topic/TopicDetail";
import Kid, { loader as kidsLoader } from "@/page/kid/Kids";
import CollectionDetail, {
  loader as collectionsDetailLoader,
} from "@/page/CollectionDetail";
import LivestreamDetail, {
  loader as livestreamsDetailLoader,
} from "@/page/LivestreamDetail";
import { Account, loader as accountLoader } from "@/page/account/Account";
import {
  Profile,
  loader as profileSettingsLoader,
} from "@/page/account/Profile";
import {
  Notifications,
  loader as notificationSettingsLoader,
} from "@/page/account/Notifications";
import Search, { loader as searchLoader } from "@/page/search/Search";
import WatchList, {
  loader as watchlistLoader,
} from "@/page/watchlist/WatchList";
import { WatchPage, loader as watchPageLoader } from "@/page/Watch";
import MaintenancePage, {
  loader as maintenanceLoader
} from "@/page/maintenance/Maintenance";

import MasterLayout, {
  loader as rootLoader,
} from "@/components/layouts/MasterLayout";
import { CommonLayout, PublicLayout } from "@/components/layouts";
import Spinner from "@/components/common/Spinner";
import ErrorPage from "@/error-page";

import { QueryClient } from "@tanstack/react-query";
import { STALE_TIME } from "@/utils/constants";
import { useUserStore } from "@/store/useUserStore";
import { requireAuth } from "@/utils/requireAuth";
import LogoutConfirmation from "@/page/logout/LogoutConfirmation";
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
    },
  },
});

export const LoadingFallback = () => (
  <div>
    <div className="flex h-screen w-full flex-col items-center justify-center text-gray-500">
      <Spinner size={8} />
    </div>
  </div>
);


export const router = createBrowserRouter([
  // Private Routes
  {
    path: "/",
    element: <MasterLayout />, //Root
    errorElement: <ErrorPage />,
    loader: rootLoader(queryClient),
    children: [
      {
        index: true,
        path: "discover",
        element: <Discover />,
        loader: discoverLoader(queryClient),
      },
      {
        path: "movies",
        element: <Movies />,
        loader: moviesLoader,
      },
      {
        path: "movies/:pk",
        element: <MovieDetail />,
        loader: moviesDetailLoader,
      },
      {
        path: "shows",
        children: [
          {
            index: true,
            element: <Shows />,
            loader: showsLoader,
          },
          {
            path: ":show_pk/seasons?/:season_pk?/episodes?/:episode_pk?",
            element: <ShowDetail />,
            loader: showDetailLoader(queryClient),
          },
        ],
      },
      {
        path: "kids",
        element: <Kid />,
        loader: kidsLoader,
      },
      {
        path: "topics",
        element: <Topic />,
        loader: topicsLoader,
      },
      {
        path: "topics/:pk",
        element: <TopicDetail />,
        loader: topicsDetailLoader,
      },
      // TODO: Livestreams page with schedule and above schedule player
      {
        path: "livestreams/:pk",
        element: <LivestreamDetail />,
        loader: livestreamsDetailLoader,
      },
      {
        path: "collections/:collectionId",
        element: <CollectionDetail />,
        loader: collectionsDetailLoader,
      },
      {
        //TODO - This route is blank.
        path: "account",
        element: <Account />,
        loader: accountLoader,
        children: [
          {
            path: "",
            loader: () => {
              return redirect("/account/profile");
            },
          },
          {
            path: "profile",
            element: <Profile />,
            loader: profileSettingsLoader,
          },
          {
            path: "notifications",
            element: <Notifications />,
            loader: notificationSettingsLoader,
          },
        ],
      },
      {
        path: "search",
        element: <Search />,
        loader: searchLoader,
      },
      {
        path: "watchlist",
        element: <WatchList />,
        loader: watchlistLoader,
      },
    ],
  },
  {
    path: "watch/:contentId",
    element: <WatchPage />,
    loader: watchPageLoader(queryClient),
  },
  // Public Routes
  {
    path: "/",
    element: <CommonLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "login",
        element: <Login />,
        loader: loginLoader,
        action: loginAction,
      },
      {
        path: "signup",
        element: <Signup />,
        loader: signupLoader,
      },
      {
        path: "verify",
        element: <Verify user />,
        loader: verifyLoader,
      },
      {
        path: "verify/:uid/:token",
        element: <VerifySuccess />,
        loader: verifySuccessLoader,
      },
      // Request new verification email
      {
        path: "verify-request",
        element: <VerifyRequest />,
        loader: verifyRequestLoader,
      },
      // Reset password request
      {
        path: "reset-password",
        element: <ResetPassword />,
        loader: resetPasswordLoader,
      },
      {
        path: "reset-password/:uid/:token",
        element: <ResetConfirm />,
        loader: resetPasswordConfirmLoader,
      },
      // TODO Does not need to be a page
      {
        path: "reset-password-success",
        element: <ResetSuccess />,
        loader: resetPasswordSuccessLoader,
      },
      {
        path: "*",
        element: <NotFound />,
        loader: notFoundLoader,
      },
      // Maintenance Route - accessible even during maintenance mode
      {
        path: "/maintenance",
        element: <MaintenancePage />,
        loader: maintenanceLoader,
      },
    ],
  },
  {
    path: "/trailers",
    element: <PublicLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: ":pk",
        element: <TrailerDetail setParamNext paramNext />,
      },
    ],
  },
  {
    // TODO: fix logout white flash.
    path: "logout",
    // TODO: move logout confirmation to shared
    // make sure logout an delete are working properly in both apps
    element: <LogoutConfirmation />,
    loader: async ({ request }) => {
      await requireAuth(request);
      return null;
    },
    action: async () => {
      await useUserStore.getState().clearUser();
      return redirect("/login");
    },
  },
]);
