import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { APP_NAME } from "@/utils/constants";
import logo from "@/images/logo.svg";
import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  await hasAuth();
  document.title = `Reset Password Success | ${APP_NAME}`;
  return null;
}

const ResetSuccess = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation");

  function handleClick() {
    setSubmitLoading(true);
    navigate("/login");
    setSubmitLoading(false);
  }

  return (
    <>
      <section className="relative flex h-screen w-full flex-col items-center justify-center p-8 sm:pt-10">
        <Card>
          <div className="text-gray-400">
            <div className="m-auto mb-10 w-32">
              <img src={logo} alt="Logo" />
            </div>
            <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
              Success
            </h2>
            <p className="max-w-sm text-center">
              Your new password has been created, you can now go sign in.
            </p>
          </div>
          <div className="mt-6">
            <ButtonRound
              onClick={handleClick}
              className="m-auto space-x-2"
              size="rnd"
              text={`Sign in to ${APP_NAME}`}
              loading={submitLoading}
            />
          </div>
        </Card>
      </section>
    </>
  );
};

export default ResetSuccess;
