import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ButtonRound } from "@/components/common/Button";
import TrailerDetail from "@/page/Trailer";
import logo from "@/images/logo.svg";

export default function PublicLayout() {
  const isAuthenticated = localStorage.getItem("cp-access-token");
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDonor, setIsDonor] = useState(false);
  const [paramNext, setParamNext] = useState();

  useEffect(() => {
    const updateScroll = () => {
      const scrY = window.scrollY;
      setIsScrolled(scrY > 25);
    };

    const onScroll = () => {
      window.requestAnimationFrame(updateScroll);
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isScrolled]);

  useEffect(() => {
    const donorView = window.location.search.includes("access=donor");
    if (donorView) {
      setIsDonor(true);
    }
  }, []);

  return (
    <>
      <div className="TitleWrapper FixedHeader">
        <div
          className={`Header md:-[52px] fixed inset-x-0 top-0 z-50 flex h-[72px] w-full px-[5vw] transition duration-300 ${
            isScrolled
              ? "border-b border-gray-900 bg-gray-950/80 backdrop-blur"
              : "bg-transparent"
          }`}
        >
          <nav className="flex grow items-center justify-between">
            <img
              className={`h-full ${
                isScrolled ? "w-24" : "w-32"
              } transition-[width] duration-200`}
              src={logo}
              alt="Logo"
            />
            <div className="flex items-center">
              {!isDonor ? (
                // <Link to={{
                //   pathname: '/signup?next=',
                //   state: {from: localStorage.getItem('tref')}
                // }}
                <Link
                  to="/signup"
                  className="mr-6 text-gray-200 hover:text-gray-400"
                >
                  Sign Up
                </Link>
              ) : null}
              <ButtonRound
                to={isAuthenticated ? "/discover" : '/login?referrer="trailer'}
                text="Sign In"
                onClick={() =>
                  navigate("/login", {
                    state: { from: paramNext },
                  })
                }
              />
            </div>
          </nav>
        </div>

        <TrailerDetail setParamNext={setParamNext} paramNext={paramNext} />
      </div>

      <div className="FooterWrapper relative mt-20 flex w-full flex-col items-center justify-center space-y-6 px-[5vw] py-8 text-xs text-gray-500">
        <img className="h-full w-24" src={logo} alt="Corco+ Logo" />
        <div className="flex flex-wrap items-start justify-center space-x-3 ">
          <Link to="" className="p-2 hover:text-gray-400">
            Privacy Policy
          </Link>
          <Link to="" className="p-2 hover:text-gray-400">
            Subscriber Agreement
          </Link>
          <Link to="" className="p-2 hover:text-gray-400">
            Help
          </Link>
          <Link to="" className="p-2 hover:text-gray-400">
            Supported Devices
          </Link>
          <Link to="" className="p-2 hover:text-gray-400">
            About Us
          </Link>
        </div>
        <p>&copy; Miracle Channel. All Rights Reserved</p>
      </div>
    </>
  );
}
