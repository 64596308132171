import { privateFetch } from "@/utils/fetch";
import { PAGE_SIZE_ROW, STALE_TIME } from "@/utils/constants";
import logger from "@/utils/logger";

const ENDPOINT_CONFIG = {
  Watchlist: (userId: string) => `/users/${userId}/activity/saveactivity`,
  default: (pageType: string) => `/content/${pageType.toLowerCase()}`,
};

const getContent = async (
  pageType: string,
  pageParam: number,
  searchParams: string,
  userId: string,
) => {
  const params = new URLSearchParams(searchParams);
  params.append("page", pageParam.toString());
  params.append("page_size", PAGE_SIZE_ROW.toString());

  const endpoint =
    pageType === "Watchlist"
      ? ENDPOINT_CONFIG.Watchlist(userId)
      : ENDPOINT_CONFIG.default(pageType);

  const res = await privateFetch.get(`${endpoint}?${params.toString()}`);
  return res.data;
};

export const contentQuery = (
  pageType: string,
  searchParams: string,
  userId: string,
) => ({
  queryKey: [pageType.toLowerCase(), searchParams.toString()],
  queryFn: async ({ pageParam = 1 }) => {
    const data = await getContent(pageType, pageParam, searchParams, userId);
    return {
      pageParam,
      pages: data.results,
      nextPage: data._next
        ? new URL(data._next).searchParams.get("page")
        : undefined,
      previousPage: data._previous,
      total: data.count,
    };
  },
  getNextPageParam: (lastPage: any) => {
    if (!lastPage) return undefined;
    return lastPage.nextPage ?? undefined;
  },
  keepPreviousData: true,
  staleTime: STALE_TIME,
});
