import React from "react";

import { Spinner } from "@/components/common";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import CollectionRow from "@/components/CollectionRow";

export default function Collections({
  collections,
  loading,
  hasNextPage,
  fetchNextPage,
  fetchingNextPage,
  onClick,
}) {
  // Intersection Observer
  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  return collections && collections.pages && collections.pages.length > 0 ? (
    <div>
      {collections.pages.map((page, i) => (
        <React.Fragment key={i}>
          {page.results.map((collection, i) => (
            <React.Fragment key={i}>
              <CollectionRow
                key={collection.pk}
                title={collection.title}
                pk={collection.pk}
                onClick={onClick}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
      <div className="flex items-center justify-center py-10 text-gray-500">
        <button
          ref={loadMoreButtonRef}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || fetchingNextPage}
        >
          {fetchingNextPage ? (
            <div className="flex w-full items-center justify-center">
              <Spinner size={8} />
            </div>
          ) : hasNextPage ? (
            "Load more"
          ) : (
            ""
          )}
        </button>
      </div>
      <div>{loading && !fetchingNextPage ? "Fetching..." : null}</div>
    </div>
  ) : null;
}
