import ContentPage, {
  pageLoader,
} from "@/features/content/components/ContentPage";

export const loader = pageLoader("Movies");

export default function Shows() {
  return (
    <ContentPage pageType="Movies" pageDesc="Discover our latest Movies" />
  );
}
