// Fetch show, and seasons.
// Fetch episodes for the season with the most recently played episode.
// Set the play button state to the most recently played episode.

import React, { useState, useEffect } from "react";
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
  QueryClient,
} from "@tanstack/react-query";
import {
  useNavigate,
  useLoaderData,
  LoaderFunctionArgs,
  redirect,
} from "react-router-dom";
import { ButtonRound } from "@/components/common/Button";
import { Spinner } from "@/components/common";
import { APP_NAME, STALE_TIME, PAGE_SIZE_ROW } from "@/utils/constants";
import DetailBlock from "@/components/DetailBlock";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import Episode from "@/components/Episode";
import { Icon } from "@mcvod-apps/icons";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";
import parseUrl from "url-parse";
import {
  useSaveWatchlist,
  useDeleteWatchlist,
} from "@/api/mutations/watchlist";
import { useUrlParser } from "@/hooks/useUrlParser";

// Fetch show by pk
async function getShowByPk(pk: string) {
  return privateFetch.get(`/content/shows/${pk}/`).then((res) => res.data);
}

//Define query
const showDetailQuery = (pk: string) => ({
  queryKey: ["1-show_detail", pk],
  queryFn: async () => getShowByPk(pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

async function getSeasonsList(show_pk: string) {
  return privateFetch
    .get(`/content/shows/${show_pk}/seasons/`)
    .then((res) => res.data);
}

const seasonsListQuery = (show_pk: string) => ({
  queryKey: ["2-seasons_list", show_pk],
  queryFn: async () => getSeasonsList(show_pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

async function getEpisodesList(
  show_pk: string,
  current_season: string,
  page_param: string,
) {
  return privateFetch
    .get(
      `/content/shows/${show_pk}/seasons/${current_season}/episodes/?page=${page_param}&page_size=${PAGE_SIZE_ROW}`,
    )
    .then((res) => res.data);
}

const episodesListQuery = (show_pk: string, current_season: string) => ({
  queryKey: ["3-episode_list", "show", show_pk, "season", current_season],
  queryFn: async ({ pageParam = "1" }) =>
    getEpisodesList(show_pk, current_season, pageParam),
  getNextPageParam: (lastPage: any, page: any) => {
    const { query } = parseUrl(lastPage._next, true);
    return query.page;
  },
  // enabled: data !== undefined && currentSeason !== undefined,
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient: QueryClient) =>
  async ({ request, params }: LoaderFunctionArgs) => {
    await requireAuth(request);
    const url = new URL(request.url);
    const show_pk = params?.show_pk ?? null;

    if (!show_pk) {
      throw new Error("Show ID is required");
    }

    // 1. Fetch show data
    const showByPkQuery = showDetailQuery(show_pk);
    const showData =
      (await queryClient.getQueryData(showByPkQuery.queryKey)) ??
      (await queryClient.fetchQuery(showByPkQuery));

    // 2. Fetch seasons data
    const seasonsQuery = seasonsListQuery(show_pk);
    const seasonsListData =
      (await queryClient.getQueryData(seasonsQuery.queryKey)) ??
      (await queryClient.fetchQuery(seasonsQuery));

    // 3. Determine season based on route
    let season_pk: string;
    let currentSeasonData;

    if (params?.season_pk) {
      // Case: /shows/128/seasons/162/ or /shows/128/seasons/162/episodes/1228/
      season_pk = params.season_pk;
      currentSeasonData = seasonsListData?.results.find(
        (season: { pk: string }) => season.pk.toString() === season_pk,
      );
    } else {
      // Case: /shows/128/
      currentSeasonData = seasonsListData?.results.find(
        (season: { is_current: boolean }) => season.is_current,
      );
      season_pk = currentSeasonData?.pk.toString();
    }

    if (!season_pk) {
      throw new Error("No valid season found");
    }

    // 4. Fetch episodes for the determined season
    const episodesQuery = episodesListQuery(show_pk, season_pk);
    const episodesData =
      (await queryClient.getQueryData(episodesQuery.queryKey)) ??
      (await queryClient.fetchQuery(episodesQuery));

    // 5. Determine current episode based on route
    let currentEpisodeData;

    if (params?.episode_pk) {
      // Case: /shows/128/seasons/162/episodes/1228/
      currentEpisodeData = episodesData?.results.find(
        (episode: { pk: string }) =>
          episode.pk.toString() === params.episode_pk,
      );
    } else {
      // Cases: /shows/128/ or /shows/128/seasons/162/
      currentEpisodeData = episodesData?.results.find(
        (episode: { is_current: boolean }) => episode.is_current,
      );
    }

    const currentEpisodePlayLink = currentEpisodeData?._play;
    const currentSeasonNumber = currentSeasonData?.season_number.toString();
    const currentEpisodeNumber = currentEpisodeData?.episode_number.toString();

    document.title = `Watch ${showData?.title} | ${APP_NAME}`;

    return {
      show_pk,
      season_pk,
      episode_pk: currentEpisodeData?.pk.toString(),
      showData,
      seasonsListData,
      episodesData,
      currentEpisodeData,
      currentSeasonData,
      currentSeasonNumber,
      currentEpisodeNumber,
      currentEpisodePlayLink,
    };
  };

interface loaderDataTypes {
  showData: any;
  seasonsData: any;
  episodesData: any;
  show_pk: string;
  season_pk: string;
  episode_pk: string;
  currentEpisodeData: any;
  currentSeasonNumber: string;
  currentEpisodeNumber: string;
  currentEpisodePlayLink: string;
  currentSeasonData: any;
}

export function ShowDetail() {
  const navigate = useNavigate();

  const {
    showData: initialShowData,
    seasonsData: initialSeasonsData,
    episodesData: initialEpisodesData,
    show_pk,
    season_pk,
    episode_pk,
    currentSeasonNumber,
    currentEpisodeNumber,
    currentEpisodePlayLink,
    currentEpisodeData,
    currentSeasonData,
  } = useLoaderData() as loaderDataTypes;

  const {
    data: showData,
    isLoading: showIsLoading,
    isError: showIsError,
    error: showError,
  } = useQuery({
    ...showDetailQuery(show_pk),
    initialData: initialShowData,
  });

  const {
    data: seasonsData,
    isLoading: seasonsIsLoading,
    isError: seasonsIsError,
    error: seasonsError,
  } = useQuery({
    ...seasonsListQuery(show_pk),
    initialData: initialSeasonsData,
  });

  const {
    data: episodesData,
    isLoading: episodesIsLoading,
    isError: episodesIsError,
    error: episodesError,
  } = useQuery({
    ...episodesListQuery(show_pk, season_pk),
    initialData: initialEpisodesData,
  });

  const getPlayButtonName = (
    currentSeasonNumber: any,
    currentEpisodeNumber: any,
  ) => {
    if (showData) {
      if (currentSeasonNumber && currentEpisodeNumber) {
        return `Play S.${currentSeasonNumber}: Ep.${currentEpisodeNumber}`;
      } else return "Play";
    }
  };

  const currentSeason = seasonsData?.results.find(
    (season: { is_current: boolean }) => season.is_current,
  );

  let watchlistAddRemoveUrl = "";
  if (currentSeason?.activity.save_activity !== null) {
    watchlistAddRemoveUrl = useUrlParser(
      currentSeason?.activity.save_activity._detail,
    );
  } else watchlistAddRemoveUrl = useUrlParser(currentSeason?._save);

  const showDetailQueryKey = ["2-seasons_list", show_pk];
  const saveWatchlistMutation = useSaveWatchlist(showDetailQueryKey);
  const deleteWatchlistMutation = useDeleteWatchlist(showDetailQueryKey);

  function clickWatchlistButton() {
    if (currentSeason.activity.save_activity === null) {
      saveWatchlistMutation.mutate(watchlistAddRemoveUrl);
    } else {
      deleteWatchlistMutation.mutate(watchlistAddRemoveUrl);
    }
  }

  function handleShowPlayClick(
    pk: string,
    play: string,
    title: string,
    type: string,
  ) {
    navigate(
      `/watch/${pk}?p_l=${currentEpisodePlayLink}&title=${title}&type=${type}`,
    );
  }

  const loadMoreButtonRef = React.useRef();

  // useIntersectionObserver({
  //   root: null,
  //   target: loadMoreButtonRef,
  //   onIntersect: episodeData.fetchNextPage,
  //   enabled: episodeData.hasNextPage,
  // });

  let playURL = "";

  if (showIsLoading || seasonsIsLoading || episodesIsLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  }

  if (showIsError || seasonsIsError || episodesIsError) {
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>{`Error: ${
          showIsError
            ? showError
            : seasonsIsError
            ? seasonsError
            : episodesIsError
            ? episodesError
            : "There was an error"
        }`}</p>
      </div>
    );
  }

  return (
    <>
      <div className="-mt-20 h-full overflow-y-scroll px-[3vw] pt-20 ">
        <div className="flex w-full flex-col">
          <DetailBlock playUrl={playURL} data={showData} page="show" />
          <div className="flex space-x-4">
            <div>
              <ButtonRound
                onClick={() =>
                  handleShowPlayClick(
                    currentEpisodeData.pk,
                    currentEpisodePlayLink,
                    currentEpisodeData.title,
                    currentEpisodeData.content_model,
                  )
                }
                className="space-x-2 self-start px-4 py-3 text-xl"
                size="rnd"
                IconStart={<Icon name="play" size={24} weight="fill" />}
              >
                {getPlayButtonName(currentSeasonNumber, currentEpisodeNumber)}{" "}
              </ButtonRound>
            </div>
            <ButtonRound
              onClick={() => clickWatchlistButton()}
              //   data.activity.save_activity === null
              //     ? saveWatchlistMutation.mutate(movie_pk)
              //     : deleteWatchlistMutation.mutate(movie_pk);
              // }}

              className="self-start rounded-full px-4 py-4 text-xl"
              size="rnd"
              varient="plain"
              IconStart={
                saveWatchlistMutation.isLoading ||
                deleteWatchlistMutation.isLoading ? (
                  <Spinner size={6} />
                ) : currentSeason.activity.save_activity === null ? (
                  <Icon name="plus" size={24} weight="bold" />
                ) : (
                  <Icon name="check" size={24} weight="bold" />
                )
              }
            />
          </div>
        </div>
        <div className="mt-20 flex flex-col">
          <Seasons
            show_pk={show_pk}
            seasons={seasonsData}
            currentSeason={season_pk}
            episodes={episodesData}
            currentSeasonNumber={currentSeasonNumber}
          />
        </div>
      </div>
    </>
  );
}

interface SeasonsProps extends React.HTMLProps<HTMLDivElement> {
  show_pk: string;
  seasons: any;
  currentSeason: string;
  episodes: any;
  currentSeasonNumber: string;
}

function Seasons(props: SeasonsProps) {
  const { seasons, currentSeason, show_pk, episodes, currentSeasonNumber } =
    props;
  const queryClient = useQueryClient();
  const [thisSeason, setThisSeason] = useState(currentSeason);
  const navigate = useNavigate();

  function handleSeasonClick(seasonPk: string) {
    setThisSeason(seasonPk);

    queryClient.prefetchQuery({
      queryKey: ["3-episode_list", "show", show_pk, "season", seasonPk],
      queryFn: () => getEpisodesList(show_pk, seasonPk, "1"),
    });
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="flex space-x-2">
          {seasons.results.map((season: any) => (
            <div
              key={season.pk}
              onClick={() => handleSeasonClick(season.pk.toString())}
              className={`rounded-full ${
                season.pk.toString() === thisSeason
                  ? "bg-gray-700 text-gray-100 hover:bg-gray-800 hover:text-gray-400"
                  : "bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-gray-100"
              } cursor-pointer px-4 py-2`}
            >
              Season {season.season_number}
            </div>
          ))}
        </div>
        <Season showPk={show_pk} seasonPk={thisSeason} episodes={episodes} />
      </div>
    </>
  );
}

interface SeasonProps extends React.HTMLProps<HTMLDivElement> {
  showPk: string;
  seasonPk: string;
  episodes: any;
}

function Season(props: SeasonProps) {
  const { showPk, seasonPk, episodes: initialEpisodes } = props;

  // Add direct query for episodes
  const { data: episodesData } = useQuery({
    ...episodesListQuery(showPk, seasonPk),
    initialData: initialEpisodes,
  });

  return (
    <>
      <div className="mt-10 w-full overflow-y-auto">
        {episodesData?.results.map((item: any) => (
          <Episode
            key={item.pk}
            pk={item.pk}
            show={showPk}
            season_number={item.season_number}
            associatedEpisodeLink={`/shows/${showPk}/seasons/${item.season_number}/episodes/${item.episode_number}`}
            associatedMeta={`S${item.season_number} E${item.episode_number}`}
            associatedSeasonLink={`/shows/${showPk}/seasons/${item.season_number}`}
            associatedTitle={item.title}
            associatedShowLink={`/shows/${showPk}`}
            episodeNumber={item.episode_number}
            videoSrc={item._play}
            image={item.images.episode}
            title={item.title}
            description={item.description}
            length={item.length}
            progress={
              item.activity.play_activity?.play_end_point_percentage || 0
            }
            saveActivity={item.activity.save_activity}
            detailLink={item._detail}
            contentType="show"
            captions={item.has_captions}
          />
        ))}
      </div>
    </>
  );
}
