import SidebarNav from "@/components/layouts/SidebarNav";
import { APP_NAME } from "@/utils/constants";
import { Icon } from "@mcvod-apps/icons";
import logo from "@/images/logo.svg";
import logoIcon from "@/images/logoIcon.svg";
import Search from "@/page/search/Search";
import SearchBar from "@/components/common/Search/SearchBar";
import UserDropdown from "@/components/common/UserDropdown";

interface TopbarProps {
  isMobile: boolean;
}

export function Topbar({ isMobile }: TopbarProps) {
  return (
    <nav className="fixed inset-x-0 top-0 z-40 flex items-center justify-center bg-gradient-to-b from-gray-950 to-transparent">
      <div className=" flex h-20 items-stretch space-x-4 rounded-full border border-indigo-950 bg-gradient-to-b from-indigo-950 to-indigo-900 p-1.5 text-lg backdrop-blur-lg">
        <div className="flex cursor-pointer items-center px-4 text-indigo-50">
          <img alt={APP_NAME} src={logo} className="h-6 w-auto" />
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Discover
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Movies
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Shows
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Kids
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Topics
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          Watchlist
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          <Icon
            name="search"
            weight="bold"
            className="h-6 w-6"
            aria-hidden="true"
          />
        </div>
        <div className="flex cursor-pointer items-center rounded-full px-4 text-indigo-50 hover:bg-indigo-700/80">
          <Icon
            name="user"
            weight="bold"
            className="h-6 w-6"
            aria-hidden="true"
          />
        </div>

        {/* <SearchBar /> */}
        {/* <UserDropdown /> */}
      </div>
      {/* <SidebarNav collapsed={isCollapsed} toggleCollapse={handleToggler} isMobile={isMobile} /> */}
    </nav>
  );
}

interface TopbarMobileProps {
  mobileOpen: boolean;
  toggleMobileMenu: () => void;
}

export const TopbarMobile: React.FC<TopbarMobileProps> = ({
  mobileOpen,
  toggleMobileMenu,
}) => {
  return (
    <div className={`${mobileOpen ? "static" : "hidden"}`}>
      <div className="fixed inset-0 z-40">
        <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-800 px-6 py-6">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{APP_NAME}</span>
              <img
                className="h-14 w-auto"
                src="https://static.corcoplus.com/assets/emails/logo_v2_68-white.png"
                alt="Corco+ logo"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Close menu</span>
              <Icon
                name="close"
                weight="bold"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
