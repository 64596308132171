import { useState, useEffect } from "react";
import SidebarNav from "@/components/layouts/SidebarNav";
import { APP_NAME } from "@/utils/constants";
import { Icon } from "@mcvod-apps/icons";

interface SidebarProps {
  isMobile: boolean;
}

export function Sidebar({ isMobile }: SidebarProps) {
  // Get initial state from localStorage
  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    try {
      return JSON.parse(localStorage.getItem("sidebar-is-collapsed") ?? "false");
    } catch {
      return false;
    }
  });

  // Update localStorage when state changes
  useEffect(() => {
    localStorage.setItem("sidebar-is-collapsed", JSON.stringify(isCollapsed));
  }, [isCollapsed]);

  const handleToggler = () => {
    // Update the state, which will trigger the useEffect to update localStorage
    setIsCollapsed(prevState => !prevState);
  };

  return (
    <nav
      className={`
            fixed inset-x-0 bottom-20 z-40 flex border-r border-gray-800 bg-gray-900
            transition-[width] duration-500 md:static md:flex md:flex-col  md:overflow-x-hidden
            ${isCollapsed ? "md:w-16" : "md:w-48"}
            ${isMobile ? "py-1" : "py-4"} px-2
            `}
    >
      <SidebarNav
        collapsed={isCollapsed}
        toggleCollapse={handleToggler}
        isMobile={isMobile}
      />
    </nav>
  );
}

interface SidebarMobileProps {
  mobileOpen: boolean;
  toggleMobileMenu: () => void;
}

export const SidebarMobile: React.FC<SidebarMobileProps> = ({
  mobileOpen,
  toggleMobileMenu,
}) => {
  return (
    <div className={`${mobileOpen ? "static" : "hidden"}`}>
      <div className="fixed inset-0 z-40">
        <div className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-800 px-6 py-6">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{APP_NAME}</span>
              <img
                className="h-14 w-auto"
                src="https://static.corcoplus.com/assets/emails/logo_v2_68-white.png"
                alt="Corco+ logo"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-300"
              onClick={toggleMobileMenu}
            >
              <span className="sr-only">Close menu</span>
              <Icon
                name="close"
                weight="bold"
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
