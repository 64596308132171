import { useTranslation } from "react-i18next";
import { Spinner } from "@/components/common";
import {
  useLoaderData,
  useFetcher,
  LoaderFunctionArgs,
} from "react-router-dom";
import { APP_NAME, STALE_TIME } from "@/utils/constants";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ButtonRound } from "@/components/common/Button";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";
import { useUserStore } from "@/store/useUserStore";
import Label from "@/components/common/Label";

// Types
interface UserPreferences {
  send_watchlist: boolean;
}

interface LoaderData {
  userId: string;
}

// Query key factory
const userPreferenceKeys = {
  all: ["user-preferences"] as const,
  detail: (userId: string) => [...userPreferenceKeys.all, userId] as const,
};

// API functions
async function getUserPreferences(userId: string): Promise<UserPreferences> {
  const response = await privateFetch.get(
    `/users/${userId}/account/preferences/`,
  );
  return response.data;
}

async function updateUserPreferences(
  userId: string,
  preferences: UserPreferences,
): Promise<UserPreferences> {
  const response = await privateFetch.put(
    `/users/${userId}/account/preferences/`,
    preferences,
  );
  return response.data;
}

// Loader function
export async function loader({
  request,
}: LoaderFunctionArgs): Promise<LoaderData> {
  await requireAuth(request);
  const { id: userId } = useUserStore.getState();
  if (!userId) throw new Error("User ID not found");
  document.title = `Notifications - ${APP_NAME}`;
  return { userId };
}

export function Notifications() {
  const data = useLoaderData();
  const { userId } = data as LoaderData;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const fetcher = useFetcher();

  // Query for getting preferences
  const {
    data: preferences,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: userPreferenceKeys.detail(userId),
    queryFn: () => getUserPreferences(userId),
    staleTime: STALE_TIME,
  });

  // Mutation for updating preferences
  const mutation = useMutation({
    mutationFn: (newValue: boolean) =>
      updateUserPreferences(userId, { send_watchlist: newValue }),
    onSuccess: (newData) => {
      // Directly update the cache with the response data
      queryClient.setQueryData(userPreferenceKeys.detail(userId), newData);
    },
  });

  if (isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner size={8} />
      </div>
    );
  }

  if (isError && error instanceof Error) {
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500">
        <p>Error: {error.message}</p>
      </div>
    );
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const newValue = formData.get("send_watchlist") === "on";
    mutation.mutate(newValue);
  };

  return (
    <div className="h-full px-[3vw] pt-20">
      <div className="w-full max-w-sm text-gray-300">
        <div className="flex text-gray-300">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-6">
              <div className="">
                <div className="mb-4 text-center">
                  <h2 className="mb-1 text-4xl font-bold">Notifications</h2>
                  <p className="text-sm text-gray-400">
                    Select which type of communication you would like to receive
                    from {APP_NAME}.
                  </p>
                </div>

                <div className="">
                  <input
                    type="checkbox"
                    name="send_watchlist"
                    id="send_watchlist"
                    defaultChecked={preferences?.send_watchlist}
                  />
                  <Label
                    htmlFor="send_watchlist"
                    text={t("setting-general.watchlist")}
                    className="ml-2"
                  />
                </div>
              </div>
              <ButtonRound
                type="submit"
                text="Save"
                className="items-center space-x-2"
                size="rnd"
                loading={mutation.isLoading}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
