import PropTypes from "prop-types";
import { Spinner } from "@/components/common";
import { useWindowSize } from "@uidotdev/usehooks";
import { useCollectionRowByPk } from "@/api/useCollectionRowByPk";
import { Carousel } from "@/components/CollectionCarousel";

export default function CollectionRow({ title, pk, onClick }) {
  const size = useWindowSize();
  const {
    data,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
  } = useCollectionRowByPk(pk);

  // Conditionally change the number of slides based on the window size.
  let NUM_SLIDES;
  if (size.width < 640) NUM_SLIDES = 3;
  if (size.width > 768) NUM_SLIDES = 4;
  if (size.width > 1024) NUM_SLIDES = 6;
  if (size.width > 1280) NUM_SLIDES = 8;

  if (isLoading || isFetching)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>{`Error: ${error.message}`}</p>
      </div>
    );

  return (
    <div className="mb-10 w-full">
      {title && (
        <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">{title}</h3>
      )}
      <Carousel
        data={data}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        slideNumber={NUM_SLIDES}
      />
    </div>
  );
}

CollectionRow.propTypes = {
  title: PropTypes.string,
  pk: PropTypes.number,
  onClick: PropTypes.func,
};
