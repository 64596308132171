import { useMutation, useQueryClient } from "@tanstack/react-query";
import { privateFetch } from "@/utils/fetch";

// Define the return type for the fetch functions
type FetchResponse = Promise<any>; // Adjust this type based on the actual response structure

//TODO: parse urls here so that we can just pass the api values from components.

const saveToWatchlist = (url: string): FetchResponse =>
  privateFetch.get(`${url}`).catch((err) => {
    console.error(err);
    throw err; // Rethrow the error to handle it in the mutation
  });

const deleteFromWatchlist = (url: string): FetchResponse =>
  privateFetch.delete(`${url}`).catch((err) => {
    console.error(err);
    throw err; // Rethrow the error to handle it in the mutation
  });

export function useSaveWatchlist(queryKey: string[]) {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>({
    mutationFn: (url: string) => saveToWatchlist(url),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries("saveactivity");
      queryClient.invalidateQueries("watchlist");
    },
  });
}

export function useDeleteWatchlist(queryKey: string[]) {
  const queryClient = useQueryClient();
  return useMutation<void, Error, string>({
    mutationFn: (url: string) => deleteFromWatchlist(url),
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries("saveactivity");
      queryClient.invalidateQueries("watchlist");
    },
  });
}
