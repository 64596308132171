import React from "react";
import { Icon } from "@mcvod-apps/icons";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Popover from "@radix-ui/react-popover";
import {
  useSaveWatchlist,
  useDeleteWatchlist,
} from "@/api/mutations/watchlist";

interface EpisodeProps extends React.HTMLProps<HTMLDivElement> {
  pk: number;
  image: string;
  episodeNumber: string;
  title: string;
  captions: boolean;
  show: string;
  videoSrc: string;
  length: string;
  progress: number;
  detailLink: string;
  contentType: string;
  saveActivity: number | null;
  description: string;
  associatedMeta: string;
  associatedTitle: string;
  associatedShowLink: string;
  associatedSeasonLink: string;
  associatedEpisodeLink: string;
  season_number: number;
}

// TODO: Add Icon component into the Popover.
export default function Episode({
  pk,
  image,
  episodeNumber,
  title,
  show,
  videoSrc,
  captions,
  description,
  length,
  progress,
  detailLink,
  contentType,
  saveActivity,
  associatedTitle,
  associatedMeta,
  associatedShowLink,
  associatedSeasonLink,
  associatedEpisodeLink,
  ...props
}: EpisodeProps) {
  const navigate = useNavigate();
  function parseUrl(url: string) {
    return new URL(url).pathname.split("/v2/content").pop() || "/#";
  }

  const { mutate: mutateSave } = useSaveWatchlist(pk);
  const { mutate: mutateDelete } = useDeleteWatchlist(pk);

  function addRemoveWatchlist(url: any): void {
    if (saveActivity === null) {
      mutateSave(url);
    } else {
      mutateDelete(url);
    }
  }

  function handleEpisodeClick() {
    navigate(
      `/watch/${pk}?p_l=${videoSrc}&title=${title}&type=${contentType}&season=${props.season_number}&e=${associatedEpisodeLink}`,
    );
  }

  return (
    <div className="mb-1 flex cursor-pointer rounded-lg px-2 py-2 hover:bg-gray-900">
      <div className="relative mr-2 flex h-[90px] w-[160px] shrink-0 flex-col overflow-clip rounded-md  md:h-[135px]  md:w-[240px]">
        <img
          src={image}
          alt={title}
          className="h-full w-full object-cover object-center"
        />
        <div
          tabIndex={0}
          role="button"
          onClick={handleEpisodeClick}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleEpisodeClick();
            }
          }}
          className="group absolute inset-0 flex cursor-pointer items-center justify-center md:hover:bg-gray-950 md:hover:bg-opacity-50"
        >
          <Icon
            name="play"
            weight="fill"
            alt="play"
            className="absolute z-10 rounded-full text-white/80 md:h-[32px] md:w-[32px] md:opacity-0 md:transition-transform md:duration-200 md:ease-in-out md:hover:scale-150 md:hover:text-white md:hover:shadow-white/80 md:group-hover:opacity-100"
          />
          {captions && (
            <div className="absolute bottom-1 left-1 rounded-md bg-black/70 p-1 text-xs text-gray-200 outline outline-1 outline-white/10">
              CC
            </div>
          )}
          <div className="absolute bottom-1 right-1 rounded-md bg-black/70 p-1 text-xs text-gray-200 outline outline-1 outline-white/10">
            {length}
          </div>
        </div>

        <div
          className="absolute inset-x-0 bottom-0 h-1 bg-indigo-700"
          style={{
            width: `calc(${progress}% * 100)`,
          }}
        />
      </div>
      <div className="flex w-full  max-w-[75ch] flex-col text-gray-200 sm:px-8">
        <div className="mb-2 text-sm font-semibold md:text-base">
          <h3 className="flex">
            {contentType === "topic"
              ? title
              : episodeNumber + "." + " " + title}
          </h3>
          {contentType === "topic" && (
            <p className="mt-auto text-sm text-gray-600">
              {show + " " + episodeNumber.toUpperCase()}
            </p>
          )}
        </div>
        <p className="mb-4 hidden text-gray-400 md:line-clamp-4 md:block">
          {description}
        </p>
      </div>
      <Popover.Root>
        <Popover.Trigger className="ml-auto flex h-[40px] w-[40px] items-center justify-center rounded-full p-2 text-gray-400 hover:bg-gray-800 hover:text-white">
          <Icon name="dotsThreeVertical" weight="bold" size={24} />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content className="rounded-md bg-gray-800  py-2 text-sm">
            <div className="flex flex-col text-gray-300 ">
              {contentType === "topic" && (
                <>
                  <Link
                    to={parseUrl(associatedShowLink)}
                    className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100"
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mr-2 h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Go to show
                    </div>
                  </Link>
                  <Link
                    to={parseUrl(associatedEpisodeLink)}
                    className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100"
                  >
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="mr-2 h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      Go to {associatedMeta}
                    </div>
                  </Link>
                </>
              )}
              <button
                onClick={() => addRemoveWatchlist(detailLink)}
                className="px-4 py-2 hover:bg-gray-600 hover:text-gray-100"
              >
                <div className="flex items-center">
                  {saveActivity ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mr-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mr-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                  {saveActivity ? "Remove from Watchlist" : "Add to Watchlist"}
                </div>
              </button>
            </div>
            <Popover.Arrow className="fill-gray-800" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
}
