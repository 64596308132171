import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  DevBanner,
  StatusBanner,
} from "@/components/notifications/BannerNotification";
import * as Sentry from "@sentry/react";
import { LoadingFallback, router, queryClient } from "@/routes/routes";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { FlagsProvider } from "flagged";
import "@/index.css";
import { RouterProvider } from "react-router-dom";
import MaintenancePage from "@/page/maintenance/Maintenance";
import { useMaintenanceStore } from "@/store/useMaintenanceStore";
// Import test utilities (only used in development)
import "@/utils/test-maintenance";

import "@fontsource/nunito/latin-300.css";
import "@fontsource/nunito/latin-400.css";
import "@fontsource/nunito/latin-600.css";
import "@fontsource/nunito/latin-700.css";
import "@fontsource/nunito/latin-800.css";

// initialize posthog only on production
if (import.meta.env.PROD) {
  posthog.init(import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY, {
    autocapture: false,
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
    loaded: function (ph) {
      if (import.meta.env.DEV) {
        ph.opt_out_capturing(); // opts a user out of event capture
        ph.set_config({ disable_session_recording: true });
      }
    },
  });
}

// Initialize Sentry
if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `mcvod-web@${APP_VERSION}`,
    environment: import.meta.env.MODE,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],

    tracesSampleRate: 1.0, // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracePropagationTargets: ["localhost", "app.corcoplus.com"],
    replaysSessionSampleRate: 0.1, //Replay sample rate, 10% of all sessions.
    replaysOnErrorSampleRate: 1.0, //Replay on error sample rate, 100% for sessions with an error.
  });
}

// MaintenancePageWrapper component to handle the maintenance state
const MaintenancePageWrapper = () => {
  const isInMaintenance = useMaintenanceStore((state) => state.isInMaintenance);
  return <MaintenancePage isVisible={isInMaintenance} />;
};

const rootElement = document.getElementById("root");
ReactDOM.createRoot(rootElement!).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <FlagsProvider
        features={{
          sideBarSearchFlag: false,
          sideBarWatchlistFlag: true,
          topBarFlag: false,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <StatusBanner active={false} />
          <MaintenancePageWrapper />
          <RouterProvider
            router={router}
            fallbackElement={<LoadingFallback />}
          />
          <DevBanner env={import.meta.env.DEV} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </FlagsProvider>
    </PostHogProvider>
  </React.StrictMode>,
);
