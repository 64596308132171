import ContentPage, {
  pageLoader,
} from "@/features/content/components/ContentPage";

export const loader = pageLoader("Topics");

export default function Topic() {
  return (
    <ContentPage
      pageType="Topics"
      pageDesc="Explore content grouped by topic."
    />
  );
}
