import React from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { privateFetch } from "@/utils/fetch";

export function useGenres() {
  return useInfiniteQuery(
    ["genres"],
    ({ pageParam = 1 }) =>
      privateFetch.get(`/content/genres/?page=${pageParam}`),
    {
      getPreviousPageParam: (firstPage) => firstPage.data,
      getNextPageParam: (lastPage, page) => {
        if (lastPage.data._next === null) return undefined;
        return lastPage.data._next.charAt(lastPage.data._next.length - 1);
      },
      keepPreviousData: true,
    },
  );
}
