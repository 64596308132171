import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "@/store/useUserStore";
import { PAGE_SIZE } from "@/utils/constants";
import { searchFetch } from "@/utils/fetch";

const ALL_TYPES = "movie,show,collection,episode,topic";
const BASE_TYPES =
  "movie,show,season,episode,livestream,topic,genre,collection";

export function useSearch(query) {
  const COUNTRY = useUserStore((state) => state.country);
  const CURRENT_ACCESS_TIER = useUserStore((state) => state.access_tier);
  // Transform granted and admin to 'donor' since the api only accepts this donor or free
  // TODO: Add a check for the access tier and use the correct tier

  const ACCESS_TIER =
    CURRENT_ACCESS_TIER === "premium" ? "donor" : CURRENT_ACCESS_TIER;

  return useQuery({
    queryKey: ["searchResults", query],
    queryFn: ({
      region = COUNTRY,
      access_tier = ACCESS_TIER,
      size = PAGE_SIZE,
      index = ALL_TYPES,
    }) =>
      searchFetch
        .get(
          `/search/?query=${query}&region=${region}&access_tier=${access_tier}&index=${index}&size=${size}`,
        )
        .then((res) => res.data.data),
    // The query will not execute until the query exists
    enabled: !!query,
  });
}
