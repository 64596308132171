import { Button } from "@headlessui/react";
import { MeshGradient } from "@mcvod-apps/ui-components";
import { Form, useNavigate } from "react-router-dom";

export default function LogoutConfirmation() {
  const navigate = useNavigate();

  return (
    <div className="focus:outline-non relative z-50 h-screen w-screen text-center">
      <div className="fixed inset-0 h-full w-full">
        <MeshGradient
          className="absolute inset-0"
          foreground="#1f2937"
          background="#030712"
          primary="#4338ca"
        />
      </div>
      <div className="flex min-h-full min-w-full items-center justify-center p-4">
        <div className="w-full max-w-lg rounded-xl border border-white/10 bg-white/5 p-6 backdrop-blur-2xl">
          <h3 className="text-2xl/8 font-bold text-white">Confirm Logout</h3>
          <p className="mt-2 text-base/7 text-white/50">
            {" "}
            Are you sure you want to log out?
          </p>
          <div className="mt-4 flex justify-center gap-4">
            <Button
              onClick={() => navigate(-1)} // Go back to previous page
              className="bg-card-foreground/10 hover:bg-card-foreground/50 w-full items-center gap-2 rounded-full px-4 py-2 text-center text-sm/6 font-semibold text-white data-[focus]:outline-1 data-[focus]:outline-white data-[hover]:outline-1 data-[hover]:outline-white"
            >
              Cancel
            </Button>
            <Form method="post" className="w-full">
              <Button
                type="submit"
                className="bg-destructive hover:bg-destructive/80 w-full items-center gap-2 rounded-full px-4 py-2 text-center text-sm/6 font-semibold text-white data-[focus]:outline-1 data-[focus]:outline-white data-[hover]:outline-1"
              >
                Logout
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
