import { Fragment } from "react";
import DetailMeta from "@/components/DetailMeta";
import { ButtonRound } from "@/components/common/Button";

export default function DetailBlock({ data, playUrl, page }) {
  return (
    <div className="relative mx-auto -mt-20 mb-0 flex w-full">
      <div className="relative z-10 flex w-[90%] max-w-[874px] flex-col justify-center pt-20 after:absolute after:inset-0 after:-z-10 after:bg-gradient-to-r after:from-gray-950 after:via-gray-950/95 md:min-w-[500px]">
        <div className="Logo" />
        <div className="Details">
          <h1 className="mb-4 text-5xl font-bold tracking-tight text-gray-200">
            {data.title}
          </h1>
          <div className="my-4">
            <DetailMeta data={data} page={page} />
          </div>
          <div className="my-[5px] flex max-w-full flex-col flex-wrap items-start justify-evenly">
            <p className="max-w-full text-xl font-normal leading-relaxed text-gray-300">
              {data.description}
            </p>

            {page === "movie" || page === "show" ? (
              <div className="pt-4">
                <div className="">
                  {data.production_team.lead_roles.count > 0 ? (
                    <span className="text-gray-500">Starring: </span>
                  ) : null}

                  <span className="text-gray-400">
                    {data.production_team.lead_roles.count > 0
                      ? data.production_team.lead_roles.results.map(
                          (role, index) => (
                            <Fragment key={role.pk}>
                              {role.title}
                              {index !==
                                data.production_team.lead_roles.count - 1 &&
                                ", "}
                            </Fragment>
                          ),
                        )
                      : null}

                    {data.production_team.guest_roles.count > 0 &&
                    data.production_team.lead_roles.count > 0
                      ? ", "
                      : ""}
                    {data.production_team.guest_roles.count > 0
                      ? data.production_team.guest_roles.results.map(
                          (role, index) => (
                            <Fragment key={role.pk}>
                              {role.title}
                              {index !==
                                data.production_team.guest_roles.count - 1 &&
                                ", "}
                            </Fragment>
                          ),
                        )
                      : null}
                  </span>
                </div>
                <div className="">
                  {data.production_team.directors ? (
                    <span className="text-gray-500">
                      Director:{" "}
                      <span className="text-gray-400">
                        {data.production_team.directors}
                      </span>{" "}
                    </span>
                  ) : null}
                </div>
              </div>
            ) : null}

            {page === "trailer" && playUrl ? (
              <div className=" ButtonBlock mt-6">
                <ButtonRound
                  className="space-x-2 self-start"
                  size="rnd"
                  text="Trailer"
                  varient="plain"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="z-0 flex h-0 flex-1 pb-[33%]">
        <div
          className="absolute right-[-3vw] h-full w-full bg-cover bg-left-top bg-no-repeat bg-origin-padding after:absolute after:left-0 after:h-full after:w-full after:bg-gradient-to-t after:from-gray-950 after:via-gray-950/0"
          style={{
            right: `${page !== "trailer" ? "-3vw" : "-5vw"}`,
            backgroundImage: `url(${data.images.feature})`,
            backgroundOrigin: "padding-box",
          }}
        />
      </div>
    </div>
  );
}
