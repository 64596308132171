import React, { useRef } from "react";
import { Spinner } from "@/components/common";
import { useSaveActivity } from "@/api/useSaveActivity";
import { ButtonRound } from "@/components/common/Button";
import { APP_NAME } from "@/utils/constants";
// utils
import { ContentGrid } from "@/features/content/components/ContentGrid";
import Thumbnail from "@/components/Thumbnail";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { useDeleteWatchlist } from "@/api/mutations/watchlist";
import { Icon } from "@mcvod-apps/icons";
import { requireAuth } from "@/utils/requireAuth";
import { useUserStore } from "@/store/useUserStore";
import { LoaderFunctionArgs } from "react-router";
import { AxiosError } from "axios";

interface WatchListProps {
  data: any;
  error: AxiosError;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: any;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAuth(request);
  document.title = `Watchlist | ${APP_NAME}`;
  return null;
}

function WatchList() {
  const { id: userId } = useUserStore((state) => state);
  const {
    data,
    error,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSaveActivity(userId) as WatchListProps;
  const { mutate } = useDeleteWatchlist(["saveactivity"]);
  const loadMoreButtonRef = React.useRef<HTMLDivElement | null>(null);

  useIntersectionObserver({
    root: null,
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (data?.pages[0].count < 1) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center text-gray-400">
        <Icon name="plus" size={100} weight="fill" className="text-gray-800" />
        <h4 className="text-3xl font-bold text-white">
          Your watchlist is empty{" "}
        </h4>
        <p> Add content to your watchlist and it will appear here.</p>
      </div>
    );
  }

  if (isLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );
  return (
    <>
      <ContentGrid pageType="Watchlist">
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
          {data.pages.map((page: any) =>
            page.results.map((item: any) => (
              <Thumbnail
                key={item.pk}
                {...item}
                image={item.content_object.images.thumbnail}
              >
                <div className="group absolute bottom-2 right-2 z-10 flex items-center justify-between">
                  <ButtonRound
                    size="rnd"
                    varient="plain"
                    IconStart={<Icon name="close" size={24} weight="bold" />}
                    onClick={() => mutate(item._detail)}
                    title="Remove"
                  />
                </div>
              </Thumbnail>
            )),
          )}
        </div>
        <div ref={loadMoreButtonRef}>
          {isFetchingNextPage ? (
            <Spinner size={8} />
          ) : hasNextPage ? (
            <button onClick={() => fetchNextPage()}>Load More</button>
          ) : null}
        </div>
      </ContentGrid>
    </>
  );
}

export default WatchList;
