import { redirect } from "react-router-dom";
import { useUserStore } from "@/store/useUserStore";

export const isUserAuthenticated = () => {
  const { accessToken, id } = useUserStore.getState();
  return Boolean(accessToken && id);
};

export async function requireAuth(request?: Request) {
  const pathname = request?.url
    ? new URL(request.url).pathname
    : window.location.pathname;

  // Don't check auth on login page to prevent redirect loops
  if (pathname === "/login") {
    return;
  }

  if (!isUserAuthenticated()) {
    if (pathname === "/" || pathname === "/logout") {
      throw redirect("/login");
    }
    throw redirect(`/login?from=${pathname}`);
  }
}

export async function hasAuth() {
  const pathname = window.location.pathname;

  // Don't redirect if already on login page
  if (pathname === "/login") return;
  // Don't redirect back to the / route

  if (isUserAuthenticated()) {
    throw redirect("/discover");
  }
}
