import { useQuery } from "@tanstack/react-query";
import { useUserStore } from "@/store/useUserStore";
import { searchFetch } from "@/utils/fetch";

export function useSearchComplete(query, renderSuggestions) {
  //Get the user info from the user context
  const { country: COUNTRY, access_tier: CURRENT_ACCESS_TIER } = useUserStore(
    (state) => state,
  );
  // Transform granted and admin to 'donor' since the api only accepts this donor or free
  // TODO: Add a check for the access tier and use the correct tier
  const ACCESS_TIER =
    CURRENT_ACCESS_TIER === "premium" ? "donor" : CURRENT_ACCESS_TIER;

  return useQuery({
    queryKey: ["searchComplete", query],
    queryFn: ({ region = COUNTRY, access_tier = ACCESS_TIER }) =>
      searchFetch
        .get(`/complete/?query=${query}&region=${region}&tier=${access_tier}`)
        .then((res) => res.data.data),

    enabled: renderSuggestions(query),
  });
}
