import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, redirect } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { publicFetch } from "@/utils/fetch";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import Footer from "@/components/Footer";
import { Navlink } from "@/components/common/Hyperlink";
import Label from "@/components/common/Label";
import { SecureTextInput, TextInput } from "@/components/TextInput";
import Checkbox from "@/components/common/Checkbox";
import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import { APP_NAME, ORGANIZATION_ID } from "@/utils/constants";
import { Icon } from "@mcvod-apps/icons";
import Verify from "@/page/signup/Verify";
import logo from "@/images/logo.svg";
import { hasAuth } from "@/utils/requireAuth";

export async function loader({ request }: { request: Request }) {
  await hasAuth();
  document.title = `Register | ${APP_NAME}`;
  return null;
}

const siteKey = import.meta.env.VITE_APP_SITE_KEY;

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
    // Testing if contains only numbers
    .matches(/(?!^\d+$)^.+$/, "Password cannot be entirely numeric"),
  newsLetterSignup: Yup.boolean(),
});

const Signup = () => {
  const [signupSuccess, setSignupSuccess] = useState('');
  const [signupError, setSignupError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [redirectOnSuccess, setRedirectOnSuccess] = useState(false);

  const submitCredentials = async (credentials) => {
    try {
      setLoginLoading(true);
      const signupUser = await publicFetch.post(`users/signup/`, credentials);
      setCurrentUserEmail(signupUser.data.email);
      if (signupUser.status === 201) {
        setSignupSuccess("Account has been created");
      }
      setTimeout(() => {
        setRedirectOnSuccess(true);
      }, 700);
    } catch (error) {
      console.error("5. API Error:", error);
      setLoginLoading(false);
      const { status, data } = error.response;
      if (status === 409) {
        setSignupError(
          "An account with the provided email address already exists or the input is invalid.",
        );
      }
    }
  };

  const [revealDetail, setRevealDetail] = React.useState(false);

  const { t, i18n } = useTranslation("translation");
  return (
    <section
      className="relative flex h-screen w-full flex-col items-center justify-center p-8 sm:pt-10"
      data-testid="signup"
    >
      {redirectOnSuccess ? (
        <Verify user={currentUserEmail} />
      ) : (
        <Card>
          <div className="text-gray-400">
            <div className="m-auto mb-10 w-32">
              <img src={logo} alt="Logo" />
            </div>
            <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
              {t("signup.welcome")}
            </h2>
            <p className="text-center text-sm">
              {t("signup.have-account")}{" "}
              <Link className="text-indigo-400" to="/login">{t("signup.sign-in")}</Link>
            </p>
          </div>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              password: "",
              newsLetterSignup: true,
            }}
            validationSchema={SignupSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) => {
              submitCredentials(values);
              setSubmitting(false);
            }}
          >
            {({ handleSubmit, values, errors, touched, isSubmitting }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                {signupSuccess && !redirectOnSuccess && (
                  <FormSuccess text={signupSuccess} />
                )}
                {signupError && <FormError text={signupError} />}

                <div className="flex flex-col space-y-6">
                  <div>
                    <div className="mb-1">
                      <Label text={t("signup.name-label")} />
                    </div>
                    <div className="flex">
                      <div className="mr-3 flex-1 flex-col">
                        <Field
                          name="first_name"
                          type="text"
                          className="mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0"
                          placeholder={t("signup.f-name-placeholder")}
                          autoComplete="given-name"
                        />
                        {touched.first_name && errors.first_name && (
                          <div className="mt-1 text-sm text-red-500">
                            {errors.first_name}
                          </div>
                        )}
                      </div>
                      <div className="flex-1 flex-col">
                        <Field
                          name="last_name"
                          type="text"
                          className="mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0"
                          placeholder={t("signup.l-name-placeholder")}
                          autoComplete="family-name"
                        />
                        {touched.last_name && errors.last_name && (
                          <div className="mt-1 text-sm text-red-500">
                            {errors.last_name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mb-1">
                      <Label text={t("signup.e-label")} />
                    </div>
                    <Field
                      name="email"
                      type="email"
                      className="mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0"
                      placeholder={t("signup.e-placeholder")}
                      autoComplete="username"
                    />
                    {touched.email && errors.email && (
                      <div className="mt-1 text-sm text-red-500">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="mb-1">
                      <Label text={t("signup.p-label")} />
                    </div>
                    <Field
                      name="password"
                      type="password"
                      className="mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0"
                      placeholder="Your memorable password"
                      autoComplete="new-password"
                    />
                    {touched.password && errors.password && (
                      <div className="mt-1 text-sm text-red-500">
                        {errors.password}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  <ButtonRound
                    type="submit"
                    className="w-full space-x-2 self-start"
                    size="rnd"
                    text={t("button.sign-up")}
                    loading={loginLoading}
                    disabled={isSubmitting}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      )}
      <Footer />
    </section>
  );
};

export default Signup;
