import PropTypes from "prop-types";
import { Spinner } from "@/components/common";
import { useWindowSize } from "@uidotdev/usehooks";
import { Carousel } from "@/components/CollectionCarousel";

export default function ContinueWatchingRow({
  title,
  data,
  loading,
  isError,
  error,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}) {
  const size = useWindowSize();

  let NUM_SLIDES;
  if (size.width < 640) NUM_SLIDES = 2;
  if (size.width > 768) NUM_SLIDES = 3;
  if (size.width > 1024) NUM_SLIDES = 4;
  if (size.width > 1280) NUM_SLIDES = 6;

  if (loading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>{`Error: ${error.message}`}</p>
      </div>
    );

  return (
    <div className="mb-10 w-full">
      {title && (
        <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">{title}</h3>
      )}
      <Carousel
        data={data}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        slideNumber={NUM_SLIDES}
      />
    </div>
  );
}

ContinueWatchingRow.propTypes = {
  data: PropTypes.shape({
    results: PropTypes.any,
  }),
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
  isError: PropTypes.any,
  loading: PropTypes.any,
  title: PropTypes.any,
};
