import { requireAuth } from "@/utils/requireAuth";
import { LoaderFunctionArgs, Outlet } from "react-router";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await requireAuth(request);
  return null;
};

export function Account() {
  return (
    <div className="flex flex-col items-center justify-center ">
      <Outlet />
    </div>
  );
}
