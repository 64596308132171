import React from "react";
import {
  useQueryClient,
  useQuery,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useFeature } from "flagged";
import parseUrl from "url-parse";
import { useUserStore } from "@/store/useUserStore";
import { IMG_URI } from "@/utils/constants";

// Components
import Collections from "@/components/Collections";
import { LiveStreamsRow } from "@/components/LivestreamsRow";
import ContinueWatchingRow from "@/components/ContinueWatchingRow";
import WatchlistRow from "@/components/WatchlistRow";
import {
  FeaturedCarousel
} from "@mcvod-apps/ui-components";

import {
  APP_NAME,
  STALE_TIME,
  PAGE_SIZE_ROW,
  PAGE_SIZE,
} from "@/utils/constants";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";
import { useLoaderData } from "react-router";

// api requests
async function getFeatured() {
  const res = await privateFetch.get("/content/featured?is_kids=false");
  return res.data.results;
}

async function getLivestreams() {
  const res = await privateFetch.get("/content/livestreams?ordering=-recent");
  return res.data;
}

async function getPlayActivity({ pageParam = 1, pk }) {
  const ordering = "-play_end_date";
  const pageSize = PAGE_SIZE_ROW;
  const startPointMin = 0.01;
  const endPointMax = 0.95;

  const res = await privateFetch.get(
    `/users/${pk}/activity/playactivity/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}&content_model=episode&content_model=movie&play_percentage_min=${startPointMin}&play_percentage_max=${endPointMax}`,
  );
  return res.data;
}

async function getSaveActivity(pk) {
  let pageParam = 1;
  let ordering = "position";
  let pageSize = PAGE_SIZE_ROW;

  const res = await privateFetch.get(
    `/users/${pk}/activity/saveactivity/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}`,
  );
  return res.data;
}

async function getCollections({ pageParam = 1 }) {
  const ordering = "position";
  const pageSize = PAGE_SIZE;
  const isKids = false;

  const res = await privateFetch.get(
    `/content/collections/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}&is_kids=${isKids}&is_featured=false&is_nested=false`,
  );
  return res.data;
}

//Define query
const livestreamsQuery = () => ({
  queryKey: ["livestreams"],
  queryFn: async () => getLivestreams(),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

const featuredQuery = () => ({
  queryKey: ["featured"],
  queryFn: async () => getFeatured(),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

const playActivityQuery = (pk) => ({
  queryKey: ["playactivity"],
  queryFn: async ({ pageParam = 1 }) => getPlayActivity({ pk, pageParam }),
  getNextPageParam: (lastPage, page) => {
    const { query } = parseUrl(lastPage._next, true);
    return query.page;
  },
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

const saveActivityQuery = (pk) => ({
  queryKey: ["watchlist"],
  queryFn: async () => getSaveActivity(pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

const collectionsQuery = () => ({
  queryKey: ["collections"],
  queryFn: async ({ pageParam = 1 }) => getCollections({ pageParam }),
  getNextPageParam: (lastPage, page) => {
    const { query } = parseUrl(lastPage._next, true);
    return query.page;
  },
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient) =>
  async ({ request, params }) => {
    await requireAuth(request);
    document.title = `Discover | ${APP_NAME}`;
    const state = useUserStore.getState();
    const id = state.id;

    const featured = featuredQuery();
    const livestreams = livestreamsQuery();
    const playActivity = playActivityQuery(id);
    const saveActivity = saveActivityQuery(id);
    const collections = collectionsQuery();

    return (
      { id },
      //TODO: queryClient.ensureQueryData instead of getQueryData
      queryClient.getQueryData(featured.queryKey) ??
        (await queryClient.fetchQuery(featured)),
      queryClient.getQueryData(livestreams.queryKey) ??
        (await queryClient.fetchQuery(livestreams)),
      queryClient.getQueryData(playActivity.queryKey) ??
        (await queryClient.fetchInfiniteQuery(playActivity)),
      queryClient.getQueryData(saveActivity.queryKey) ??
        (await queryClient.fetchQuery(saveActivity)),
      queryClient.getQueryData(collections.queryKey) ??
        (await queryClient.fetchInfiniteQuery(collections))
    );
  };

function Discover() {
  const { id } = useLoaderData();

  const sideBarWatchlistFlag = useFeature("sideBarWatchlistFlag");

  const {
    data: featuredData,
    isLoading: featuredLoading,
    isSuccess: featuredSuccess,
  } = useQuery(featuredQuery());

  const {
    data: livestreamsData,
    isFetching: livestreamsFetching,
    isLoading: livestreamsLoading,
    isError: livestreamsIsError,
    error: livestreamsError,
  } = useQuery(livestreamsQuery());

  const {
    data: collectionsData,
    isFetching: collectionsFetching,
    fetchNextPage: collectionsFetchNextPage,
    hasNextPage: collectionsHasNextPage,
    isFetchingNextPage: collectionsFetchingNextPage,
  } = useInfiniteQuery(collectionsQuery());

  const {
    data: playActivityData,
    isFetching: playActivityFetching,
    isLoading: playActivityLoading,
    isError: playActivityisError,
    error: playActivityError,
    fetchNextPage: playActivityFetchNextPage,
    hasNextPage: playActivityHasNextPage,
    isFetchingNextPage: playActivityFetchingNextPage,
  } = useInfiniteQuery(playActivityQuery(id));

  const {
    data: saveActivityData,
    isLoading: saveActivityLoading,
    isError: saveActivityisError,
    error: saveActivityError,
    isFetching: saveActivityFetching,
    fetchNextPage: saveActivityFetchNextPage,
    hasNextPage: saveActivityHasNextPage,
    isFetchingNextPage: saveActivityFetchingNextPage,
  } = useQuery(saveActivityQuery(id));

  return (
    <>
      <div className="-mt-20" data-testid="discover">
        <FeaturedCarousel
          items={featuredData}
          loading={featuredLoading}
          success={featuredSuccess}
          imgUri={IMG_URI}
        />
        <LiveStreamsRow
          data={livestreamsData}
          loading={livestreamsLoading}
          error={livestreamsError}
          isError={livestreamsIsError}
        />
        {playActivityData?.pages.length > 0 ? (
          <ContinueWatchingRow
            title="Continue Watching"
            data={playActivityData}
            loading={playActivityLoading}
            isError={playActivityisError}
            error={playActivityError}
            fetchNextPage={playActivityFetchNextPage}
            hasNextPage={playActivityHasNextPage}
            fetchingNextPage={playActivityFetchingNextPage}
          />
        ) : null}
        {!sideBarWatchlistFlag && saveActivityData?.count > 0 ? (
          <WatchlistRow
            title="Watchlist"
            data={saveActivityData}
            loading={saveActivityLoading}
            isError={saveActivityisError}
            error={saveActivityError}
            fetchNextPage={saveActivityFetchNextPage}
            hasNextPage={saveActivityHasNextPage}
            fetchingNextPage={saveActivityFetchingNextPage}
          />
        ) : null}

        <Collections
          collections={collectionsData}
          loading={collectionsFetching}
          fetchNextPage={collectionsFetchNextPage}
          hasNextPage={collectionsHasNextPage}
          fetchingNextPage={collectionsFetchingNextPage}
        />
      </div>
    </>
  );
}

export default Discover;
