import ContentPage, {
  pageLoader,
} from "@/features/content/components/ContentPage";

export const loader = pageLoader("Kids");

export default function Kids() {
  return (
    <ContentPage
      pageType="Kids"
      pageDesc="Discover our latest kids movies and shows"
    />
  );
}
