type ProgressType = {
  progress?: number;
};

export default function Progress({ progress }: ProgressType) {
  if (progress === undefined) return null;

  const progressPercentage = progress * 100;

  return progress !== undefined ? (
    <div className="mt-2  h-1 w-full rounded-full bg-indigo-50 bg-opacity-10">
      <div
        className="h-full rounded-full bg-indigo-700"
        style={{ width: `${progressPercentage}%` }}
      />
    </div>
  ) : null;
}
