import React from "react";
import { Spinner } from "@/components/common";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_NAME, BASE_IMG_URL } from "@/utils/constants";
import { ContentGrid } from "@/features/content/components/ContentGrid";
import Thumbnail from "@/components/Thumbnail";
import { Icon } from "@mcvod-apps/icons";
// API
import { useSearch } from "@/api/useSearch";
import SearchBar from "@/components/common/Search/SearchBar";
import { SearchInput } from "@/page/search/SearchInput";
import { useCollections } from "@/api/useCollections";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { requireAuth } from "@/utils/requireAuth";

export async function loader({ request }) {
  await requireAuth(request);
  return null;
}

// Utils

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

// TODO: Add SearchInput to this layout
function SearchLayout({ children }) {
  return <div>{children}</div>;
}

function EmptySearchContent({ data, isLoading, isIdle, isError, error }) {
  if (isLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner size={8} />
      </div>
    );
  if (isIdle)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Icon name="frown" size={120} className="currentColor" />
        <h4>No suggestions were found</h4>
      </div>
    );
  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <ContentGrid>
      <h2 className="mb-5 text-base text-gray-400">Explore</h2>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
        {data.pages[0].data.results.map((item, index) => (
          <Thumbnail
            key={item.pk}
            {...item}
            image={item.images.thumbnail}
            growOnHover
          >
            <p className="mt-2 text-sm text-gray-500">{item.title}</p>
          </Thumbnail>
        ))}
      </div>
    </ContentGrid>
  );
}

function SearchResults({ data, isLoading, isError, isIdle, error, query }) {
  if (isLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner size={8} />
      </div>
    );

  if (isIdle)
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center text-gray-400">
        <Icon
          name="search"
          size={100}
          weight="bold"
          className="text-gray-800"
        />
        <h4>Search for shows, movies, people, and more...</h4>
      </div>
    );

  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500">
        <p>Error: {error.message}</p>
      </div>
    );

  if (data.found === 0) {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center text-gray-400">
        <Icon name="frown" size={100} weight="fill" className="text-gray-800" />
        <h4>No results were found for "{query}"</h4>
      </div>
    );
  }

  return (
    <ContentGrid pageType="Search">
      {data.found && (
        <h2 className="mb-5 text-base text-gray-400">Results for "{query}"</h2>
      )}
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
        {data.hits.map((item) => (
          <>
            <Thumbnail
              key={item.id}
              {...item}
              _detail={item.fields.api_url}
              image={`${BASE_IMG_URL}${item.fields.image_url}`}
              growOnHover
            >
              <p className="mt-2 text-sm text-gray-500">{item.fields.title}</p>
              {/* <div className="self-start text-xs rounded-full py-0.5 px-1 bg-indigo-100 text-indigo-800 uppercase">{item.fields.content_type}</div> */}
            </Thumbnail>
          </>
        ))}
      </div>
    </ContentGrid>
  );
}

export default function Search() {
  const navigate = useNavigate();
  const query = useQueryParams();
  const [searchQuery, setSearchQuery] = React.useState("");

  // Search API
  const {
    data: searchData,
    isLoading: searchLoading,
    isIdle: searchIdle,
    isError: searchIsError,
    error: searchError,
  } = useSearch(searchQuery);

  // Collection API
  const {
    data: collectionsData,
    isLoading: collectionsLoading,
    isIdle: collectionsIdle,
    isError: collectionsIsError,
    error: collectionsError,
  } = useCollections();

  useDocumentTitle(`Search | ${APP_NAME}`);

  React.useEffect(() => {
    if (query.size > 0 && query.get("q") !== null) {
      setSearchQuery(query.get("q"));
    }
    return () => {
      setSearchQuery("");
    };
  }, [query]);

  const handleClick = (movie) => {
    if (movie.content_model === "topic") {
      navigate(`/topic/${movie.pk}`);
    }
    if (movie.content_model === "movie") {
      navigate(`/movie/${movie.pk}`);
    }
    if (
      movie.content_model === "episode" ||
      movie.content_model === "season" ||
      movie.content_model === "show"
    ) {
      const [, tvShow] = movie._detail.split("shows");
      const [, pkShow] = tvShow.split("/");
      navigate(`/show/${pkShow}`);
    }
  };

  //TODO: Get data for images and url
  return (
    <SearchLayout>
      {query.get("q") === null ? (
        <EmptySearchContent
          data={collectionsData}
          isLoading={collectionsLoading}
          isIdle={collectionsIdle}
          isError={collectionsIsError}
          error={collectionsError}
        />
      ) : (
        <SearchResults
          data={searchData}
          isLoading={searchLoading}
          isIdle={searchIdle}
          isError={searchIsError}
          error={searchError}
          query={searchQuery}
        />
      )}
    </SearchLayout>
  );
}
