import React from "react";

const FormError = ({ text }) => (
  <section className="mb-2 p-2 text-center ">
    <p className="font-bold text-red-700">
      <span className="ml-1">{text}</span>
    </p>
  </section>
);

export default FormError;
