import React, { useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Hls from "hls.js";
import "hls-video-element";
import type { HlsVideoElement } from "hls-video-element";
import { Icon } from "@mcvod-apps/icons";
import { AxiosInstance } from "axios";
import {
  MediaController,
  MediaControlBar,
  MediaTimeRange,
  MediaTimeDisplay,
  MediaPlayButton,
  MediaMuteButton,
  MediaVolumeRange,
  MediaFullscreenButton,
  MediaLiveButton,
  MediaLoadingIndicator,
  MediaSeekBackwardButton,
  MediaSeekForwardButton,
  MediaCaptionsButton,
} from "media-chrome/dist/react";

import {
  MediaChromeMenuButton,
  MediaRenditionMenu,
  MediaRenditionMenuButton,
  MediaPlaybackRateMenu,
  MediaPlaybackRateMenuButton,
  MediaCaptionsMenu,
} from "media-chrome/dist/react/menu/index.js";

interface VideoPlayerProps {
  src: string;
  preload?: "none" | "metadata" | "auto";
  className?: string;
  onError?: (error: Error) => void;
  onReady?: () => void;
  videoId?: string;
  isLive?: boolean;
  onClose?: () => void;
  playBackLink: PlayBackLinkResponse;
  playBackActivity: PlayBackActivityResponse;
  title: string;
  privateFetch: AxiosInstance;
}

interface PlayBackLinkResponse {
  link: string;
  _play_activity: string;
}

interface PlayBackActivityResponse {
  pk: number;
  content_model: string;
  play_start_date: string | null;
  play_end_date: string | null;
  play_start_point: string | null;
  play_start_point_seconds: number | null;
  play_start_point_percentage: number | null;
  play_end_point: string | null;
  play_end_point_seconds: number | null;
  play_end_point_percentage: number | null;
  play_point: string | null;
  play_point_seconds: number | null;
  play_point_percentage: number | null;
  play_percentage: number | null;
  play_session_count: number;
  rating: number | null;
}


// Only need to declare the JSX intrinsic elements
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "hls-video": React.DetailedHTMLProps<
        React.VideoHTMLAttributes<HlsVideoElement>,
        HlsVideoElement
      >;
    }
  }
}

/**
 * Video Player component that used to play videos
 * @example
 * ```tsx
 * <VideoPlayer
 *       src={https://api.corcoplus.com/v2/content/shows/290/seasons/498/episodes/13353/play/}
 *       title={Return to Reason}
 *       isLive={true}
 *       onClose={onClose}
 *       playBackLink={playBackLink}
 *       playBackActivity={playBackActivity}
 *       privateFetch={privateFetch}
 *     />
 * ```
 */
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  preload = "metadata",
  className,
  onError,
  onReady,
  videoId,
  isLive = false,
  onClose,
  playBackLink,
  playBackActivity,
  title,
  privateFetch,
}) => {
  const videoRef = useRef<HlsVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);
  const progressIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const navigate = useNavigate();

  //API Calls
  const sendPlayStarted = useCallback(
    async (currentTime: number) => {
      try {
        await privateFetch({
          method: "PUT",
          url: playBackLink?._play_activity,
          data: {
            play_point_seconds: Math.floor(currentTime) || 0,
          },
        });
      } catch (error) {
        console.error("Error sending play started:", error);
      }
    },
    [videoId, videoRef],
  );

  const sendSeekEvent = useCallback(
    async (currentTime: number) => {
      try {
        await privateFetch({
          method: "PUT",
          url: playBackLink?._play_activity,
          data: {
            play_point_seconds: Math.floor(currentTime) || 0,
          },
        });
      } catch (error) {
        console.error("Error sending seek event:", error);
      }
    },
    [videoId, videoRef],
  );

  const sendProgressUpdate = useCallback(
    async (currentTime: number) => {
      try {
        await privateFetch({
          method: "PATCH",
          url: playBackLink?._play_activity,
          data: {
            currentTime,
            play_point_seconds: Math.floor(currentTime),
          },
        });
      } catch (error) {
        console.error("Error sending progress update:", error);
      }
    },
    [videoId, videoRef],
  );

  // Progress tracking interval
  const startProgressTracking = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    progressIntervalRef.current = setInterval(() => {
      if (videoRef.current?.paused === false) {
        sendProgressUpdate(videoRef.current?.currentTime || 0);
      }
    }, 10000); // Every 10 seconds
  }, [sendProgressUpdate]);

  const stopProgressTracking = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  }, []);

  // Event handlers
  const handlePlay = useCallback(() => {
    sendPlayStarted(videoRef.current?.currentTime || 0);
    startProgressTracking();
  }, [sendPlayStarted, startProgressTracking]);

  const handlePause = useCallback(() => {
    stopProgressTracking();
  }, [stopProgressTracking]);

  const handleSeeked = useCallback(() => {
    if (videoRef.current) {
      sendSeekEvent(videoRef.current.currentTime);
    }
  }, [sendSeekEvent]);

  useEffect(() => {
    if (!videoRef.current || !src) return;

    const videoElement = videoRef.current;

    const handleManifestParsed = (event: Event) => {
      const hls = (videoElement as any).hls; // get hls instance from video element
      const subtitleTracks = hls?.subtitleTracks || [];

      videoElement.querySelectorAll("track").forEach((track) => track.remove());
      subtitleTracks.forEach((track: any, index: number) => {
        const trackElement = document.createElement("track");
        trackElement.kind = "subtitles";
        trackElement.label = track.name || `Subtitle ${index + 1}`;
        trackElement.srclang = track.lang || "en";
        trackElement.src = track.url;
        trackElement.default = index === 3;
        videoElement.appendChild(trackElement);
      });
    };

    videoElement.addEventListener("hlsManifestParsed", handleManifestParsed);


    videoElement.addEventListener("loadedmetadata", () => {
      const textTracks = videoElement.textTracks;

      for (let i = 0; i < textTracks.length; i++) {
        const track = textTracks[i];
        if (track.kind === "subtitles" || track.kind === "captions") {
          track.mode = "showing"; // Enable track if required
        }
      }
    });
    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);
    videoElement.addEventListener("seeked", handleSeeked);
    // Cleanup
    return () => {
      stopProgressTracking();
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
      videoElement.removeEventListener("seeked", handleSeeked);
      videoElement.removeEventListener("hlsManifestParsed", handleManifestParsed);
    };
  }, [onReady, handlePlay, handlePause, handleSeeked]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      navigate(-1); // Go back if no onClose provided
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
      <div className="relative aspect-video max-h-[90vh] w-full max-w-[90vw]">
        <MediaController
          className="aspect-video h-full w-full"
          defaultsubtitles
        >
          <div
            slot="top-chrome"
            className="absolute inset-x-0 top-0 flex justify-between bg-gradient-to-b from-black/80 via-black/50 to-transparent py-10"
          >
            <h1 className="px-3  py-2 text-lg font-semibold text-white">
              {title}
            </h1>
            <button
              onClick={handleClose}
              className=" rounded-full p-2 text-white duration-200 ease-in-out hover:scale-150"
              button-name="close"
              title="Close"
            >
              <Icon
                name="close"
                color="white"
                size={24}
                weight="bold"
                mirrored={false}
              />
            </button>
          </div>
          <hls-video
            ref={videoRef}
            slot="media"
            className="h-full w-full object-contain"
            crossOrigin="use-credentials"
            muted
            src={src}
            autoPlay
          />
          <MediaLoadingIndicator slot="centered-chrome" noautohide />
          <MediaCaptionsMenu hidden anchor="auto" />
          <MediaRenditionMenu hidden anchor="auto" />
          <MediaControlBar>
            <MediaPlayButton />
            {!isLive && (
              <>
                <MediaSeekBackwardButton />
                <MediaSeekForwardButton />
                <MediaTimeRange />
                <MediaTimeDisplay showduration remaining />
              </>
            )}
            {isLive && <MediaLiveButton />}
            <MediaMuteButton />
            <MediaVolumeRange />
            <MediaPlaybackRateMenu
              rates=".5 .75 1 1.25 1.5 2"
              hidden
              anchor="auto"
            />
            {!isLive && <MediaPlaybackRateMenuButton />}
            <MediaRenditionMenuButton />
            <MediaCaptionsButton />
            <MediaFullscreenButton />
          </MediaControlBar>
        </MediaController>
      </div>
    </div>
  );
};

export {
  VideoPlayer
};