import { redirect } from 'react-router-dom';
import { isInMaintenanceMode } from '@/page/maintenance/Maintenance';

/**
 * Maintenance mode check for route loaders
 * If the application is in maintenance mode, redirect to a special maintenance route
 *
 * @param options Configuration options
 * @returns null if not in maintenance mode, otherwise redirects
 */
export const checkMaintenanceMode = (options: {
  excludePaths?: string[];
  maintenanceRoute?: string;
} = {}) => {
  const {
    excludePaths = ['/maintenance'],
    maintenanceRoute = '/maintenance'
  } = options;

  return (request: Request) => {
    const url = new URL(request.url);
    const currentPath = url.pathname;

    // Skip check for excluded paths (like the maintenance page itself)
    if (excludePaths.includes(currentPath)) {
      return null;
    }

    // If in maintenance mode, redirect to the maintenance route
    if (isInMaintenanceMode()) {
      return redirect(maintenanceRoute);
    }

    return null;
  };
};

/**
 * Middleware to check for maintenance mode before rendering a route
 * Use this in route loaders to prevent rendering routes when in maintenance mode
 *
 * Example usage:
 * ```
 * export const loader = async ({ request }) => {
 *   // Check for maintenance mode first
 *   const maintenanceCheck = checkMaintenanceMode()(request);
 *   if (maintenanceCheck) return maintenanceCheck;
 *
 *   // Continue with normal loader logic
 *   // ...
 * };
 * ```
 */
export const maintenanceMiddleware = (request: Request) => {
  return checkMaintenanceMode()(request);
};