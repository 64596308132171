import React from "react";
import parseUrl from "url-parse";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@mcvod-apps/icons";
import { toTitleCase } from "@/utils/utils";
import { IMG_URI } from "@/utils/constants";

type ThumbnailWatchedProps = {
  content_model: string;
  title: string;
  image: string;
  overlay_text: string;
  progress: number;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  style: React.CSSProperties;
  className: string;
  _play: string;
  _detail: string;
  pk: string;
};

export default function ThumbnailWatched({
  content_model: contentModel,
  title,
  image,
  overlay_text: overlayText,
  progress,
  onClick,
  style,
  className,
  _play,
  _detail,
  ...props
}: ThumbnailWatchedProps) {
  const { pathname } = parseUrl(_detail || "");
  const path = pathname.replace("/v2/content", "");
  const navigate = useNavigate();

  function handlePlayClick() {
    navigate(
      `/watch/${props.pk}?p_l=${_play}&title=${title}&type=${contentModel}`,
    );
  }

  return (
    <div className="flex w-full flex-col ">
      <div
        className={`group relative cursor-pointer overflow-hidden rounded ${className}`}
      >
        {image ? (
          <>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={handlePlayClick}
              onClick={handlePlayClick}
              className="relative overflow-hidden rounded-t after:absolute after:inset-0 after:transition after:duration-100 after:ease-in after:hover:bg-gray-800/30"
            >
              <img
                src={image}
                alt={title}
                className="h-full w-full"
                onError={(e) => ((e.target as HTMLImageElement).src = IMG_URI)}
              />
            </div>

            <div className="flex flex-col overflow-hidden rounded-b bg-gray-800 text-sm text-gray-300">
              <div className="mt-2 flex flex-col px-4 md:flex-row md:items-center md:justify-between ">
                <div className="group mr-2 flex space-x-2 overflow-x-clip ">
                  <p>{title}</p>
                </div>
                <div className="flex items-center justify-between space-x-2 text-gray-400 ">
                  {overlayText ? (
                    <div className="rounded-full bg-gray-700 px-2 py-0.5 text-xs">
                      {overlayText.toUpperCase()}
                    </div>
                  ) : null}
                  <div className="flex items-center space-x-2">
                    <button
                      type="button"
                      tabIndex={0}
                      onKeyDown={handlePlayClick}
                      onClick={handlePlayClick}
                      title={`Play ${contentModel}`}
                      className="transition duration-100 ease-in hover:text-gray-200"
                    >
                      <Icon
                        name="playCircle"
                        alt="play"
                        size={20}
                        weight="fill"
                      />
                    </button>
                    <Link
                      to={path}
                      title={`${toTitleCase(contentModel)} details`}
                      className="transition duration-100 ease-in hover:text-gray-200"
                    >
                      <Icon name="info" alt="info" size={20} weight="fill" />
                    </Link>
                  </div>
                </div>
              </div>

              {progress !== undefined ? (
                <div className="mt-2 h-1  w-full bg-indigo-700/10">
                  <div
                    className="h-full  bg-indigo-700 "
                    style={{
                      width: `calc(${progress}% * 100)`,
                    }}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
