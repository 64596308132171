export const MeshGradient = ({
  className,
  ...props
}: {
  className?: string;
  background: string;
  foreground: string;
  primary: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="mesh-gradient"
      className={`h-full w-full ${className || ""}`}
      preserveAspectRatio="xMidYMid slice"
      viewBox="0 0 1000 500"
      {...props}
    >
      <defs>
        <filter
          id="blur"
          width="1000"
          height="500"
          x="0"
          y="0"
          filterUnits="userSpaceOnUse"
        >
          <feGaussianBlur stdDeviation="113"></feGaussianBlur>
        </filter>
        <filter id="noise" width="100%" height="100%" x="0" y="0">
          <feTurbulence
            baseFrequency="1"
            result="turbulence"
            stitchTiles="stitch"
            type="fractalNoise"
          ></feTurbulence>
          <feBlend in="SourceGraphic" in2="turbulence" mode="overlay"></feBlend>
        </filter>
      </defs>
      <rect
        id="background"
        width="100%"
        height="100%"
        fill={props.background}
      ></rect>
      <g id="swatches" filter="url(#blur)">
        <path
          fill={props.background}
          d="M-311.47-130h650.177v601.376H-311.47z"
        ></path>
        <path
          fill={props.foreground}
          d="M844.473 9.638h252.111v179.261H844.473z"
        ></path>
        <path
          fill={props.foreground}
          d="M28.736 254.491h440.008v453.715H28.736z"
        ></path>
        <path
          fill={props.primary}
          d="M459.262 382.8h151.96v219.582h-151.96z"
        ></path>
      </g>
      <path
        d="M0 0h1000v500H0z"
        filter="url(#noise)"
        opacity="12%"
        style={{ mixBlendMode: "overlay" }}
      ></path>
    </svg>
  );
};
