import { ButtonRound } from "@/components/common/Button";
import Label from "@/components/common/Label";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import { DeactivateAccountModal } from "@/components/Modal";
import { TextInput } from "@/components/TextInput";
import { useUserStore } from "@/store/useUserStore";
import {
  APP_NAME,
  ORGANIZATION,
  SUPPORT_EMAIL_ADDRESS,
} from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";
import { requireAuth } from "@/utils/requireAuth";
import { Icon } from "@mcvod-apps/icons";
import { Field, Form, Formik } from "formik";
import qs from "qs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSubmit } from "react-router-dom";
import * as Yup from "yup";

export async function loader({ request }) {
  await requireAuth(request);
  document.title = `Account | ${APP_NAME}`;
  return null;
}

export function Profile() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // Get all user info from store
  const userInfo = useUserStore((state) => ({
    id: state.id,
    email: state.email,
    first_name: state.first_name,
    last_name: state.last_name,
    access_reason: state.access_reason,
  }));

  const { t } = useTranslation();

  const NewPasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Current password is required"),
    new_password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
  });

  const nameSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
  });

  const submitNewPassword = async (credentials, resetForm) => {
    try {
      setSubmitLoading(true);
      const pwData = await privateFetch.put(
        `users/${userInfo.id}/password/`,
        qs.stringify(credentials),
      );

      if (pwData.status === 200) {
        setSubmitSuccess("New password has been saved");
        resetForm();
      }
      setSubmitError(false);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      const { status } = error.response;
      if (status === 400) {
        setSubmitError("There was an issue with one of the fields");
      }
      setSubmitSuccess(false);
    }
  };

  const submitUpdateName = async (values) => {
    try {
      setSubmitLoading(true);
      const { email, ...updateData } = values;

      const response = await privateFetch.put(
        `users/${userInfo.id}/`,
        qs.stringify(updateData),
      );

      if (response.status === 200) {
        // Update user info in store with the response data
        //TODO: Use react query mutations with Zustand to with a useUser Query

        useUserStore.getState().setUser({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
        });
        setSubmitSuccess("Updated name has been saved");
        setSubmitError(false);
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      // Check if error response exists before accessing status
      if (error.response?.status === 400) {
        setSubmitError("There was an issue with one of the fields");
      } else {
        setSubmitError("An unexpected error occurred");
      }
      setSubmitSuccess(false);
    }
  };
  // 'PUT' -> /users/<pk>/password/
  // -> success: new password has been saved
  const [revealDetail, setRevealDetail] = React.useState(false);

  return (
    <div className="h-full px-[3vw] pt-20">
      <div className="w-full max-w-md space-y-16 text-gray-300 ">
        <div className="">
          <div className="mb-4 text-center">
            <h1 className="mb-1 text-4xl font-bold">My Account</h1>
            <p className="text-sm text-gray-400">
              Manage your profile, notifications and other settings here
            </p>
          </div>
          <Formik
            initialValues={{
              email: userInfo.email || "",
              first_name: userInfo.first_name || "",
              last_name: userInfo.last_name || "",
            }}
            onSubmit={(values) => submitUpdateName(values)}
            validationSchema={nameSchema}
          >
            {({ values }) => (
              <Form>
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}
                <div className="">
                  <div className="">
                    <div className="">
                      <Label className="" text="Email" />
                      <Field
                        as={TextInput}
                        ariaLabel="Email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder="Enter your email"
                        disabled={true}
                        className="cursor-not-allowed border border-gray-700 bg-gray-800 text-gray-400"
                      />
                    </div>

                    <div className="">
                      <Label className="" text="First Name" />
                      <Field
                        as={TextInput}
                        ariaLabel="First Name"
                        name="first_name"
                        type="text"
                        autocomplete="given-name"
                        placeholder="Enter your first name"
                        className="cursor-text border border-gray-700 bg-gray-800 text-white focus:text-white"
                      />
                    </div>
                    <div className="">
                      <Label className="" text="Last Name" />
                      <Field
                        as={TextInput}
                        ariaLabel="Last Name"
                        name="last_name"
                        type="text"
                        autocomplete="family-name"
                        placeholder="Enter your last name"
                        className="cursor-text border border-gray-700 bg-gray-800 text-white focus:text-white"
                      />
                    </div>
                  </div>

                  <div className="mt-6">
                    <ButtonRound
                      type="submit"
                      className="w-full space-x-2 self-start"
                      size="rnd"
                      text="Save"
                      loading={submitLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="flex flex-col ">
          <div className="mb-4 mt-4 text-center">
            <h2 className="mb-1 text-4xl font-bold">Change Password</h2>
          </div>
          <Formik
            initialValues={{
              old_password: "",
              new_password: "",
            }}
            onSubmit={async (values, { resetForm }) =>
              await submitNewPassword(values, resetForm)
            }
            validationSchema={NewPasswordSchema}
          >
            {() => (
              <Form>
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}
                <div className="">
                  <div className="">
                    <div className="">
                      <Label className="" text="Current Password" />
                      <div className="relative">
                        <Field
                          name="old_password"
                          type={showPassword ? "text" : "password"}
                          autocomplete="current-password"
                          placeholder="Enter your current password"
                          className="mb-2 block h-12 w-full rounded-lg border-gray-700 bg-gray-800 pl-4 text-white placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-white focus:ring-0"
                        />
                        <div
                          className="absolute bottom-0 right-4 top-0 flex cursor-pointer items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <Icon name="show" size={20} title="Show Password" />
                          ) : (
                            <Icon name="hide" size={20} title="Hide Password" />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="flex items-center">
                        <Label className="" text="New Password" />
                        <div
                          className="ml-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-xs font-bold text-gray-500"
                          onClick={() => setRevealDetail(!revealDetail)}
                        >
                          <Icon
                            name="info"
                            size={12}
                            title="Password Requirements"
                          />{" "}
                        </div>
                      </div>
                      <div className="relative">
                        <Field
                          name="new_password"
                          type={showPassword ? "text" : "password"}
                          autocomplete="new-password"
                          placeholder="Enter your new password"
                          className="mb-2 block h-12 w-full rounded-lg border-gray-700 bg-gray-800 pl-4 text-white placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-white focus:ring-0"
                        />
                        <div
                          className="absolute bottom-0 right-4 top-0 flex cursor-pointer items-center"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? (
                            <Icon name="show" size={20} title="Show Password" />
                          ) : (
                            <Icon name="hide" size={20} title="Hide Password" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6">
                    <ButtonRound
                      type="submit"
                      className="w-full space-x-2 self-start"
                      size="rnd"
                      text="Update password"
                      loading={submitLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <DeleteAccount account={userInfo} />
      </div>
    </div>
  );
}

const DeleteAccount = ({ account }) => {
  const [toggleModal, setToggleModal] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const submit = useSubmit();

  const handleConfirmDelete = async () => {
    try {
      setIsDeleting(true);
      await privateFetch.delete(`users/${account.id}/`);
      submit(null, { method: "POST", action: "/logout" });
    } catch (error) {
      console.error("Error deleting account", error);
      setIsDeleting(false);
      //TODO: Handle error (maybe a toast or something)
    }
  };
const cannotSelfDelete =
    account.access_tier !== "free" ||
    account.access_reason !== "gift" ||
    account.access_reason !== "staff";

  const isValid = account.email && !account.email.includes("pac+");

  if (account.access_tier !== "free" && isValid) {
    return (
      <div className="flex flex-col">
        <div className="text-muted-foreground mt-4 flex flex-col text-center">
          <h2 className="mb-1 text-4xl font-bold">Delete My Account</h2>
          <p className="mb-1">
            {account.access_reason === "gift" &&
              "Your account is gifted through an organization."}
            {account.access_reason === "staff" &&
              `You have a staff account with ${ORGANIZATION}.`}
          </p>
          <p className="text-xs">
            To delete your account please contact{" "}
            <a
              className="text-link hover:text-link-foreground"
              href={`mailto:${SUPPORT_EMAIL_ADDRESS}?subject=Delete%20Account%20Request&body=I%20would%20like%20to%20delete%20my%20account%20for%20the%20${APP_NAME}%20platform%20please%20let%20me%20know%20more%20about%20my%20account.%20My%20email%20is%20${account.email}`}
            >
              {SUPPORT_EMAIL_ADDRESS}
            </a>
          </p>
        </div>
      </div>
    );
  } else {
    return (
    <>
      <DeactivateAccountModal
          modalState={toggleModal}
          toggle={setToggleModal}
          cancelAction={() => setToggleModal(false)}
          destructiveAction={handleConfirmDelete}
          isLoading={isDeleting}
        />
      <div className="flex flex-col">
          <div className="mt-4 mb-4 flex flex-col text-center">
            <h2 className="mb-1 text-4xl font-bold">Delete My Account</h2>
            <p className="text-muted-foreground text-sm">
              Deleting your account from {APP_NAME} will remove your list and
              history and unlink the devices associated with it.
            </p>
          </div>
          <ButtonRound
            className="bg-destructive text-destructive-foreground hover:bg-destructive-foreground hover:text-destructive mt-5"
            varient="danger"
            text="Delete Account"
            onClick={() => setToggleModal(!toggleModal)}
          />
        </div>
      </>
    );
  }
};
