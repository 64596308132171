import { useState, useEffect } from "react";
import SearchBar from "@/components/common/Search/SearchBar";
import UserDropdown from "@/components/common/UserDropdown";
import { HeaderBanner } from "@/components/notifications/BannerNotification";
import { useUserStore } from "@/store/useUserStore";

export default function Header() {
  const [bannerActive, setBannerActive] = useState(false);
  const [bannerHidden, setBannerHidden] = useState(false);

  const { state: userState } = useUserStore((state) => state);

  useEffect(() => {
    if (userState === "110") {
      setBannerActive(true);
    }
    return () => {
      setBannerHidden(true);
    };
  }, [userState]);

  const onHideBanner = () => setBannerHidden(true);

  return (
    <>
      <div
        className={`${bannerHidden ? "hidden" : "fixed inset-x-0 top-0 z-10"}`}
      >
        <HeaderBanner active={bannerActive} onHideBanner={onHideBanner} />
      </div>
      <header className="sticky top-0 z-50 flex  h-20 w-full items-center justify-between bg-gradient-to-b from-gray-950 to-transparent px-4 py-2 md:right-0">
        <div className="ml-auto flex">
          <SearchBar />
          <div className="mr-8" />
          <UserDropdown />
        </div>
      </header>
    </>
  );
}
