import { useInfiniteQuery } from "@tanstack/react-query";
import { PAGE_SIZE_ROW } from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";

const fetchSaveActivity = async ({
  pageParam = 1,
  ordering = "save_date",
  pageSize = PAGE_SIZE_ROW,
  userId,
}) => {
  const res = await privateFetch.get(
    `/users/${userId}/activity/saveactivity/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}`,
  );
  return res.data;
};

export function useSaveActivity(userId) {
  return useInfiniteQuery({
    queryKey: ["watchlist", userId],
    queryFn: ({
      pageParam = 1,
      ordering = "save_date",
      pageSize = PAGE_SIZE_ROW,
    }) => fetchSaveActivity({ pageParam, ordering, pageSize, userId }),
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.data?._next) return undefined;
      const url = new URL(lastPage.data._next);
      return url.searchParams.get("page");
    },
    keepPreviousData: true,
  });
}
