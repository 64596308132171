import { ButtonRound } from "./button";
// import { IMG_URI } from "@/utils/constants";
import { Transition, TransitionChild } from "@headlessui/react";
import { Icon } from "@mcvod-apps/icons";
import cn from "classnames";
import { Children, cloneElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import parseUrl from "url-parse";

//TODO: move carousel changed over to shared, make sure both apps are updated
interface FeaturedCarouselProps {
  items: any[];
  loading: boolean;
  success: boolean;
  imgUri: string
}

const FeaturedCarousel : React.FC<FeaturedCarouselProps> = ({
  items,
  loading,
  success,
  imgUri
}) => {
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className="bg-card mb-10 flex h-[50vh] max-h-[50vh] w-full animate-pulse flex-col items-start justify-center space-y-2 px-10 sm:h-[70vh] sm:max-h-[70vh]">
        <div className="bg-muted/50 h-5 w-1/12 rounded-full" />
        <div className="bg-muted/50 h-10 w-7/12 rounded-full" />
        <div className="bg-muted/50 h-10 w-3/12 rounded-full" />
      </div>
    );
  }

  return (
    <Transition
      as="div"
      appear={true}
      show={success}
      enter="transition-opacity duration-1000 ease-in-out"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="mb-10"
    >
      <FeaturedSwipeableCarousel
        title="Carousel"
        loading={loading}
        success={success}
        autoplay
        delay={10000}
        className="overflow-hidden"
      >
        {items?.map((item, i) => (
          <FeaturedCarouselItem width="100%" key={i}>
            <div className="bg-background/30 relative h-full max-h-[70vh] w-full overflow-hidden">
              <TransitionChild
                enter="transition-opacity transition ease-in-out delay-300 duration-300"
                enterFrom="opacity-0 "
                enterTo="opacity-100 "
                as="div"
              >
                <div className="absolute inset-0 z-10 flex max-w-prose flex-col justify-center space-y-5 pl-10 whitespace-pre-line">
                  {" "}
                  <div className="bg-background/90 text-foreground max-w-fit rounded-full px-2 py-1 text-xs tracking-widest uppercase">
                    {item.content_model}
                  </div>
                  <div className="text-foreground text-2xl font-bold md:text-5xl">
                    {item.title}
                  </div>
                  <ButtonRound
                    onClick={() => {
                      const { pathname } = parseUrl(item._detail || "");
                      const path = pathname.replace("/v2/content", "");
                      navigate(path);
                    }}
                    className="self-start px-4 py-4"
                    size="rnd"
                    IconStart={<Icon name="play" size={24} weight="fill" />}
                  />
                </div>
              </TransitionChild>

              <div className="from-background absolute inset-y-0 left-0 w-9/12 bg-gradient-to-r" />
              <div className="from-background absolute inset-x-0 -bottom-px h-1/2 bg-gradient-to-t" />
              <img
                src={item.images.feature}
                alt={item.title}
                className="h-full w-full object-cover object-right-bottom"
                onError={(e) => {
                  (e.target as HTMLImageElement).src = imgUri;
                  (e.target as HTMLImageElement).style.width = "648px";
                  (e.target as HTMLImageElement).style.height = "364px";
                }}
              />
            </div>
          </FeaturedCarouselItem>
        ))}
      </FeaturedSwipeableCarousel>
    </Transition>
  );
}

interface FeaturedCarouselItemProps {
  children: React.ReactNode;
  width: string;
}

const FeaturedCarouselItem = ({
  children,
  width,
}: FeaturedCarouselItemProps) => (
  <div
    className="inline-flex max-h-[50vh] bg-gray-700 text-white"
    style={{ width }}
  >
    {children}
  </div>
);

interface FeaturedSwipeableCarouselProps {
  children: React.ReactNode;
  delay: number;
  className: string;
  autoplay: boolean;
  title: string;
  loading: boolean;
  success: boolean;
}

const FeaturedSwipeableCarousel = ({
  children,
  delay = 10000,
  className,
  autoplay,
}: FeaturedSwipeableCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const numItems = Children.count(children);

  const updateIndex = (i: number) => {
    let newIndex = i;
    if (newIndex < 0) {
      newIndex = numItems - 1;
    } else if (newIndex >= numItems) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    if (autoplay) {
      const interval = setInterval(() => {
        if (!paused) {
          updateIndex(activeIndex + 1);
        }
      }, delay);
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className={cn(className)}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="whitespace-nowrap transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {Children.map(children, (child) =>
          cloneElement(child as React.ReactElement, { width: "100%" }),
        )}
      </div>
      <div className="float-right mr-4">
        {Children.map(children, (index) => (
          <button
            type="button"
            className={`${
              index === activeIndex ? "bg-primary" : "bg-muted"
            } hover:bg-primary focus:bg-primary mr-2 h-2 w-2 rounded-full last:mr-0`}
            onClick={() => {
              updateIndex(index as number);
            }}
          >
            {" "}
          </button>
        ))}
      </div>
    </div>
  );
};

export {
  FeaturedCarousel
};