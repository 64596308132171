import { Icon } from "@mcvod-apps/icons";
import { Link } from "react-router-dom";
import { APP_NAME } from "@/utils/constants";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  document.title = `Page not found | ${APP_NAME}`;
  return null;
}

export default function NotFound() {
  return (
    <div id="notfound">
      <div className="space-between flex h-screen flex-col items-center justify-center space-y-6 bg-gray-900">
        <div className="space-between flex items-baseline space-x-3 text-white ">
          <h1 className="text-9xl font-black text-gray-800 ">404</h1>
          <h2 className="text-4xl italic text-gray-800 ">Page not found</h2>
        </div>

        <Link
          to="/"
          className="bg-gradient focus:shadow-outline-blue group relative flex justify-center rounded-md bg-indigo-700 px-4 py-2 text-sm font-medium text-white shadow-lg transition duration-100 ease-in-out focus:border-blue-700 focus:outline-none active:bg-blue-700 disabled:bg-gray-700 disabled:text-gray-800"
        >
          {APP_NAME} Home
        </Link>
        <Link
          to={-1}
          replace
          className="bg-gradient focus:shadow-outline-blue group relative flex justify-center px-4 py-2 text-sm font-medium  text-white shadow-lg transition duration-100 ease-in-out focus:outline-none disabled:text-gray-800"
        >
          <Icon name="arrowLeft" /> Go Back
        </Link>
      </div>
    </div>
  );
}
