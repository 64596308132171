import { useState } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ButtonRound } from "@/components/common/Button";
import { Icon } from "@mcvod-apps/icons";

// Query Hooks
import { useLivestreamByPk } from "@/api/useLivestreamsByPk";

// Icons
import { Spinner } from "@/components/common";

import { APP_NAME, STALE_TIME } from "@/utils/constants";
import DetailBlock from "@/components/DetailBlock";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";

function getLivestreamByPk(pk) {
  return privateFetch
    .get(`/content/livestreams/${pk}/`)
    .then((res) => res.data);
}

const livestreamDetailQuery = (pk) => ({
  queryKey: ["livestreamDetail", pk],
  queryFn: async () => getLivestreamByPk(pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient) =>
  async ({ request, params }) => {
    await requireAuth(request);
    document.title = `Watch ${data?.title} | ${APP_NAME}`;

    const livestreamByPkQuery = livestreamDetailQuery(pk);

    return (
      queryClient.getQueryData(livestreamByPkQuery.queryKey) ??
      (await queryClient.fetchQuery(livestreamByPkQuery))
    );
  };

export default function LivestreamDetail() {
  const { pk } = useParams();
  const navigate = useNavigate();
  const {
    data: livestreamData,
    isFetching: livestreamIsFetching,
    isLoading: livestreamIsLoading,
    isError: livestreamIsError,
    error: livestreamError,
  } = useQuery(livestreamDetailQuery(pk));

  function handleLivestreamPlayClick() {
    navigate(
      `/watch/${pk}?p_l=${livestreamData._play}&title=${livestreamData.title}&type=${livestreamData.content_model}`,
    );
  }

  if (livestreamIsLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (livestreamIsError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {livestreamError.message}</p>
      </div>
    );

  return (
    <>
      <div className="-mt-20 h-full overflow-y-scroll px-[3vw] pt-20">
        <div className="flex w-full flex-col">
          <DetailBlock
            data={livestreamData}
            playUrl={videoSrc}
            page="livestream"
          />
          {/* <h2 className="mb-6 text-6xl font-bold text-gray-100">
                        {data.title}
                    </h2> */}
          <div className="flex space-x-4 py-1">
            <div>
              <ButtonRound
                onClick={handleLivestreamPlayClick}
                className="space-x-2 self-start px-4 py-4 text-xl"
                size="rnd"
                varient="primary"
                IconStart={<Icon name="play" size={24} weight="fill" />}
                text="Watch Livestream"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
