import { useMaintenanceStore } from '@/store/useMaintenanceStore';

/**
 * Test utility to simulate a 503 error and trigger maintenance mode
 * This can be called from the browser console: window.testMaintenanceMode()
 */
export const testMaintenanceMode = (): void => {
  useMaintenanceStore.getState().setMaintenanceMode(true);
  console.log('Maintenance mode activated for testing');
};

// Make the function available globally for testing
if (typeof window !== 'undefined') {
  (window as any).testMaintenanceMode = testMaintenanceMode;
}

/**
 * Test utility to exit maintenance mode
 * This can be called from the browser console: window.exitMaintenanceMode()
 */
export const exitMaintenanceMode = (): void => {
  useMaintenanceStore.getState().setMaintenanceMode(false);
  console.log('Exited maintenance mode');
};

// Make the function available globally for testing
if (typeof window !== 'undefined') {
  (window as any).exitMaintenanceMode = exitMaintenanceMode;
}