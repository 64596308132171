import { useQuery } from "@tanstack/react-query";
import { STALE_TIME } from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";

export function useTopicByPk(pk) {
  return useQuery(
    ["topic", pk],
    () => privateFetch.get(`/content/topics/${pk}/`).then((res) => res.data),
    {
      keepPreviousData: true,
      staleTime: STALE_TIME,
    },
  );
}
