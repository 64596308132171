import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import Label from "@/components/common/Label";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import LOGO from "@/images/logo.svg";
import { APP_NAME } from "@/utils/constants";
import { publicFetch } from "@/utils/fetch";
import { hasAuth } from "@/utils/requireAuth";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Verify from "./Verify";

export async function loader() {
  await hasAuth();
  document.title = `Request Verification Email | ${APP_NAME}`;
  return null;
}

const RequestVerificationEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be a valid email address")
    .required("Email is required"),
});

const RequestVerificationEmail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const unverified_user = searchParams.get("error") === "unverified";
  const unverified_user_email = searchParams.get("email");
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userEmail, setUserEmail] = useState();

  // Submit Credentials
  const submitCredentials = async (user) => {
    const userData = new Object();
    userData.email = user;

    try {
      setSubmitLoading(true);
      const { data } = await publicFetch.post("users/verify_request/", user);
      if (data.success) {
        setSubmitSuccess(!!data.success);
        setSubmitError("");
        setUserEmail(user.email);
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(true);
      setUserEmail(user.email);
      setSubmitError(error.response.detail);
      setSubmitSuccess(true); // set on error to direct to verify component.
    }
  };

  const { t, i18n } = useTranslation("translation");
  return (
    <>
      <section
        className="overlay-gradient relative flex h-screen w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-8 sm:pt-10"
        data-testid="verify-request"
      >
        {submitSuccess ? (
          <Verify user={userEmail} />
        ) : (
          <Card>
          <div className="text-gray-400">
            <div className="m-auto mb-10 w-32">
              <img src={LOGO} alt="Logo" />
            </div>
            <h2 className="text-gray-100 mb-2 text-center text-2xl leading-9 font-bold">
              Request New Verification Email
            </h2>
            <p className="text-center">
              {unverified_user
                ? `${unverified_user_email} has not been
                verified. Please verify your email or request a new verification email.`
                : `Verification emails are only valid for several days. Need a new
                one? Submit your email below and we'll send you a new one.`}
            </p>
            <p className="mt-2 text-center text-sm">
              Already have an account?{" "}
              <Link to="/login" className="text-indigo-400">
                {t("auth.reset-password.request.account.link")}
              </Link>
            </p>
          </div>
          <Formik
            initialValues={{
              email: unverified_user_email || "",
            }}
            enableReinitialize
            validationSchema={RequestVerificationEmailSchema}
            onSubmit={(values, { setSubmitting }) => {
              submitCredentials(values);
              setSubmitting(false);
            }}
            >
            {({
              isSubmitting,
              isValid,
              handleSubmit,
            }) => (
              <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
              className="mt-8"
              >
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}

                <div className="mb-2">
                  <div className="mb-1 flex flex-col">
                    <div className="mb-1">
                      <Label htmlFor="email" text="Enter your email" />
                    </div>
                        <Field
                          id="email"
                          name="email"
                          aria-label={t("signup.e-placeholder")}
                          type="email"
                          className="mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0"
                          autoComplete="username"
                          placeholder={t("signup.e-placeholder")}
                      />
                  </div>
                </div>

                <div className="mt-6">
                  <ButtonRound
                    type="submit"
                    text="Request new verification email"
                    loading={submitLoading}
                    className="w-full"
                    disabled={isSubmitting || !isValid}
                    />
                </div>
              </Form>
            )}
          </Formik>
        </Card>
                  )}
      </section>
    </>
  );
};

export default RequestVerificationEmail;
