import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import { Navlink } from "@/components/common/Hyperlink";
import Label from "@/components/common/Label";
import { Spinner } from "@mcvod-apps/ui-components";
import Footer from "@/components/Footer";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import { SecureTextInput, TextInput } from "@/components/TextInput";
import { storeTokens, useUserStore } from "@/store/useUserStore";
import { APP_NAME, LOGO } from "@/utils/constants";
import { privateFetch, publicFetch } from "@/utils/fetch";
import qs from "qs";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  redirect,
  useActionData,
  useNavigation,
} from "react-router-dom";
import { z } from "zod";
import { maintenanceMiddleware } from '@/utils/maintenance-check';

const ERROR_MESSAGES = {
  INVALID_CREDENTIALS: "login.invalid-credentials",
  UNKNOWN_ERROR: "login.unknown-error",
  UNVERIFIED_ACCOUNT: "login.unverified-account",
  VALIDATION: {
    EMAIL_REQUIRED: "login.validation.email-required",
    PASSWORD_REQUIRED: "login.validation.password-required",
    PASSWORD_LENGTH: "login.validation.password-length",
    INVALID_EMAIL: "login.validation.invalid-email",
  },
};


//Types
interface LoginCredentials {
  grant_type: string;
  client_id: string;
  username: string;
  password: string;
}

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  status: number | null;
  statusText: string;
  error?: string;
  fullResponse?: any;
}

interface UserResponse {
  pk: string;
  email: string;
  state: string;
}

interface DeviceResponse {
  data: {
    ip_country: string;
  };
}

interface TierResponse {
  data: {
    first_name: string;
    last_name: string;
    is_verified: boolean;
    organization: {
      external_code: string;
    };
    access_info: {
      tier: "premium" | "free";
      reason: "gift" | "subscription" | "gift" | "staff" | "default";
      expiry: string;
    };
  };
}

//Validation schema
const loginSchema = z.object({
  username: z
    .string()
    .min(1, ERROR_MESSAGES.VALIDATION.EMAIL_REQUIRED)
    .email(ERROR_MESSAGES.VALIDATION.INVALID_EMAIL),
  password: z
    .string()
    .min(1, ERROR_MESSAGES.VALIDATION.PASSWORD_REQUIRED)
    .min(8, ERROR_MESSAGES.VALIDATION.PASSWORD_LENGTH),
});

type LoginFormData = z.infer<typeof loginSchema>;

export const loader = async ({ request }: LoaderFunctionArgs) => {
  // Check for maintenance mode first
  const maintenanceCheck = maintenanceMiddleware(request);
  if (maintenanceCheck) return maintenanceCheck;
  // check if user is already authenticated
  const isLoggedIn = useUserStore.getState().accessToken;
  if (isLoggedIn) {
    return redirect("/discover");
  }

  document.title = `Login | ${APP_NAME}`;
  // get the rediretTo from the query params
  const url = new URL(request.url);
  const from = url.searchParams.get("from") || "/discover";


  return { from };
};

// Api Functions
export async function loginUser(
  creds: LoginCredentials,
): Promise<AuthResponse> {
  try {
    const res = await publicFetch.post("auth/token/", qs.stringify(creds), {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    console.log("Storing Tokens :", res.data);
    await storeTokens(res.data.access_token, res.data.refresh_token);
    return {
      access_token: res.data.access_token,
      refresh_token: res.data.refresh_token,
      statusText: res.statusText,
      status: res.status,
      fullResponse: res,
    };
  } catch (error) {
    console.error("Login error:", error);
    const errorMessage =
      error instanceof z.ZodError ? error.message :  "unknown error occured";
    return {
      error: errorMessage,
      access_token: "",
      refresh_token: "",
      statusText: "",
      status: null,
      fullResponse: error,
    };
  }
}

interface UnverifiedUserError extends Error {
  code: string;
}

export async function lookupUser(token: string): Promise<UserResponse> {
  try {
    const user = await publicFetch.get("users/lookup/", {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log("User lookup response structure:", JSON.stringify(user.data));

    if (user.data.state === "100") {
      //delete access token
        await useUserStore.getState().clearUser();
        const error = new Error("unverified") as UnverifiedUserError;
        error.code = "UNVERIFIED";
        console.log("Unverified user error:", error);
        throw error;
    }

    return {
      pk: user.data.pk,
      email: user.data.email,
      state: user.data.state,
    };
  } catch (error) {
    throw error;
  }
}

export async function currentDevice(
  token: string,
  user: string,
): Promise<DeviceResponse> {
  try {
    const device = await publicFetch(`users/${user}/account/devices/current`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log("Device lookup response structure:", JSON.stringify(device.data));
    return device;
  } catch (error) {
    throw error;
  }
}

export async function lookupUserTierandOrganization(
  token: string,
  user: string,
): Promise<TierResponse> {
  try {
    const tierAndOrganization = await privateFetch(`users/${user}/`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log("Tier and organization lookup response structure:", JSON.stringify(tierAndOrganization.data));
    return tierAndOrganization;
  } catch (error) {
    throw error;
  }
}

interface ActionData {
  error?: string;
  success?: boolean;
}

// Action Handler

export async function action({
  request,
}: ActionFunctionArgs): Promise<ActionData | Response> {
  const formData = await request.formData();
  const url = new URL(request.url);
  const from = url.searchParams.get("from");
  const redirectTo = from === null ? "/discover" : from;
  console.log("Setting post login redirect to:", redirectTo); // Debug line

  const rawFormData: Partial<LoginFormData> = {
    username: formData.get("username")?.toString(),
    password: formData.get("password")?.toString(),
  };

  try {
    // Validate form data
    const validatedData: LoginFormData = loginSchema.parse(rawFormData);
    const credentials: LoginCredentials = {
      grant_type: "password",
      client_id: import.meta.env.VITE_APP_CLIENT_ID,
      ...validatedData,
    };

    const auth = await loginUser(credentials);
    console.log("0-Auth response received:", auth);
    console.log("1-Auth response received:", auth.status);
    console.log("2-Auth response received:", auth.statusText); // Add debug logging
    console.log("3-Auth response received:", auth.fullResponse); // Add debug logging

    if (auth.status === 200) {
      console.log("Status is 200:", auth.status); // Add debug logging
      try {
        const user = await lookupUser(auth.access_token);
        console.log("User lookup response structure:", JSON.stringify(user));

        // Continue with normal login flow
        const [device, tier] = await Promise.all([
          currentDevice(auth.access_token, user.pk),
          lookupUserTierandOrganization(auth.access_token, user.pk),
        ]);
        console.log("Device lookup response structure:", JSON.stringify(device));
        console.log("Tier and organization lookup response structure:", JSON.stringify(tier));

        // Set user data and redirect
        useUserStore.getState().setUser({
          id: user.pk,
          email: user.email,
          first_name: tier.data.first_name,
          last_name: tier.data.last_name,
          state: user.state,
          org_external_code: tier.data.organization.external_code,
          country: device.data.ip_country.toLowerCase(),
          access_tier: tier.data.access_info.tier,
          access_reason: tier.data.access_info.reason,
          access_expiry: tier.data.access_info.expiry,
        });

        return redirect(redirectTo);

      } catch (error) {
        if (error instanceof Error && error.message === "unverified") {
          return redirect(
            `/verify-request?${error ? "error" : ""}=${error.message}&email=${encodeURIComponent(validatedData.username)}`,
          );
        }
        throw error;
      }
    }

    return { error: ERROR_MESSAGES.INVALID_CREDENTIALS };
  } catch (error) {
    if (error instanceof z.ZodError) {
      return { error: error.errors[0].message };
    }
    return { error: ERROR_MESSAGES.UNKNOWN_ERROR };
  }
}

// Component
const Login: React.FC = () => {
  const actionData = useActionData() as ActionData;
  const navigation = useNavigation();
  const { t } = useTranslation();

  const isLoading = navigation.state === "submitting";
  const isRedirecting = navigation.state === "loading";
  const showError = actionData?.error && !isRedirecting;


  if (isRedirecting) {
    return (
      <section className="relative flex h-screen w-full flex-col items-center justify-center p-8">
        <div className="text-center">
          <Spinner size={10} />
        </div>
      </section>
    );
  }

  return (
    <section
      className="relative flex h-screen w-full flex-col items-center justify-center p-8 sm:pt-10 "
      data-testid="signin"
    >
      <Card background="" className="">
        <div className="text-gray-400">
          <div className="m-auto mb-10 w-32">
            <img src={LOGO} alt="Logo" />
          </div>
          <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
            {t("login.welcome")}
          </h2>
          <p className="text-center text-sm">
            {t("login.no-account")}{" "}
            <Navlink to="/signup" text={t("login.sign-up")} />
          </p>
        </div>

        <Form replace method="post" className="mt-8">
          {actionData?.success && !isRedirecting && (
            <FormSuccess text={t("login.success")} />
          )}
          {showError && <FormError text={t(actionData?.error as string)} />}

          <div className="flex flex-col space-y-6">
            <div>
              <div className="flex">
                <Label className="mb-2" htmlFor="email" text={t("login.e-label")} />
              </div>

              <TextInput
                id="email"
                ariaLabel={"Email address"}
                name={"username"}
                type={"email"}
                autocomplete={"email"}
                placeholder={t("login.e-placeholder")}
                disabled={false}
                className={""}
              />
              {showError && (
                <p style={{ color: "red" }}>{t(actionData?.error as string)}</p>
              )}
            </div>

            <div className="">
              <div className="flex justify-between">
                <Label className="mb-2" htmlFor="password" text={t("login.p-label")} />
                <Navlink
                  to="/reset-password"
                  text={t("login.forgot")}
                  className="text-xs text-indigo-500"
                />
              </div>

              <SecureTextInput
                id="password"
                ariaLabel={"Password"}
                name={"password"}
                autocomplete={"current-password"}
                placeholder={t("login.p-placeholder")}
                disabled={false}
                className={""}
              />
              {showError && (
                <p style={{ color: "red" }}>{t(actionData?.error as string)}</p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <ButtonRound
              type="submit"
              varient="primary"
              className="w-full space-x-2 self-start"
              size="rnd"
              text={isLoading ? t("login.signing-in") : t("login.sign-in")}
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </Form>
      </Card>
      <Footer />
    </section>
  );
};

export default Login;
