import React from "react";
import cn from "classnames";

const Input = ({
  ariaLabel,
  name,
  type,
  placeholder,
  disabled,
  field,
  className,
  autocomplete,
  value,
  onChange,
}) => (
  <input
    {...field}
    aria-label={ariaLabel}
    name={name}
    type={type}
    placeholder={placeholder}
    disabled={disabled}
    autoComplete={autocomplete}
    value={value}
    onChange={onChange}
    className={cn(
      `mb-2 block h-12 w-full rounded-lg border-gray-400 bg-gray-100 pl-4 text-gray-500 placeholder:text-sm placeholder:text-gray-400 focus:border-indigo-700 focus:text-gray-800 focus:ring-0`,
      className,
    )}
  />
);

export default Input;
