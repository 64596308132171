// Component that displays to let the new sign up know they have to go verify their account.
import { useTranslation } from "react-i18next";
import Card from "@/components/common/Card";
import { Icon } from "@mcvod-apps/icons";
import { APP_NAME } from "@/utils/constants";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  await hasAuth();
  document.title = `Verify Your Account | ${APP_NAME}`;
  return null;
}

export default function Verify({ user }) {
  const { t, i18n } = useTranslation("translation");
  return (
    <Card className="m-auto">
      <div className="flex w-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm">
          <div className="flex flex-col items-center">
            <div className="mb-10 text-gray-100">
              <Icon name="check" size={120} weight="bold" color="white" />
            </div>
            <h2 className="mb-2 text-center text-xl font-bold leading-9 text-gray-100">
              Check your email!
            </h2>
            <p className="text-center text-gray-500">
              We've sent a verification email to <strong>{user}</strong> if the
              account exists. Please check your inbox and follow the
              instructions to verify your account.
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
}
