import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";

// Custom Hooks
import { useTopicByPk } from "@/api/useTopicByPk";

// Icons
import { Spinner } from "@/components/common";
import { APP_NAME } from "@/utils/constants";
import DetailBlock from "@/components/DetailBlock";
import Episode from "@/components/Episode";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";

function getTopicByPk(pk) {
  return privateFetch.get(`/content/topics/${pk}/`).then((res) => res.data);
}

const topicDetailQuery = (pk) => ({
  queryKey: ["topicDetail", pk],
  queryFn: async () => getTopicByPk(pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient) =>
  async ({ request, params }) => {
    const { pk } = useParams();
    await requireAuth(request);
    document.title = `Watch ${topicDetailData?.title} | ${APP_NAME}`;

    const topicByPkQuery = topicDetailQuery(pk);
    return (
      queryClient.getQueryData(topicByPkQuery.queryKey) ??
      (await queryClient.fetchQuery(topicByPkQuery))
    );
  };

export default function TopicDetail() {
  const { pk } = useParams();
  const navigate = useNavigate();
  const {
    data: topicDetailData,
    isFetching: topicDetailIsFetching,
    isLoading: topicDetailIsLoading,
    isError: topicDetailIsError,
    error: topicDetailError,
  } = useTopicByPk(pk);
  const queryClient = useQueryClient();

  function handleMoviePlayClick() {
    navigate(
      `/watch/${pk}?p_l=${topicDetailData._play}&title=${topicDetailData.title}&type=${topicDetailData.content_model}`,
    );
  }

  if (topicDetailIsLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (topicDetailIsError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {topicDetailError.message}</p>
      </div>
    );

  return (
    <>
      <div className="-mt-20 h-full overflow-y-scroll px-[3vw] pt-20 ">
        <div className="flex w-full flex-col">
          <DetailBlock data={topicDetailData} page="topic" />
        </div>
        <div className="mt-20 flex flex-col">
          {topicDetailIsLoading ? (
            <div className="flex h-full w-full items-center justify-center ">
              <Spinner color="#4C51BF" size={6} />
            </div>
          ) : topicDetailIsError ? (
            <p>Error: {topicDetailError.message}</p>
          ) : (
            <div className="mt-10 w-full overflow-y-auto">
              {topicDetailData.episodes.results.map((item, index) => (
                <Episode
                  key={item.pk}
                  pk={item.pk}
                  videoSrc={item._play}
                  episodeNumber={item.overlay_text}
                  image={item.images.episode}
                  show={item.season.show.title}
                  title={item.title}
                  description={item.description}
                  length={item.length}
                  progress={
                    item.activity.play_activity?.play_end_point_percentage || 0
                  }
                  saveActivity={item.activity.save_activity}
                  associatedMeta={item.overlay_text.toUpperCase()}
                  detailLink={item._detail}
                  associatedEpisodeLink={item._detail}
                  associatedSeasonLink={item.season._detail}
                  associatedShowLink={item.season.show._detail}
                  associatedTitle={item.season.show.title}
                  contentType="topic"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
