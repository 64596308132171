import ContentPage, {
  pageLoader,
} from "@/features/content/components/ContentPage";

export const loader = pageLoader("Shows");

export default function Shows() {
  return (
    <ContentPage pageType="Shows" pageDesc="Discover our latest TV shows" />
  );
}
