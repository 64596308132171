import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { publicFetch, privateFetch } from "@/utils/fetch";
import qs from "qs";

// User state interface
export interface UserState {
  id: string | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  state: string | null;
  country: string | null;
  access_tier: string | null;
  org_external_code: string | null;
  access_reason: string | null;
  access_expiry: string | null;
  accessToken: string | null;
  refreshTokenString: string | null;
  login: (accessToken: string, refreshToken: string) => void;
  refreshToken: () => Promise<void>;
  setUser: (user: Partial<UserState>) => void;
  clearUser: () => Promise<void>;
  checkUserUpdate: () => Promise<void>;
}

const requestNewToken = async () => {
  const refreshToken = useUserStore.getState().refreshTokenString;
  // TODO: If no refresh token, we need to clear the user
  if (!refreshToken) throw new Error("No refresh token found");

  const data = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    client_id: import.meta.env.VITE_APP_CLIENT_ID,
  };

  const response = await publicFetch.post("auth/token/", qs.stringify(data), {
    headers: { "content-type": "application/x-www-form-urlencoded" },
  });

  return response.data;
};

export const useUserStore = create<UserState>()(
  devtools(
    persist(
      (set, get) => ({
        id: null,
        email: null,
        first_name: null,
        last_name: null,
        state: null,
        country: null,
        access_tier: null,
        org_external_code: null,
        access_reason: null,
        access_expiry: null,
        accessToken: null,
        refreshTokenString: null,
        login: (accessToken, refreshToken) => {
          set({ accessToken, refreshTokenString: refreshToken });
        },
        refreshToken: async () => {
          try {
            const data = await requestNewToken();
            get().login(data.access_token, data.refresh_token);
          } catch (error) {
            console.error("Failed to refresh token:", error);
            get().clearUser();
          }
        },
        setUser: (user: Partial<UserState>) => set((state) => ({ ...state, ...user })),
        clearUser: async () => {
          const token = get().accessToken;
          if (token) {
            try {
              await deleteAccessToken(token);
            } catch (error) {
              console.log("Failed to delete token from source", error);
            }
          }

            set({
              id: null,
              email: null,
              first_name: null,
              last_name: null,
              state: null,
              country: null,
              access_tier: null,
              org_external_code: null,
              access_reason: null,
              access_expiry: null,
              accessToken: null,
              refreshTokenString: null,
            });
          },
        checkUserUpdate: async () => {
          const userId = get().id;
          const currentTier = get().access_tier;
          const currentCountry = get().country;

          if (!userId) return;

          try {
            const [countryRes, tierRes] = await Promise.all([
              privateFetch.get(`users/${userId}/account/devices/current/`),
              privateFetch.get(`users/${userId}/`),
            ]);

            const updates: Partial<UserState> = {};

            if (currentCountry !== countryRes.data.ip_country.toLowerCase()) {
              updates.country = countryRes.data.ip_country.toLowerCase();
            }

            if (currentTier !== tierRes.data.access_info.tier) {
              updates.access_tier = tierRes.data.access_info.tier;
              updates.access_reason = tierRes.data.access_info.reason;
              updates.access_expiry = tierRes.data.access_info.expiry;
            }

            if (Object.keys(updates).length > 0) {
              set(updates);
            }
          } catch (error) {
            console.error("Error checking for user updates", error);
          }
        },
      }),
      {
        name: "corcoplus-store",
      },
    ),
  ),
);

export const useUserSelectors = {
  useIsLoggedIn: () => useUserStore((state) => !!state.accessToken),
  userIsVerified: () =>
    useUserStore((state) => state.state === "110" || state.state === "111"),
  userUserProfile: () =>
    useUserStore((state) => ({
      id: state.id,
      email: state.email,
      first_name: state.first_name,
      last_name: state.last_name,
      state: state.state,
      country: state.country,
      access_tier: state.access_tier,
      org_external_code: state.org_external_code,
      access_reason: state.access_reason,
      access_expiry: state.access_expiry,
    })),
};

// Helper function to store tokens securely
export const storeTokens = async (accessToken: string, refreshToken: string) => {
  // Store access token in memory (Zustand store)
  useUserStore.getState().setUser({
    accessToken,
    refreshTokenString: refreshToken,
  });
  //TODO Store refresh token in HttpOnly cookie (requires server-side implementation)
};

const deleteAccessToken = async (token: string) => {
  const qsData = qs.stringify({
    client_id: import.meta.env.VITE_APP_CLIENT_ID,
    token: token,
  });
  const config = {
    method: "post",
    url: "auth/revoke_token/",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    data: qsData,
  };

  const { data } = await publicFetch(config);
};
