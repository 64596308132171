import React from 'react';
// @ts-ignore
import { SideBarBanner } from "@/components/notifications/BannerNotification";
import { useTranslation } from "react-i18next";
import { Link, useMatch } from "react-router-dom";
import { Icon } from "@mcvod-apps/icons";
// @ts-ignore
import { APP_NAME, LOGO, LOGO_ICON } from "@/utils/constants";
// @ts-ignore
import { useFeature } from "flagged";
// @ts-ignore
import { useUserSelectors } from "@/store/useUserStore";

interface CustomLinkProps extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  isExternal: boolean;
  to: string;
  newTab?: boolean;
}

const CustomLink: React.FC<CustomLinkProps> = ({ isExternal, to, newTab = false, ...props }) =>
  isExternal ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props} />
  ) : (
    <Link to={to} {...props} />
  );

interface MenuIconProps {
  icon: string;
  className?: string;
  size?: number;
}

const MenuIcon: React.FC<MenuIconProps> = ({ icon, className, size }) => (
  <span className={className}>
    {/* @ts-ignore */}
    <Icon name={icon} size={size || 24} color="currentColor" />
  </span>
);

interface MenuItem {
  title: string;
  icon: string;
  location?: string;
  isExternal?: boolean;
  isCollapseButton?: boolean;
  sectionHeading?: boolean;
  iconSize?: number;
  onClick?: () => void;
  newTab?: boolean;
}

interface MenuItemProps {
  item: MenuItem;
  collapsed: boolean;
  toggleCollapse: () => void;
  isMobile: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, collapsed, toggleCollapse, isMobile }) => {
  const checkActiveRouter = (name?: string): boolean => name === window.location.pathname;
  const { t } = useTranslation("translation");

  // Special handling for the collapse button
  if (item.isCollapseButton) {
    return (
      <button
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault();
          toggleCollapse();
        }}
        className={`
        text-center text-xs md:text-left md:text-sm
        ${collapsed ? "md:flex" : "md:flex-row"}
        rounded text-gray-400 hover:bg-gray-800/80 hover:text-gray-100
        ${isMobile ? "px-1 py-1 text-xs" : "px-2 py-2"}
        group relative mb-1 flex flex-initial cursor-pointer flex-col items-center md:whitespace-nowrap md:py-3
        `}
      >
        <MenuIcon
          icon={item.icon}
          className={`mb-1 md:mb-0 ${collapsed ? "md:mr-0" : "md:mr-2"} h-6 w-6
          text-gray-400 group-hover:text-gray-100
          `}
          size={item.iconSize || 24}
        />

        <div
          className={`transition-[opacity] delay-200 duration-200 ${
            collapsed ? "hidden opacity-0" : "initial opacity-100"
          } `}
        >
          {item.title}
        </div>
      </button>
    );
  }

  return (
    <CustomLink
      to={item.location || ''}
      onClick={item.onClick}
      isExternal={!!item.isExternal}
      newTab={item.newTab}
      className={`
      ${
        item.sectionHeading
          ? "text-sm font-semibold"
          : "text-center text-xs md:text-left md:text-sm"
      }
      ${collapsed ? "md:flex" : "md:flex-row"}
      ${
        checkActiveRouter(item.location)
          ? " rounded bg-gray-800/80 text-gray-100"
          : " rounded text-gray-400 hover:bg-gray-800/80 hover:text-gray-100"
      }
      ${isMobile ? "px-1 py-1 text-xs" : "px-2 py-2"}
      group relative mb-1 flex flex-initial cursor-pointer flex-col items-center md:whitespace-nowrap md:py-3
      `}
    >
      <MenuIcon
        icon={item.icon}
        size={item.iconSize || 24}
        className={`mb-1 md:mb-0 ${collapsed ? "md:mr-0" : "md:mr-2"} h-6 w-6

        ${
          checkActiveRouter(item.location)
            ? "text-gray-100 "
            : "text-gray-400 group-hover:text-gray-100"
        }
        `}
      />

      <div
        className={`transition-[opacity] delay-200 duration-200 ${
          collapsed ? "hidden opacity-0" : "initial opacity-100"
        } `}
      >
        {item.title}
      </div>
    </CustomLink>
  );
};

interface MenuContentProps {
  menuItems: MenuItem[];
  collapsed: boolean;
  isMobile: boolean;
}

const MenuContent: React.FC<MenuContentProps> = ({ menuItems, collapsed, isMobile }) => (
  <>
    {menuItems.map((item, i) => (
      <div className="my-0 flex-1 md:flex-initial" key={i}>
        <MenuItem
          item={item}
          collapsed={collapsed}
          isMobile={isMobile}
          toggleCollapse={() => {}} // Passing empty function as it's not used for regular menu items
        />
      </div>
    ))}
  </>
);

interface BottomMenuContentProps {
  menuItems: MenuItem[];
  collapsed: boolean;
  toggleCollapse: () => void;
  isMobile: boolean;
}

const BottomMenuContent: React.FC<BottomMenuContentProps> = ({
  menuItems,
  collapsed,
  toggleCollapse,
  isMobile,
}) => (
  <div className="mt-auto">
    {menuItems.map((item, i) => (
      <div
        className={`${collapsed ? " " : ""} my-1 flex-1 md:flex-initial`}
        key={i}
      >
        <MenuItem
          item={item}
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
          isMobile={isMobile}
        />
      </div>
    ))}
  </div>
);

interface SidebarNavProps {
  collapsed: boolean;
  toggleCollapse: () => void;
  isMobile: boolean;
}

export default function SidebarNav({ collapsed, toggleCollapse, isMobile }: SidebarNavProps): JSX.Element {
  const isLoggedIn = useUserSelectors.useIsLoggedIn();
  const { t } = useTranslation();
  const sidebar_search_flag = useFeature("sidebar_search_flag");
  const sidebar_watchlist_flag = useFeature("sidebar_watchlist_flag");

  const globalNav: MenuItem[] = [
    {
      title: t("menu.discover"),
      icon: "discover",
      location: "/discover",
    },
    {
      title: t("menu.movie"),
      icon: "movie",
      location: "/movies",
    },
    {
      title: t("menu.tvshow"),
      icon: "series",
      location: "/shows",
    },
    {
      title: t("menu.kid"),
      icon: "kid",
      location: "/kids",
    },
    {
      title: t("menu.topic"),
      icon: "topic",
      location: "/topics",
    },
  ];

  if (sidebar_search_flag) {
    globalNav.push({
      title: t("menu.search"),
      icon: "search",
      location: "/search",
    });
  }
  if (sidebar_watchlist_flag) {
    globalNav.push({
      title: t("menu.settings.watchlist"),
      icon: "plus",
      location: "/watchlist",
    });
  }

  const bottomNav: MenuItem[] = [
    {
      title: "Help & Support",
      icon: "info",
      location: "https://help.corco.com/",
      isExternal: true,
    },
    {
      title: `${collapsed ? "Open" : "Close"}`,
      icon: `${collapsed ? "arrowLineRight" : "arrowLineLeft"}`,
      isCollapseButton: true, // Add this flag to identify the collapse button
    },
  ];

  const settingsNav: MenuItem[] = [
    {
      title: "Back to Discover",
      sectionHeading: true,
      icon: "arrowLeft",
      iconSize: 24,
      location: "/discover",
    },
    {
      title: "Profile",
      icon: "user", // Added missing icon
      location: "/account",
    },
    {
      title: "Notifications",
      icon: "notification", // Added missing icon
      location: "/account/notifications",
    },
  ];

  // Check the route to determine the menu array to pass to MenuContent
  const settings = useMatch("/account/*");

  return (
    <>
      <Link to="/discover" className="mb-6 hidden px-2 md:block md:h-20 ">
        <div
          className={`${
            collapsed ? "justify-center" : "justify-start self-stretch"
          } min-size-10 just flex h-auto items-center text-4xl font-bold tracking-wide text-white `}
        >
          <img alt={APP_NAME + " logo"} src={collapsed ? LOGO_ICON : LOGO} />
        </div>
      </Link>
      {isLoggedIn ? (
        <MenuContent
          menuItems={settings ? settingsNav : globalNav}
          collapsed={collapsed}
          isMobile={isMobile}
        />
      ) : null}
      {!collapsed ? <SideBarBanner /> : null}
      {!isMobile ? (
        <BottomMenuContent
          menuItems={bottomNav}
          collapsed={collapsed}
          toggleCollapse={toggleCollapse}
          isMobile={isMobile}
        />
      ) : null}
    </>
  );
}