import React from "react";
import cn from "classnames";
import FormError from "@/components/common/FormError";
import Input from "@/components/common/Input";
import { Checkbox, Radio } from "@/components/common";
import { Icon } from "@mcvod-apps/icons";

// Text Fields
const TextInput = ({
  ariaLabel,
  name,
  type,
  placeholder,
  disabled,
  className,
  autocomplete,
  value,
  onChange,
}) => {
  return (
    <>
      <Input
        ariaLabel={ariaLabel}
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(className)}
        autocomplete={autocomplete}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
// Password Fields
export const SecureTextInput = ({
  ariaLabel,
  name,
  placeholder,
  disabled,
  className,
  details = false,
  iconColor = "text-gray-500",
  autocomplete,
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
      <div className="relative">
        <Input
          ariaLabel={ariaLabel}
          name={name}
          type={!showPassword ? "password" : "text"}
          placeholder={placeholder}
          disabled={disabled}
          className={cn(`pl-4`, className)}
          autocomplete={autocomplete}
        />
        <div
          className={cn(
            `absolute bottom-0 right-4 top-0 flex cursor-pointer items-center`,
            iconColor,
          )}
          onClick={() => setShowPassword(!showPassword)}
        >
          {!showPassword ? (
            <Icon name="show" size={20} title="Show Password" />
          ) : (
            <Icon name="hide" size={20} title="Hide Password" />
          )}
        </div>
      </div>
      {details ? (
        <div className="text-xs text-gray-500">
          <ul>
            <li>Your password must contain at least 8 characters.</li>
            <li>Your password can't be entirely numeric.</li>
          </ul>
        </div>
      ) : null}
    </>
  );
};

// Radio Buttons
const SelectInput = ({ name, type, value, disabled }) => {
  return (
    <>
      <Radio name={name} value={value} type={type} disabled={disabled} />
    </>
  );
};

// Check Buttons
const CheckInput = ({ name, disabled }) => {
  return (
    <>
      <Checkbox name={name} disabled={disabled} />
    </>
  );
};

export { TextInput, SelectInput, CheckInput };
