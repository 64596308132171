import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Card from "@/components/common/Card";
import { Icon } from "@mcvod-apps/icons";
import qs from "qs";
import { APP_NAME } from "@/utils/constants";
import { publicFetch } from "@/utils/fetch";
import logo from "@/images/logo.svg";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import { Navlink } from "@/components/common/Hyperlink";
import Label from "@/components/common/Label";
import { TextInput } from "@/components/TextInput";
import { ButtonRound } from "@/components/common/Button";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  await hasAuth();
  document.title = `Reset Password | ${APP_NAME}`;
  return null;
}

/**
 *  TODO: Refactor to show message regardless of error or success.
 *  users have to check inbox and spam for email. If not arrived retry.
 * */

const ResetSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const ResetPassword = () => {
  const [submitSuccess, setSubmitSuccess] = useState<string>();
  const [submitError, setSubmitError] = useState<string>();
  const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [email, setEmail] = useState<string>();

  // Submit Credentials
  const submitCredentials = async (email: string) => {
    try {
      setSubmitLoading(true);
      const { data } = await publicFetch.post(
        "users/password/reset_request/",
        qs.stringify({ email }),
        {
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        },
      );

      setEmail(email);
      setSubmitSuccess(data.message);
      setSubmitError("");
      setTimeout(() => {
        setRedirectOnSubmit(true);
      }, 1000);
    } catch (error) {
      console.error("4. Error occurred:", error);
      setEmail(email);
      setSubmitLoading(false);
      // const { data } = error.response;
      if (import.meta.env.NODE_ENV === "development")
        console.log(error.response);
      setTimeout(() => {
        setRedirectOnSubmit(true);
      }, 1000);
      // "This email does not exist."
      // TODO: if error still set submit success - just leave it in the network.
      // 400: Bad Request - Email does not exist
      // setSubmitError(error.message);
      //  setSubmitSuccess(true);
    }
  };

  const { t, i18n } = useTranslation("translation");
  return (
    <>
      <section
        className="overlay-gradient relative flex h-screen w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-8 sm:pt-10"
        data-testid="reset-password"
      >
        {!redirectOnSubmit ? (
          <Card background="" className="">
            <div className="text-gray-400">
              <div className="m-auto mb-10 w-32 ">
                <img src={logo} alt="Logo" />
              </div>
              <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
                {t("auth.reset-password.request.welcome")}
              </h2>
              <p className=" text-center">
                {t("auth.reset-password.request.message")}
              </p>
              <p className="mt-2 text-center text-sm">
                {t("auth.reset-password.request.account.message")}{" "}
                <Navlink
                  to="/login"
                  text={t("auth.reset-password.request.account.link")}
                  className="text-indigo-400"
                />
              </p>
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={(values, { setSubmitting }) => {
                submitCredentials(values.email);
                setSubmitting(false);
              }}
              validationSchema={ResetSchema}
            >
              {(formik) => (
                <Form
                  className="mt-8"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      formik.handleSubmit();
                    }
                  }}
                >
                  {submitSuccess && <FormSuccess text={submitSuccess} />}
                  {submitError && <FormError text={submitError} />}

                  <div>
                    <div className="mb-2">
                      <div className="mb-1">
                        <Label text={t("forms.e-label")} />
                      </div>
                      <TextInput
                        disabled={submitLoading}
                        ariaLabel={t("forms.e-placeholder")}
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder={t("forms.e-placeholder")}
                        className="text-gray-400"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="mt-1 text-xs text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="mt-6">
                    <ButtonRound
                      type="submit"
                      text={t("button.password-reset")}
                      loading={submitLoading}
                      className="w-full"
                      onClick={() => formik.handleSubmit()}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Card>
        ) : (
          <Card background="" className="">
            <div className="text-gray-400">
              <div className="m-auto mb-10 flex w-32 items-center justify-center text-gray-100">
                <Icon name="send" size={80} width={1} />
              </div>
              <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
                Check your email!
              </h2>
              <p className="text-center">
                We have sent an email with instructions on how to reset your
                password to - <span className="font-semibold">{email}</span>
              </p>
            </div>
          </Card>
        )}
      </section>
    </>
  );
};

export default ResetPassword;
