import React, { useState, useEffect, useRe, useContext } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { ButtonRound } from "@/components/common/Button";
import { Disclosure } from "@headlessui/react";
//Query Hooks
import { useTrailerByPk } from "@/api/useTrailerByPk";
import { useTrailers } from "@/api/useTrailers";

//Icons
import { Icon } from "@mcvod-apps/icons";
import { Spinner } from "@/components/common";

import PublicVideoPlayer from "@/components/PublicVideoPlayer";
import { APP_NAME, TAGLINE_MAIN } from "@/utils/constants";
import DetailBlock from "@/components/DetailBlock";
import parseUrl from "url-parse";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { privateFetch } from "@/utils/fetch";

const TrailerDetail = ({ setParamNext, paramNext }) => {
  // 0_FREE
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  const { pk: trailer_pk } = useParams();
  const [playUrl, setPlayUrl] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [exception, setException] = useState();
  const { data, isFetching, isLoading, isError, error } =
    useTrailerByPk(trailer_pk);
  const {
    data: trailersData,
    isFetching: trailersIsFetching,
    isError: trailersIsError,
  } = useTrailers(search);

  // access_tier=0_FREE
  // access_tier=1_DONOR
  // is_kids=true / false
  // genre=Drama

  useDocumentTitle(`${data?.title} Trailer | ${APP_NAME}`);
  useEffect(() => {
    if (data) {
      // Set the reference for redirection from trailer after login or signup
      if (data.content_object.content_model === "movie") {
        setParamNext(
          `/${data.content_object.content_model}/${data.content_object.pk}/`,
        );
      }
      if (data.content_object.content_model === "season") {
        setParamNext(
          `/shows/${data.content_object.show.pk}/${data.content_object.content_model}/${data.content_object.pk}/`,
        );
      }

      const fetchPlayUrl = async () => {
        const { pathname: a } = parseUrl(data._play);
        const playUrlPath = a.replace("/v2/", "/");

        // Get the data from the parsed playUrlPath - that is using the hook - useUrlParser
        const result = await privateFetch
          .get(playUrlPath)
          .then((res) => res.data)
          .catch((e) => setException(e.message));

        setPlayUrl(result.link);
      };

      fetchPlayUrl();
    }
  }, [data]);

  if (isFetching)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );
  return (
    <>
      <PublicVideoPlayer
        title={data.title}
        playUrl={playUrl}
        onStart={showVideo}
        onDismiss={() => {
          setShowVideo(false);
        }}
      />

      <main data-testid="trailer">
        <section className="leading=[1.4] relative mx-auto my-0 max-w-[1600] overflow-hidden bg-gray-950 px-[5vw] pb-6 pt-14">
          <DetailBlock
            data={data}
            playUrl={playUrl}
            setShowVideo={setShowVideo}
            page="trailer"
          />
        </section>

        {/* {trailersData && trailersData.results && trailersData.count > 0  */}
        {/* <div className="grid gap-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-8 -m-1.5 items-start justify-evenly  ">
            ?
            <section className="pb-6">
              <div className="">
                <h2 className="text-2xl text-white">More Like This</h2>
              </div>
                {trailersData.results.map(trailer => (
                  <Link className="max-w-full m-1.5 rounded" key={trailer.pk} to={`${trailer.pk}`}>
                    <img className="w-full rounded" src={trailer.images.thumbnail} alt={trailer.title} loading='lazy'/>
                  </Link>
                ))} */}
        {/* <div className="bg-pink-200/30">
                  <SliderCarousel>
                    {trailersData.results.map(trailer => (
                      <Link className="rounded" key={trailer.pk} to={`${trailer.pk}`}>
                              <div className="cursor-pointer group h-auto max-w-xs mr-4 overflow-hidden relative rounded max-w-28  md:max-w-48  shrink-0">
                              {!!trailer ? (
                                <div className="_Thumbnail">
                                  <div onClick={() => console.log('Thumbnail click')} className="relative overflow-hidden rounded after:absolute after:inset-0 after:hover:bg-gray-800/30 after:transition after:duration-100 after:ease-in" >
                                    <img src={trailer.images.thumbnail} alt={trailer.title} className="w-full h-full" loading="lazy"/>
                                  </div>
                                    {(trailer.content_object.content_model && trailer.content_object.content_model === 'season') ? (
                                      <div className="absolute inset-x-0 bottom-0 py-1 bg-indigo-700 bg-opacity-90 flex items-center justify-center text-gray-100 uppercase text-xs tracking-wide">
                                        Season {trailer.content_object.season_number}
                                      </div>
                                    ) : (
                                    null
                                    )}
                                </div>
                              ) : (

                                  <div className="text-pink-200"> HEY BOO </div>

                                )}
                          </div> */}
        {/* <img className=" rounded" src={trailer.images.thumbnail} alt={trailer.title} loading='lazy'/>      */}
        {/* </Link>
                    ))}
                  </SliderCarousel>
                </div> */}
        {/* </div> */}

        {/* </section>
            : null
            } */}
        {/* <section className="SectionMore pt-0 pb-6 px-[5vw] max-w-[1600] my-0 mx-auto relative leading=[1.4] overflow-hidden">
              <div className="h-full flex flex-col items-center justify-center text-white">
                  <h2 className="text-4xl mb-2"> Frequently Asked Questions</h2>
                    <div className="max-w-screen-lg">
                      <FaqDisclosure />
                    </div>

                    </div>
                  </section> */}

        <section className="SectionMore leading=[1.4] relative mx-auto my-0 mt-32 max-w-[1600] overflow-hidden px-[5vw] pb-6">
          <TrailerAccessLevelCTA next={paramNext} />
        </section>
      </main>
    </>
  );
};

export default TrailerDetail;

function TrailerAccessLevelCTA({ next }) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const CTAText = [
    {
      id: 1,
      access: "public",
      title: `There's even more to watch.`,
      body: `${APP_NAME} has an extensive library of films, documentaries, TV shows, cartoons, originals, and more.
      Watch as much as you want, anytime you want.`,
      buttonTitle: "Sign up now",
      buttonLink: "/signup",
    },
    {
      id: 2,
      access: "donor",
      title: `Sign in to ${APP_NAME}`,
      body: `${APP_NAME} has an extensive library of films, documentaries, TV shows, cartoons, originals, and more.
      Watch as much as you want, anytime you want.`,
      buttonTitle: "Sign in to watch",
      buttonLink: `/login`,
    },
  ];

  const TrailerAccessLevelDetail = ({ data, access }) => (
    <div className="h-full  text-white">
      <h2 className="mb-4 text-4xl font-bold">{data.title}</h2>
      <p className="mb-8 max-w-[70ch] text-lg font-light leading-relaxed text-gray-400">
        {data.body}
      </p>
      {data.buttonLink === "/login" ? (
        <ButtonRound
          text={data.buttonTitle}
          size="rnd"
          onClick={() =>
            navigate(`${data.buttonLink}`, {
              state: { from: next },
            })
          }
        />
      ) : (
        <ButtonRound
          text={data.buttonTitle}
          size="rnd"
          onClick={() => navigate(`${data.buttonLink}`)}
        />
      )}
    </div>
  );

  return (
    <>
      {search.includes("access=donor")
        ? CTAText.filter((accessLevel) => accessLevel.access === "donor").map(
            <TrailerAccessLevelDetail data={item} key={i} />,
          )
        : CTAText.filter((accessLevel) => accessLevel.access !== "donor").map(
            (item, i) => <TrailerAccessLevelDetail data={item} key={i} />,
          )}
    </>
  );
}

function FaqDisclosure() {
  const faq = [
    {
      title: `What is ${APP_NAME}?`,
      body: `
      <p>${APP_NAME} is Miracle Channel's online streaming service, powered by our donors. On ${APP_NAME} you can stream thousands of TV shows, movies and ministry programs with a tap of your finger.</p>
      <p>Watch ${APP_NAME} on iOS and Android devices, on any laptop or computer, or on your TV through Roku /(or stream from the app on your phone through Apple TV).</p>
      <p>Here's what you'll find inside ${APP_NAME}:
        <ul>
          <li>curated Christian teaching playlists updated weekly (called the Weekly Fix)</li>
          <li>over 12,000 titles covering movies, documentaries, TV shows, kids shows and Christian teaching</li>
          <li>all the programs you see on Miracle Channel which means you won't miss an episode of your favourite show</li>
          <li>bonus Christian teaching from pastors who aren't on our channel like Levi Lusko, Max Lucado, Christine Caine, Steven Furtick and Louis Giglio</li>
          <li>and the Miracle Channel livestream (soon you'll see the new channel livestream, plus more from TBN added to this list).</li>
        </ul>
      </p>
      <p>
        <em>*With your email address you can access a sample library of the programming listed above.</em>
        <em>**Unlimited ${APP_NAME} access is granted to monthly Miracle Channel donors. Learn more.</em>
      </p>
      `,
    },
    {
      title: `Do I have to be a Miracle Channel donor to get a ${APP_NAME} account?`,
      body: `
      <p>We created ${APP_NAME} as a gift to our donors. When you give, every dollar fuels the work God is doing through this ministry.</p>
      <p>If most of us believers are willing to give our money to media made by the world... why don't most of us support media made by Christians? That's the only way we'll see change!</p>
      <p>It's your monthly donation that helps get better shows and movies that align with your values (including original shows made here at Miracle Channel). It funds improvements to the app with more customization features. And it means we can get ${APP_NAME} on more platforms for you to watch—Apple TV, Fire TV stick, PlayStation and Xbox.</p>
      <p>To thank them for their support, monthly donors get unlimited access to ${APP_NAME} (minimum of $10/month). Click here to set up your monthly donation.</p>
      <p>If you're not ready to give yet, get ${APP_NAME} a try and get access to our sample library with just your email address. Click here to sign up.</p>`,
    },
    {
      title: `How much do I need to donate to get unlimited access to ${APP_NAME}?`,
      body: `
      <p>${APP_NAME} is, and always will be, a gift to Miracle Channel donors. Monthly donors who give a minimum of $10/month are granted unlimited access to ${APP_NAME}. Not only does this cover your online streaming costs, every donation helps us make ${APP_NAME} even better.</p>
      <p>To set up a monthly donation to Miracle Channel, click here.</p>
      <p>To learn more about what your donation does, click here.</p>`,
    },
    {
      title: `When I give to Miracle Channel, where does my money go?`,
      body: `
      <p> When you give, you...
        <ol>
          <li>help us get better shows and movies that align with your values
          <li>fund improvements to the app with more customization features
          <li>make it possible for ${APP_NAME} to be on more platforms for you to watch (Apple TV, Fire TV Stick, PlayStation and Xbox).
          <li>support the creation of excellent Canadian entertainment made by Christians (right here at Miracle Channel)
        </ol>
      </p>
      <p>Most importantly, your donation is changing the narrative. It's levelling the playing field between media made by the world and media made by Christians. To set up your monthly donation to Miracle Channel, click here. Unlimited access to ${APP_NAME} is available for a minimum monthly donation of $10 or more.</p>
      `,
    },
    {
      title: `What makes ${APP_NAME} different from other streaming services I use?`,
      body: `
        <p>The difference between Corco+ and other streaming services is that here you'll find media made by Christians who love God—and know the truth. The programs you watch, and the money you donate is supporting a platform that shares your same values.</p>
        <p>When you look at these secular streaming services and media outlets you'll see messages that are anti-Christian. Their platforms are used to tell us what to think about politics, family and faith. Most times, what THEY say is different from what the Bible says.</p>
        <p>Corco+ exists because you deserve to have a place to go that's working FOR you, not against you.</p>
        `,
    },
    {
      title: `Can I have access to ${APP_NAME} if I donate to Springs Church Mission Giving?`,
      body: `
        <p>Unfortunately, only direct Miracle Channel donors can receive full access to Corco.+</p>
        <p>Springs Church and Miracle Channel are two separate organizations. To protect you and your personal information, The Privacy Act prohibits organizations from sharing donor information with one another.</p>
        <p>To set up a recurring donation to Miracle Channel and get unlimited access to Corco+, <a href="https://miraclechannel.kindful.com/campaigns/https://miraclechannel.kindful.com/?campaign=1124354?utf8=%E2%9C%93&campaigns=https://miraclechannel.kindful.com/?campaign=1124354&amount=20">Go Donate</a>.</p>`,
    },
    {
      title: `I'm having technical difficulties. Help!`,
      body: `
        <p>No problem, we've got you covered. We have answers to your questions on our support website. If that page doesn't answer your questions, someone on our team would love to help you out!</p>
        <p>Go to <a href="https://help.corco.com">help.corco.com</a> for support.
        `,
    },
  ];

  return (
    <div className="w-full px-4 pt-16">
      <div className="mx-auto w-full p-2 ">
        {faq.map((item, i) => (
          <Disclosure key={i} className="mt-10">
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-2 flex w-full justify-between rounded-md bg-gray-800 px-4 py-2 text-left  text-lg font-medium text-white hover:bg-gray-700">
                  <span>{item.title}</span>
                  <Icon
                    name="down"
                    size={28}
                    className={`${
                      open ? "rotate-180 transform" : ""
                    }  text-indigo-400`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="mt-1 rounded-md bg-gray-800 px-4 pb-2 pt-4">
                  <article
                    className="prose text-gray-200"
                    dangerouslySetInnerHTML={{
                      __html: item.body,
                    }}
                  />
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

function ValuPropCTA() {
  return (
    <div className="ValuProp-CTA rounded-full ">
      <div className="rounded-2xl border border-indigo-700 border-opacity-25 bg-gradient-to-tr from-indigo-800 via-indigo-500 to-indigo-700 p-6 shadow-lg shadow-indigo-700/30">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-full flex-1  items-center sm:w-0">
            <div className="text-3xl font-bold text-white">{TAGLINE_MAIN}</div>
            <div className="ml-auto shrink-0">
              <div className="shadow-sm">
                <a
                  href="https://miraclechannel.kindful.com/?campaign=1118917"
                  target="_blank"
                  className="focus:shadow-outline flex cursor-pointer items-center justify-center rounded-lg border border-transparent bg-black/20 px-6 py-4 text-lg font-medium leading-5 text-gray-100 transition duration-150 ease-in-out hover:bg-indigo-700 hover:text-white hover:shadow-md hover:shadow-indigo-900 focus:outline-none"
                  rel="noreferrer"
                >
                  Access Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
