import { useInfiniteQuery } from "@tanstack/react-query";
import parseUrl from "url-parse";
import { PAGE_SIZE, STALE_TIME } from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";

export function useCollections(options = null, is_kids = null) {
  return useInfiniteQuery({
    queryKey: ["collections"],
    queryFn: async ({
      pageParam = 1,
      isKids = false,
      ordering = "position",
      pageSize = PAGE_SIZE,
    }) =>
      privateFetch.get(
        `/content/collections/?page=${pageParam}&page_size=${pageSize}&ordering=${ordering}&is_kids=${isKids}&is_featured=false&is_nested=false`,
      ),
    getNextPageParam: (lastPage, page) => {
      const { query } = parseUrl(lastPage._next, true);
      return query.page;
    },
    keepPreviousData: true,
    staleTime: STALE_TIME,
  });
}
