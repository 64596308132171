import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "@/components/common";
import Thumbnail from "@/components/Thumbnail";
import { useWindowSize } from "@/hooks/useWindowSize";
import { Carousel, CarouselItem } from "@/components/CollectionCarousel";

export default function WatchlistRow({
  title,
  data,
  loading,
  isError,
  error,
  fetchNextPage,
  hasNextPage,
  fetchingNextPage,
}) {
  const size = useWindowSize();

  // Conditionally change the number of slides based on the window size.
  let NUM_SLIDES;
  if (size.width < 640) NUM_SLIDES = 3;
  if (size.width > 768) NUM_SLIDES = 4;
  if (size.width > 1024) NUM_SLIDES = 6;
  if (size.width > 1280) NUM_SLIDES = 8;

  if (loading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>{`Error: ${error.message}`}</p>
      </div>
    );

  return (
    <div className="mb-10 w-full">
      {title && (
        <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">{title}</h3>
      )}
      {/* <div className='flex overflow-x-scroll'> */}
      <Carousel slideNumber={NUM_SLIDES}>
        {data.results.map((item, i) => (
          <CarouselItem
            key={item.pk}
            index={i}
            growOnHover
            slideNumber={NUM_SLIDES}
          >
            <Thumbnail
              image={item.content_object.images.thumbnail}
              {...item.content_object}
              key={i}
            />
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  );
}

WatchlistRow.propTypes = {
  data: PropTypes.shape({
    results: PropTypes.any,
  }),
  error: PropTypes.shape({
    message: PropTypes.any,
  }),
  fetchNextPage: PropTypes.any,
  fetchingNextPage: PropTypes.any,
  hasNextPage: PropTypes.any,
  isError: PropTypes.any,
  loading: PropTypes.any,
  title: PropTypes.any,
};
