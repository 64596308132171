import { useWindowSize } from "@uidotdev/usehooks";
import { Carousel } from "@/components/CollectionCarousel";
import { Spinner } from "@/components/common";

interface LiveStream {
  // Add properties based on your data structure
  id: string;
  title: string;
  // Add other properties as needed
}

interface LiveStreamsRowProps {
  data: {
    results: LiveStream[];
    count: number;
  };
  loading: boolean;
  isError: boolean;
  error: {
    message: string;
  };
}

export function LiveStreamsRow({
  data,
  loading,
  isError,
  error,
}: LiveStreamsRowProps) {
  const size = useWindowSize();

  let NUM_SLIDES;
  if (size.width !== null && size.width < 640) NUM_SLIDES = 4;
  if (size.width !== null && size.width > 768) NUM_SLIDES = 4;
  if (size.width !== null && size.width > 1024) NUM_SLIDES = 6;
  if (size.width !== null && size.width > 1280) NUM_SLIDES = 8;

  if (loading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );

  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>{`Error: ${error.message}`}</p>
      </div>
    );

  return (
    <div className="mb-10 w-full">
      <h3 className="mb-4 ml-[3vw] font-semibold text-gray-100">Livestreams</h3>
      <Carousel slideNumber={NUM_SLIDES} data={data} />

      {/* <div className="flex overflow-x-scroll ">{loading ? renderPlaceholders() : renderLiveStreams()}</div> */}
    </div>
  );
}
