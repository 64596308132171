import { useQueries, useQueryClient } from "@tanstack/react-query";
import { STALE_TIME } from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";
import parseUrl from "url-parse";

export interface PlayBackLinkResponse {
  link: string;
  _play_activity: string;
}

export interface PlayBackActivityResponse {
  pk: number;
  content_model: string;
  play_start_date: string | null;
  play_end_date: string | null;
  play_start_point: string | null;
  play_start_point_seconds: number | null;
  play_start_point_percentage: number | null;
  play_end_point: string | null;
  play_end_point_seconds: number | null;
  play_end_point_percentage: number | null;
  play_point: string | null;
  play_point_seconds: number | null;
  play_point_percentage: number | null;
  play_percentage: number | null;
  play_session_count: number;
  rating: number | null;
}

async function getPlayBackLink(_play: string): Promise<PlayBackLinkResponse> {
  const res = await privateFetch.get(_play);
  const link = res.data.link;
  const _play_activity = parseUrl(res.data._play_activity).pathname.replace(
    "/v2/",
    "/",
  );
  return { link, _play_activity };
}

async function getPlayBackActivity(
  _play_activity: string,
): Promise<PlayBackActivityResponse> {
  return await privateFetch.get(_play_activity).then((res) => res.data);
}

interface PlayBackLinkParams {
  _play: string;
  contentId: string | undefined;
  enabled: boolean;
}

export function usePlayBackLink({
  _play,
  contentId,
  enabled,
}: PlayBackLinkParams) {
  const queryClient = useQueryClient();

  const results = useQueries({
    queries: [
      {
        queryKey: ["play_link", contentId],
        queryFn: () => getPlayBackLink(_play),
        enabled,
        staleTime: STALE_TIME,
        keepPreviousData: true,
      },
      {
        queryKey: ["play_activity", contentId],
        queryFn: async () => {
          const playLinkData = queryClient.getQueryData<PlayBackLinkResponse>([
            "play_link",
            contentId,
          ]);
          if (!playLinkData) {
            throw new Error("Play link data not available");
          }
          return getPlayBackActivity(playLinkData._play_activity);
        },
        enabled:
          enabled && !!queryClient.getQueryData(["play_link", contentId]),
        staleTime: STALE_TIME,
        keepPreviousData: true,
      },
    ],
  });

  const [playLinkQuery, playActivityQuery] = results;

  return {
    playBackLink: playLinkQuery.data,
    playBackActivity: playActivityQuery.data,
    playBackIsLoading: playLinkQuery.isLoading || playActivityQuery.isLoading,
    playBackIsError: playLinkQuery.isError || playActivityQuery.isError,
    playBackError: playLinkQuery.error || playActivityQuery.error,
  };
}
