import React, { useState, useEffect } from "react";
import { publicFetch } from "@/utils/fetch";
import { Link, useParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import Footer from "@/components/Footer";
import { Icon } from "@mcvod-apps/icons";
import { Spinner } from "@/components/common";
import { APP_NAME } from "@/utils/constants";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  await hasAuth();
  document.title = `Verify Success | ${APP_NAME}`;
  return null;
}

export default function VerifySuccess() {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);

  const { t, i18n } = useTranslation("translation");
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const incomingValues = {
    uid: uid,
    token: token,
  };

  useEffect(() => {
    submitCredentials(incomingValues);
  }, []);

  // Submit Credentials
  const submitCredentials = async (credentials) => {
    try {
      setSubmitLoading(true);

      const { data } = await publicFetch.post(
        "users/verify_confirm/",
        credentials,
      );

      setSubmitSuccess(data.message);
      setSubmitError("");
      setTimeout(() => {
        setRedirectOnSubmit(true);
        setSubmitLoading(false);
      }, 1000);
    } catch (error) {
      setSubmitLoading(false);
      setSubmitError("There is an issue verifying your account.");
      setSubmitSuccess(null);
    }
  };

  function onClick() {
    navigate("/login");
  }

  if (submitLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  }
  if (submitError) {
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <div className="mb-2 text-center text-gray-400">
          <p>{submitError}</p>
          <Link
            to="/verify-request"
            className="text-xs text-indigo-300 hover:underline"
          >
            Request a new verification email
          </Link>
        </div>
      </div>
    );
  }

  return (
    <>
      <section className="overlay-gradient relative flex h-screen w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-8 sm:pt-10">
        <Card>
          <div className="flex flex-col items-center justify-center text-gray-400">
            <div className="mb-10 text-gray-100">
              <Icon name="check" size={112} />
            </div>
            <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
              Success
            </h2>
            <p className="text-center">
              Your email address has been successfully verified.
            </p>
            <div className="mt-6">
              <ButtonRound
                text="Sign in to start watching"
                onClick={onClick}
                className="space-x-2 self-start"
                size="rnd"
              />
            </div>
          </div>
        </Card>
        <Footer />
      </section>
    </>
  );
}
