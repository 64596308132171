import React from "react";
import { PropTypes } from "prop-types";
import { useSwipeable } from "react-swipeable";
import { NUM_SLIDES } from "@/utils/constants";
import { Icon } from "@mcvod-apps/icons";
import Thumbnail from "@/components/Thumbnail";
import { LiveStreamButton } from "@/components/LiveStreamButton";
import ThumbnailWatched from "@/components/ThumbnailWatched";

export const CarouselItem = ({
  children,
  width,
  index,
  data,
  growOnHover,
  slideNumber,
}) => (
  <div
    data-index={index}
    className={`carousel-item inline-flex min-h-[1px] items-center justify-center text-white ${
      growOnHover === true ? "transition duration-300 hover:scale-105" : " "
    } p-2`}
    style={{ width: `calc(100% / ${slideNumber})` }}
  >
    {children}
  </div>
);

export const Carousel = ({
  data,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  children,
  slideNumber,
  growOnHover,
  pauseOnHover,
  count,
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [paused, setPaused] = React.useState(false);
  const [width, setWidth] = React.useState(0);
  const carousel = React.useRef();

  // Calculate numItems based on the data prop
  const numItems = data?.pages
    ? data.pages.flatMap((page) => page.results).length
    : data?.results?.length || 0;

  const activeItems = numItems / slideNumber;
  const { scrollWidth } = carousel;
  const { offsetWidth } = carousel;
  const percentage = scrollWidth / offsetWidth;
  // const scrollAmount = carousel.current.offsetWidth / carousel.current.scrollWidth;

  const updateIndex = (i) => {
    let newIndex = i;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= numItems / slideNumber) {
      newIndex = numItems / slideNumber - 1;
      // newIndex = percentage;
    }

    setActiveIndex(newIndex);
  };

  React.useEffect(() => {
    if (data?.pages) {
      // Check if the user swiped to the last item and there's a next page available
      if (
        activeIndex === data.pages.flatMap((page) => page.results).length - 1 &&
        hasNextPage &&
        !isFetchingNextPage
      ) {
        fetchNextPage();
      }
    }
  }, [activeIndex, data, fetchNextPage, hasNextPage, isFetchingNextPage]);

  React.useEffect(() => {
    setWidth(carousel.current.offsetWidth);
  });

  // TODO: Get swipable handlers working properly
  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className="carousel relative mx-[3vw] overflow-hidden"
      ref={carousel}
    >
      <div
        className="inner-carousel whitespace-nowrap transition-transform duration-300"
        // style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        style={{ transform: `translateX(-${width * activeIndex}px)` }}
      >
        {data
          ? data.pages
            ? data?.pages.flatMap((page, i) => (
                <React.Fragment key={i}>
                  {page.results.map((item, i) => (
                    <CarouselItem
                      key={item.pk}
                      index={i}
                      growOnHover={growOnHover}
                      slideNumber={slideNumber}
                    >
                      {item.content_model === "playactivity" ? (
                        <ThumbnailWatched
                          image={
                            item.video.content_model === "episode"
                              ? item.video.season.images.feature
                              : item.video.images.feature
                          }
                          progress={item.play_end_point_percentage || 0}
                          {...item.video}
                          key={item.pk}
                          className={`${
                            item.video.content_model === "episode"
                              ? "max-w-"
                              : ""
                          }`}
                        />
                      ) : (
                        <Thumbnail
                          {...item.content_object}
                          image={item.content_object.images.thumbnail}
                        />
                      )}
                    </CarouselItem>
                  ))}
                </React.Fragment>
              ))
            : data?.results.map((item, i) => (
                <CarouselItem
                  key={item.pk}
                  index={i}
                  growOnHover={growOnHover}
                  slideNumber={NUM_SLIDES}
                >
                  {item.content_model === "livestream" ? (
                    <LiveStreamButton {...item} slideNumber={NUM_SLIDES}>
                      {item.title}
                    </LiveStreamButton>
                  ) : (
                    <Thumbnail
                      {...item.content_object}
                      image={item.content_object.images.thumbnail}
                    />
                  )}
                </CarouselItem>
              ))
          : null}
      </div>

      {numItems > slideNumber ? (
        <>
          <button
            type="button"
            className={`
            ${activeIndex === 0 ? "hidden" : "inline"}
            absolute bottom-0 left-0 top-0 z-10 m-auto flex h-8 w-8 items-center justify-center rounded-full bg-white/70 outline outline-1 outline-white transition-transform duration-100 ease-out hover:scale-105`}
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <Icon
              name="chevronLeft"
              size={28}
              className="-ml-0.5 text-indigo-700"
            />
          </button>
          <button
            type="button"
            className={`${
              activeIndex === numItems / slideNumber - 1 ? "hidden" : "inline"
            } absolute bottom-0 right-0 top-0 z-10 m-auto flex h-8 w-8 items-center justify-center rounded-full bg-white/70 outline outline-1 outline-white transition-transform duration-100 ease-out hover:scale-105`}
            onClick={() => {
              updateIndex(activeIndex + 1);
              if (activeIndex + 1 > numItems / slideNumber - 1) {
                updateIndex(numItems / slideNumber - 1);
              }
            }}
          >
            <Icon
              name="chevronRight"
              size={28}
              className="ml-0.5 text-indigo-700"
            />
          </button>
        </>
      ) : null}
    </div>
  );
};

CarouselItem.propTypes = {
  children: PropTypes.object,
  width: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.array,
  growOnHover: PropTypes.bool,
  slideNumber: PropTypes.number,
};

Carousel.propTypes = {
  children: PropTypes.any,
  slideNumber: PropTypes.number,
  growOnHover: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  count: PropTypes.number,
};
