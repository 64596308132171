import { useState } from "react";
import {
  BottomBanner,
  BottomFloatingBanner,
} from "@/components/notifications/BannerNotification";
import { LoaderFunctionArgs, Outlet } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import { Topbar } from "../Topbar";
import Header from "../Header";
import { useInterval } from "@/hooks/useInterval";
import { POLLING_INTERVAL } from "@/utils/constants";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useFeature } from "flagged";
import { useUserStore } from "@/store/useUserStore";
import { QueryClient } from "@tanstack/react-query";
import { requireAuth } from "@/utils/requireAuth";
import { maintenanceMiddleware } from "@/utils/maintenance-check";

export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    // Check for maintenance mode first
    const maintenanceCheck = maintenanceMiddleware(request);
    if (maintenanceCheck) return maintenanceCheck;

    // Continue with auth check
    await requireAuth(request);
    return null;
  };

export default function MasterLayout() {
  const isMobile = useMediaQuery("only screen and (max-width : 768px)");
  const [mobileOpen, setMobileOpen] = useState(true);
  const topBarFlag = useFeature("topBarFlag");
  const checkUsersUpdate = useUserStore((state) => state.checkUserUpdate);
  const userState = useUserStore((state) => state.state);
  //Polling for new device, location and tier change from userStore
  useInterval(checkUsersUpdate, POLLING_INTERVAL);

  const toggleMobileMenu = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="flex h-screen flex-col ">
      <div className="flex flex-1 overflow-y-scroll">
        {topBarFlag ? (
          <Topbar isMobile={isMobile} />
        ) : (
          <Sidebar isMobile={isMobile} />
        )}
        <main className="relative flex-1 overflow-y-scroll">
          <Header />
          <div className={`Content ${userState === "110" ? "pb-32" : ""}`}>
            <Outlet />
          </div>
          <BottomBanner />
        </main>
      </div>
    </div>
  );
}
