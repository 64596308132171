import React from "react";
import { useUserStore, useUserSelectors } from "@/store/useUserStore";
import { APP_NAME, MC_CAMPAIGN_URL } from "@/utils/constants";

import { useWindowSize } from "@uidotdev/usehooks";
import { Icon } from "@mcvod-apps/icons";

export const StatusBanner = ({ active }) => (
  <>
    {active ? (
      <div className="overflow-hidden bg-gradient-to-r from-indigo-600 to-blue-500 py-2">
        <div className="relative mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-center">
            <div className="flex items-center space-x-2 text-sm font-medium leading-tight text-white">
              <div>
                <Icon name="info" />{" "}
              </div>
              <div>
                {" "}
                Some of our users are having trouble signing in. We are working
                to fix this issue.{" "}
                <span className="font-bold">Thank you for your patience.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : null}
  </>
);

const HeaderBanner = ({ active, onHideBanner }) => {
  if (active) {
    return (
      <div className="bg-indigo-600">
        <div className="mx-auto max-w-7xl px-3 py-2 sm:px-6 lg:px-8">
          <div className="flex flex-wrap items-center justify-between">
            {/* w-0 flex-1 flex items-cente */}
            <div className="flex flex-1 items-center ">
              <span className="flex rounded-full bg-indigo-800 p-2">
                {/* Icon name: speakerphone */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              {/* TODO: [COR-7] Bootstrap Global adds margin to the <p> tag.   */}
              <p className="mb-0 ml-3 truncate font-medium text-white">
                {/* For Small Screens */}
                <span className="md:hidden">We announced a new feature!</span>
                {/* For Large Screens */}
                <span className="hidden md:inline">
                  Big news! We're excited to announce a brand new feature.
                </span>
              </p>
            </div>
            <div className=" w-1/2 shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="https://miraclechannel.kindful.com/?campaign=1118917"
                target="_blank"
                className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                rel="noreferrer"
              >
                Learn more
              </a>
            </div>
            <div className="shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                onClick={onHideBanner}
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <span className="sr-only">Dismiss</span>
                {/* Icon name: x  */}
                <svg
                  className="h-6 w-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

// SIDEBAR BANNER
const SideBarBanner = () => {
  const { state } = useUserStore((state) => state);
  const isLoggedIn = useUserSelectors.useIsLoggedIn();

  // Show same banner as "Free side" on Public
  if (state === "110" || isLoggedIn === false) {
    return (
      <div className="hidden overflow-hidden rounded-lg border  border-gray-700 border-opacity-25 bg-gray-950 p-3 text-gray-100 md:block ">
        <div className="mb-4">
          <div className="text-md mb-1 font-semibold">Did you know!</div>
          <p className="text-xs leading-relaxed text-gray-400">
            {APP_NAME} is made possible by donors who want to support
            entertainment made by Christians.
          </p>
        </div>
        <div className="flex">
          <div className="shadow-sm">
            <a
              href={MC_CAMPAIGN_URL}
              target="_blank"
              className="focus:shadow-outline flex cursor-pointer items-center justify-center rounded border border-transparent bg-indigo-700 px-4 py-2 text-sm font-medium leading-5 text-gray-100 transition duration-150 ease-in-out hover:text-indigo-200 focus:outline-none"
              rel="noreferrer"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    );
  }
  return null;
};
//BOTTOM BANNER
const BottomFloatingBanner = ({ state }) => {
  const { state: userState } = useUserStore((state) => state);

  if (userState === "110") {
    return (
      <div
        className="fixed inset-x-0 bottom-24 z-10 hidden max-w-screen-md md:bottom-6 md:right-0 md:mx-auto md:block"
        style={{ width: "calc(100% - 12rem)", left: "12rem" }}
      >
        <div className="rounded-xl border  border-indigo-200 border-opacity-25 bg-gradient-to-tr from-indigo-900 via-indigo-800 to-indigo-700 p-6 shadow-lg">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-full flex-1 items-center sm:w-0">
              <p className="truncate text-gray-200">
                <strong className="font-medium text-white md:hidden">
                  Donate to Miracle Channel,
                </strong>
                <strong className="font-sm hidden text-white md:inline">
                  Donate to Miracle Channel,
                </strong>
                <span className="lg:hidden"> and get unlimited access</span>
                <span className="hidden md:inline lg:hidden">
                  {" "}
                  to thousands of titles
                </span>
                <span className="hidden lg:inline">
                  {" "}
                  and get unlimited access to thousands of titles
                </span>
              </p>
            </div>
            <div className="shrink-0">
              <div className="shadow-sm">
                <a
                  href="https://miraclechannel.kindful.com/?campaign=1118917"
                  target="_blank"
                  className="focus:shadow-outline flex cursor-pointer items-center justify-center rounded border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium leading-5 text-gray-900 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none"
                  rel="noreferrer"
                >
                  Get Access
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const BottomBanner = () => {
  const { state: userState } = useUserStore((state) => state);

  if (userState === "110") {
    return (
      <footer className="max-w-screen fixed inset-x-0 bottom-0 z-50">
        <div className="border border-indigo-200 border-opacity-25  bg-gradient-to-tr from-indigo-900 via-indigo-800  to-indigo-700 px-12 py-4 shadow-lg">
          <article className="flex flex-wrap items-center justify-between">
            <div className="flex w-full flex-1 items-center sm:w-0">
              <p className="truncate text-gray-200">
                <strong className="font-medium text-white md:hidden">
                  Donate to Miracle Channel,
                </strong>
                <strong className="font-sm hidden text-white md:inline">
                  Donate to Miracle Channel,
                </strong>
                <span className="lg:hidden"> and get unlimited access</span>
                <span className="hidden md:inline lg:hidden">
                  {" "}
                  to thousands of titles
                </span>
                <span className="hidden lg:inline">
                  {" "}
                  and get unlimited access to thousands of titles
                </span>
              </p>
            </div>
            <div className="shrink-0">
              <div className="shadow-sm">
                <a
                  href="https://miraclechannel.kindful.com/?campaign=1118917"
                  target="_blank"
                  className="focus:shadow-outline flex cursor-pointer items-center justify-center rounded border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium leading-5 text-gray-900 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none"
                  rel="noreferrer"
                >
                  Get Access
                </a>
              </div>
            </div>
          </article>
        </div>
      </footer>
    );
  }
  return null;
};

const DevBanner = (props) => {
  const size = useWindowSize();
  const [hidden, setHidden] = React.useState(true);
  const { id, email, state, country, tier, logout, refreshToken } =
    useUserStore((state) => state);
  const isLoggedIn = useUserSelectors.useIsLoggedIn();
  // Show dev banner only in development environment.
  if (!props.env) {
    return null;
  }

  return (
    <React.Fragment>
      <div
        className={
          !hidden
            ? "word-wrap fixed bottom-12 right-2 top-12 z-[9999] rounded-lg bg-gray-900 p-12 text-white  outline  outline-gray-400"
            : "hidden"
        }
      >
        <div className="mb-6">
          <p>
            Environment:{" "}
            <strong>
              {import.meta.env.DEV ? "Development" : "Production"}
            </strong>{" "}
          </p>
          <p>
            API Endpoint: <strong>{import.meta.env.VITE_APP_API_URL}</strong>
          </p>
          <p>
            Logged In? <strong>{isLoggedIn ? "Yes" : "No"}</strong>
          </p>
          <p>
            User: <strong>{email}</strong>
          </p>
          <p>
            UserID:<strong>{id}</strong>
          </p>
          <p>
            UserState:<strong>{state}</strong>
          </p>
          <p>
            Tier: <strong>{tier}</strong>
          </p>
          <p>
            Country: <strong>{country}</strong>
          </p>
        </div>
        <div className="mb-10 w-full text-white outline-dotted outline-pink-600 sm:outline-yellow-600 md:outline-blue-600 lg:outline-orange-600 xl:outline-green-600 2xl:outline-red-600">
          <p className="text-center">
            Window Size: <strong>{size.width}</strong>
          </p>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            className="bg-brand-700 rounded px-3 py-2 text-white"
            type="button"
            onClick={refreshToken}
          >
            Get Refresh Token
          </button>
          <button
            className="rounded bg-gray-800 px-3 py-2 text-white"
            type="button"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
      <div
        className={
          !hidden
            ? "fixed bottom-2 right-2 z-50"
            : "fixed bottom-2 right-2 z-50"
        }
      >
        <button
          className="rounded bg-black p-2 text-white"
          type="button"
          onClick={() => {
            hidden ? setHidden(false) : setHidden(true);
          }}
        >
          {hidden ? (
            <Icon name="arrowLineRight" size={24} />
          ) : (
            <Icon name="arrowLineLeft" size={24} />
          )}
        </button>
      </div>
    </React.Fragment>
  );
};

export {
  HeaderBanner,
  DevBanner,
  SideBarBanner,
  BottomFloatingBanner,
  BottomBanner,
};
