import FilterDropdown from "@/components/common/FilterDropdown";

type ContentGridProps = {
  children: any;
  pageTitle?: string;
  pageType:
    | "Kids"
    | "Movies"
    | "Shows"
    | "Topics"
    | "Search"
    | "Watchlist"
    | "Collection";
  pageDesc?: string;
};

export function ContentGrid(props: ContentGridProps) {
  const { children, pageTitle, pageType, pageDesc } = props;

  const allowedPageTypes = ["Kids", "Movies", "Shows", "Topics"];

  return (
    <div className="-mt-20 flex w-full  flex-col items-center overflow-y-auto px-[3vw] pt-20">
      <div className="mb-6 w-[90%] max-w-[800px] self-start md:w-[35%] md:min-w-[500px]">
        <div className="top-0 mb-4 flex tracking-tight text-gray-200">
          {!pageTitle ? (
            <h1 className="pl-1.5 text-4xl">{pageType}</h1>
          ) : (
            <h1 className="pl-1.5 text-4xl">{pageTitle}</h1>
          )}
          {allowedPageTypes.includes(pageType) ? (
            <FilterDropdown className="px-5" />
          ) : null}
        </div>
        <div className="text-base font-normal text-gray-400">
          <p>{pageDesc}</p>
        </div>
      </div>
      <div className="flex w-full flex-wrap self-start overflow-y-auto p-1.5">
        {children}
      </div>
    </div>
  );
}
