import parseUrl from "url-parse";
import { useNavigate } from "react-router-dom";
import { IMG_URI } from "@/utils/constants";

export default function ThumbnailTopic({
  content_model: contentModel,
  title,
  image,
  overlay_text: overlayText,
  progress,
  onClick,
  style,
  count,
  _detail,
  className,
  children,
  ...props
}) {
  const navigate = useNavigate();
  const { pathname } = parseUrl(_detail || "");
  const path = pathname.replace("/v2/content", "");

  function handleThumbnailClick() {
    navigate(path);
  }

  return (
    <div className="flex w-full flex-col">
      <div
        className={`group relative cursor-pointer overflow-hidden rounded ${className}`}
      >
        {image ? (
          <>
            <div
              tabIndex={0}
              role="button"
              onKeyDown={handleThumbnailClick}
              onClick={handleThumbnailClick}
              className="relative overflow-hidden rounded after:absolute after:inset-0 after:transition after:duration-100 after:ease-in after:hover:bg-gray-800/30"
            >
              <img
                src={image}
                alt={title}
                className="h-full w-full"
                onError={(e) => (e.target.src = IMG_URI)}
              />
              {children}
            </div>
            <div className="absolute inset-x-0 bottom-0 flex items-center justify-center bg-indigo-700/90 py-1 text-xs uppercase tracking-wide text-gray-100">
              {count} Videos
            </div>
          </>
        ) : (
          overlayText && (
            <div className="absolute inset-x-0 bottom-0 flex h-1/6 items-center justify-center bg-indigo-700 uppercase text-gray-100">
              {count} Videos
            </div>
          )
        )}
      </div>
    </div>
  );
}
