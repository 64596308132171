import { useInfiniteQuery } from "@tanstack/react-query";
import parseUrl from "url-parse";
import { PAGE_SIZE_ROW, STALE_TIME } from "@/utils/constants";
import { privateFetch } from "@/utils/fetch";

function getCollectionRowByPk({ pageParam = 1, pk }) {
  const ordering = "position";
  const pageSize = PAGE_SIZE_ROW;
  return privateFetch
    .get(
      `/content/collections/${pk}/collectionitems/?page=${pageParam}&ordering=${ordering}&page_size=${pageSize}`,
    )
    .then((res) => res.data);
}

export function useCollectionRowByPk(pk) {
  return useInfiniteQuery({
    queryKey: ["collection-row", pk],
    queryFn: async ({ pageParams }) =>
      await getCollectionRowByPk({ pageParams, pk }),
    getNextPageParam: (lastPage, page) => {
      const { query } = parseUrl(lastPage._next, true);
      return query.page;
    },
    keepPreviousData: true,
    staleTime: STALE_TIME,
  });
}
