import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
// Query Hooks
import {
  useCollectionByPk,
  useCollectionByPkItems,
} from "@/api/useCollectionByPk";
// Icons
import { Spinner } from "@/components/common";
// util
import { APP_NAME, STALE_TIME, PAGE_SIZE } from "@/utils/constants";
import { ContentGrid } from "@/features/content/components/ContentGrid";
import Thumbnail from "@/components/Thumbnail";
import { useInfiniteQuery } from "@tanstack/react-query";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";
import parseUrl from "url-parse";

function getCollectionByPk({ pageParam = 1, pk }) {
  const ordering = "position";
  const pageSize = PAGE_SIZE;
  const isKids = false;

  return privateFetch
    .get(`/content/collections/${pk}/?page=${pageParam}`)
    .then((res) => res.data);
}

const collectionDetailQuery = (pk) => ({
  queryKey: ["collectionDetail", pk],
  queryFn: async ({ pageParam = 1 }) => getCollectionByPk({ pageParam, pk }),
  getNextPageParam: (lastPage, page) => {
    const { query } = parseUrl(lastPage._next, true);
    return query.page;
  },
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient) =>
  async ({ request, params }) => {
    const { pk } = useParams();
    await requireAuth(request);
    document.title = `${data && data.title} Collection | ${APP_NAME}`;

    const collectionByPkQuery = collectionDetailQuery(params.collectionId);

    setTitle(collectionByPkQuery.data.title);
    setVideoSrc(collectionByPkQuery.data._play);
    setIsLive(collectionByPkQuery.data.content_model === "livestream");

    return (
      queryClient.getQueryData(collectionByPkQuery.queryKey) ??
      (await queryClient.fetchInfiniteQuery(collectionByPkQuery))
    );
  };

export default function CollectionDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const {
    data,
    isIdle,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isError,
    error,
  } = useInfiniteQuery(collectionDetailQuery(params.collectionId));

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  if (isLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (isError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {error.message}</p>
      </div>
    );

  return (
    <>
      <ContentGrid
        pageType="Collection"
        pageTitle={data.pages[0].title}
        pageDesc={data.pages[0].description}
      >
        {isIdle ? (
          <Spinner size={8} />
        ) : (
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
            {data?.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.collection_items?.results.map((item) => (
                  <Thumbnail
                    key={item.pk}
                    {...item.content_object}
                    image={item.content_object.images.thumbnail}
                    growOnHover
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        )}
      </ContentGrid>
      <div className="flex items-center justify-center py-10 text-gray-500">
        <button
          ref={loadMoreButtonRef}
          type="button"
          onKeyDown={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          {isFetchingNextPage ? (
            <div className="flex w-full items-center justify-center">
              <Spinner size={8} />
            </div>
          ) : hasNextPage ? (
            "Load more"
          ) : (
            ""
          )}
        </button>
      </div>
    </>
  );
}
