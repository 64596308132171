import React, { useState, useEffect } from "react";
import { Navigate, useMatch, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { publicFetch } from "@/utils/fetch";
import FormError from "@/components/FormError";
import FormSuccess from "@/components/FormSuccess";
import { Navlink } from "@/components/common/Hyperlink";
import Label from "@/components/common/Label";
import { SecureTextInput } from "@/components/TextInput";
import { ButtonRound } from "@/components/common/Button";
import Card from "@/components/common/Card";
import { Icon } from "@mcvod-apps/icons";
import qs from "qs";
import { APP_NAME } from "@/utils/constants";
import logo from "@/images/logo.svg";
import { hasAuth } from "@/utils/requireAuth";

export async function loader() {
  await hasAuth();
  document.title = `Confirm Reset Password | ${APP_NAME}`;
  return null;
}

const ResetConfirmSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required")
    .matches(/(?!^\d+$)^.+$/, "Password cannot be entirely numeric"),
});

const ResetConfirm = () => {
  const { uid, token } = useParams();
  const [submitSuccess, setSubmitSuccess] = useState();
  const [submitError, setSubmitError] = useState();
  const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // Submit Credentials
  const submitCredentials = async (credentials) => {
    try {
      setSubmitLoading(true);

      const { data } = await publicFetch.post(
        "users/password/reset_confirm/",
        qs.stringify(credentials),
        {
          // Override header content type fo
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        },
      );

      setSubmitSuccess(data.message);
      setSubmitError("");
      setTimeout(() => {
        setRedirectOnSubmit(true);
      }, 1000);
    } catch (error) {
      setSubmitLoading(false);
      // const { data } = error.response;
      setSubmitError(error.message);
      setSubmitSuccess(null);
    }
  };

  const [revealDetail, setRevealDetail] = React.useState(false);
  const { t, i18n } = useTranslation("translation");
  return (
    <>
      {redirectOnSubmit && <Navigate to="/reset-password-success" />}
      <section className="overlay-gradient relative flex h-screen w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat p-8 sm:pt-10">
        <Card>
          <div className="text-gray-400">
            <div className="m-auto mb-10 w-32">
              <img src={logo} alt="Logo" />
            </div>
            <h2 className="mb-2 text-center text-2xl font-bold leading-9 text-gray-100">
              New password
            </h2>
            <p className="text-center">
              Create your new memorable password and you’ll be all set.
            </p>
            <p className="mt-2 text-center text-sm">
              {t("auth.reset-password.request.account.message")}{" "}
              <Navlink
                to="/login"
                text={t("auth.reset-password.request.account.link")}
                className="text-indigo-400"
              />
            </p>
          </div>
          <Formik
            initialValues={{
              uid: uid,
              token: token,
              password: "",
            }}
            onSubmit={(values) => submitCredentials(values)}
            validationSchema={ResetConfirmSchema}
          >
            {({ isSubmitting, isValid, dirty }) => (
              <Form className="mt-8">
                {submitSuccess && <FormSuccess text={submitSuccess} />}
                {submitError && <FormError text={submitError} />}

                <div className="mb-2">
                  <div className="mb-1 flex items-center">
                    <Label text="Create your new password" />
                    <div
                      className="ml-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full text-xs font-bold text-gray-500"
                      onClick={() => setRevealDetail(!revealDetail)}
                    >
                      <Icon
                        name="info"
                        size={12}
                        title="Password Requirements"
                      />{" "}
                    </div>
                  </div>
                  <SecureTextInput
                    ariaLabel="Password"
                    name="password"
                    autocomplete="new-password"
                    placeholder="Your new memorable password"
                    details={revealDetail}
                  />
                </div>

                <div className="mt-6">
                  <ButtonRound
                    type="submit"
                    text={t("forms.button.new-password")}
                    loading={submitLoading}
                    className="w-full"
                    disabled={isSubmitting || !dirty || !isValid}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
    </>
  );
};

export default ResetConfirm;
