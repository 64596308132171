import {
  Button,
  Description,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { Spinner } from "@mcvod-apps/ui-components";
import { useTranslation } from "react-i18next";

//TODO: move to shared
export function DeactivateAccountModal({
  toggle,
  modalState,
  destructiveAction,
  cancelAction,
  isLoading,
}) {
  const { t } = useTranslation("translation");

  return (
    <Dialog
      as="div"
      open={modalState}
      className="relative z-50 focus:outline-none"
      onClose={() => toggle(false)}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/50 backdrop-blur-sm duration-600 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 z-50 flex w-screen overflow-y-auto">
        <div className="flex min-h-full min-w-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="w-full max-w-lg rounded-xl border border-white/10 bg-white/5 p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:transform-[scale(95%)] data-[closed]:opacity-0"
          >
            <DialogTitle as="h3" className="text-2xl/8 font-bold text-white">
              Confirm Account Deletion
            </DialogTitle>
            <Description>
              This will permanently deactivate your account
            </Description>
            <p className="mt-2 text-base/7 text-white/50">
              Are you sure you want to deactivate your account? All of your data
              will be permanently removed.
            </p>

            <div className="mt-4 flex gap-4">
              <Button
                autoFocus
                type="button"
                className="bg-card-foreground/10 hover:bg-card-foreground/50 w-full items-center gap-2 rounded-full px-4 py-2 text-center text-sm/6 font-semibold text-white data-[focus]:outline-1 data-[focus]:outline-white data-[hover]:outline-1 data-[hover]:outline-white"
                onClick={cancelAction}
              >
                Go back
              </Button>
              <Button
                type="button"
                disabled={isLoading}
                className="bg-destructive hover:bg-destructive/80 w-full items-center gap-2 rounded-full px-4 py-2 text-center text-sm/6 font-semibold text-white data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50 data-[focus]:outline-1 data-[focus]:outline-white data-[hover]:outline-1"
                onClick={destructiveAction}
              >
                {isLoading ? (
                  <Spinner
                    color="fill-white"
                    className="flex items-center justify-center"
                    size={6}
                  />
                ) : (
                  "Delete account"
                )}
              </Button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}