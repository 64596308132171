import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { ButtonRound } from "@/components/common/Button";
import { useUrlParser } from "@/hooks/useUrlParser";
// Custom Hooks
import {
  useSaveWatchlist,
  useDeleteWatchlist,
} from "@/api/mutations/watchlist";

// Icons
import { Spinner } from "@/components/common";
import Progress from "@/components/Progress";
import { APP_NAME, STALE_TIME } from "@/utils/constants";
import DetailBlock from "@/components/DetailBlock";
import { Icon } from "@mcvod-apps/icons";
import { requireAuth } from "@/utils/requireAuth";
import { privateFetch } from "@/utils/fetch";

function getMovieByPk(pk) {
  return privateFetch.get(`/content/movies/${pk}/`).then((res) => res.data);
}

//Define query
const movieDetailQuery = (pk) => ({
  queryKey: ["movie_detail", pk],
  queryFn: async () => getMovieByPk(pk),
  keepPreviousData: true,
  staleTime: STALE_TIME,
});

export const loader =
  (queryClient) =>
  async ({ request, params }) => {
    const { pk } = useParams();
    await requireAuth(request);
    document.title = `Watch ${movieDetailData?.title} | ${APP_NAME}`;

    const movieByPkQuery = movieDetailQuery(pk);

    return (
      queryClient.getQueryData(movieByPkQuery.queryKey) ??
      (await queryClient.fetchQuery(movieByPkQuery))
    );
  };

export default function MovieDetail() {
  const navigate = useNavigate();
  const { pk } = useParams();
  const {
    data: movieDetailData,
    isLoading: movieDetailLoading,
    isError: movieDetailIsError,
    error: movieDetailError,
  } = useQuery(movieDetailQuery(pk));

  let watchlistAddRemoveUrl = "";
  if (movieDetailData?.activity.save_activity !== null) {
    watchlistAddRemoveUrl = useUrlParser(
      movieDetailData?.activity.save_activity._detail,
    );
  } else watchlistAddRemoveUrl = useUrlParser(movieDetailData?._save);

  const movieDetailQueryKey = ["movie_detail", movieDetailData?.pk];
  const saveWatchlistMutation = useSaveWatchlist(movieDetailQueryKey);
  const deleteWatchlistMutation = useDeleteWatchlist(movieDetailQueryKey);

  function clickWatchlistButton() {
    if (movieDetailData.activity.save_activity === null) {
      saveWatchlistMutation.mutate(watchlistAddRemoveUrl);
    } else {
      deleteWatchlistMutation.mutate(watchlistAddRemoveUrl);
    }
  }

  function handleMoviePlayClick() {
    navigate(
      `/watch/${movieDetailData?.pk}?p_l=${movieDetailData?._play}&title=${movieDetailData?.title}&type=${movieDetailData?.content_model}`,
    );
  }

  if (movieDetailLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center ">
        <Spinner size={8} />
      </div>
    );
  if (movieDetailIsError)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500 ">
        <p>Error: {movieDetailError.message}</p>
      </div>
    );

  return (
    <>
      <div className="-mt-20 h-full overflow-y-scroll px-[3vw] pt-20 ">
        <div className="flex w-full flex-col">
          <DetailBlock
            data={movieDetailData}
            playUrl={movieDetailData?._play}
            page="movie"
          />
          <div className="flex space-x-4 py-1">
            <div>
              <ButtonRound
                onClick={handleMoviePlayClick}
                className="space-x-2 self-start px-4 py-4 text-xl"
                size="rnd"
                varient="primary"
                IconStart={<Icon name="play" size={24} weight="fill" />}
                text="Play"
              />
              <Progress
                progress={
                  movieDetailData.activity.play_activity
                    ?.play_end_point_percentage
                }
              />
            </div>
            {/* Progress bar Component here */}
            {/* TODO ADD MUTATION */}
            <ButtonRound
              onClick={() => clickWatchlistButton()}
              //   data.activity.save_activity === null
              //     ? saveWatchlistMutation.mutate(movie_pk)
              //     : deleteWatchlistMutation.mutate(movie_pk);
              // }}

              className="self-start rounded-full px-4 py-4 text-xl"
              size="rnd"
              varient="plain"
              IconStart={
                saveWatchlistMutation.isLoading ||
                deleteWatchlistMutation.isLoading ? (
                  <Spinner size={6} />
                ) : movieDetailData.activity.save_activity === null ? (
                  <Icon name="plus" size={24} weight="bold" />
                ) : (
                  <Icon name="check" size={24} weight="bold" />
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
