import React from "react";
import { privateFetch } from "@/utils/fetch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { requireAuth } from "@/utils/requireAuth";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Spinner } from "@/components/common";
import {
  VideoPlayer
} from "@mcvod-apps/ui-components";
import { usePlayBackLink } from "@/api/usePlaybackLink";
import {
  PlayBackLinkResponse,
  PlayBackActivityResponse,
} from "@/api/usePlaybackLink";

// Add loader for the route
export const loader =
  (queryClient: QueryClient) =>
  async ({ request }: { request: Request }) => {
    await requireAuth(request);
    // You might want to add additional data fetching here
    return null;
  };

//   src,
//   preload = "metadata",
//   className,
//   onError,
//   onReady,
//   videoId,
//   isLive = false,
//   onClose,
//   playBackLink,
//   playBackActivity,
//   title,
// }) => {
//   const videoRef = useRef<HlsVideoElement>(null);
//   const hlsRef = useRef<Hls | null>(null);
//   const progressIntervalRef = useRef<ReturnType<typeof setInterval> | null>(
//     null,
//   );
//   const navigate = useNavigate();

//   //API Calls
//   const sendPlayStarted = useCallback(
//     async (currentTime: number) => {
//       try {
//         await privateFetch({
//           method: "PUT",
//           url: playBackLink?._play_activity,
//           data: {
//             play_point_seconds: Math.floor(currentTime) || 0,
//           },
//         });
//       } catch (error) {
//         console.error("Error sending play started:", error);
//       }
//     },
//     [videoId, videoRef],
//   );

//   const sendSeekEvent = useCallback(
//     async (currentTime: number) => {
//       try {
//         await privateFetch({
//           method: "PUT",
//           url: playBackLink?._play_activity,
//           data: {
//             play_point_seconds: Math.floor(currentTime) || 0,
//           },
//         });
//       } catch (error) {
//         console.error("Error sending seek event:", error);
//       }
//     },
//     [videoId, videoRef],
//   );

//   const sendProgressUpdate = useCallback(
//     async (currentTime: number) => {
//       try {
//         await privateFetch({
//           method: "PATCH",
//           url: playBackLink?._play_activity,
//           data: {
//             currentTime,
//             play_point_seconds: Math.floor(currentTime),
//           },
//         });
//       } catch (error) {
//         console.error("Error sending progress update:", error);
//       }
//     },
//     [videoId, videoRef],
//   );

//   // Progress tracking interval
//   const startProgressTracking = useCallback(() => {
//     if (progressIntervalRef.current) {
//       clearInterval(progressIntervalRef.current);
//     }

//     progressIntervalRef.current = setInterval(() => {
//       if (videoRef.current?.paused === false) {
//         sendProgressUpdate(videoRef.current?.currentTime || 0);
//       }
//     }, 10000); // Every 10 seconds
//   }, [sendProgressUpdate]);

//   const stopProgressTracking = useCallback(() => {
//     if (progressIntervalRef.current) {
//       clearInterval(progressIntervalRef.current);
//       progressIntervalRef.current = null;
//     }
//   }, []);

//   // Event handlers
//   const handlePlay = useCallback(() => {
//     sendPlayStarted(videoRef.current?.currentTime || 0);
//     startProgressTracking();
//   }, [sendPlayStarted, startProgressTracking]);

//   const handlePause = useCallback(() => {
//     stopProgressTracking();
//   }, [stopProgressTracking]);

//   const handleSeeked = useCallback(() => {
//     if (videoRef.current) {
//       sendSeekEvent(videoRef.current.currentTime);
//     }
//   }, [sendSeekEvent]);

//   useEffect(() => {
//     if (!videoRef.current || !src) return;

//     const videoElement = videoRef.current;

//     const handleManifestParsed = (event: Event) => {
//       const hls = (videoElement as any).hls; // get hls instance from video element
//       const subtitleTracks = hls?.subtitleTracks || [];

//       videoElement.querySelectorAll("track").forEach((track) => track.remove());
//       subtitleTracks.forEach((track: any, index: number) => {
//         const trackElement = document.createElement("track");
//         trackElement.kind = "subtitles";
//         trackElement.label = track.name || `Subtitle ${index + 1}`;
//         trackElement.srclang = track.lang || "en";
//         trackElement.src = track.url;
//         trackElement.default = index === 3;
//         videoElement.appendChild(trackElement);
//       });
//     };

//     videoElement.addEventListener("hlsManifestParsed", handleManifestParsed);


//     videoElement.addEventListener("loadedmetadata", () => {
//       const textTracks = videoElement.textTracks;

//       for (let i = 0; i < textTracks.length; i++) {
//         const track = textTracks[i];
//         if (track.kind === "subtitles" || track.kind === "captions") {
//           track.mode = "showing"; // Enable track if required
//         }
//       }
//     });
//     videoElement.addEventListener("play", handlePlay);
//     videoElement.addEventListener("pause", handlePause);
//     videoElement.addEventListener("seeked", handleSeeked);
//     // Cleanup
//     return () => {
//       stopProgressTracking();
//       videoElement.removeEventListener("play", handlePlay);
//       videoElement.removeEventListener("pause", handlePause);
//       videoElement.removeEventListener("seeked", handleSeeked);
//       videoElement.removeEventListener("hlsManifestParsed", handleManifestParsed);
//     };
//   }, [onReady, handlePlay, handlePause, handleSeeked]);

//   const handleClose = () => {
//     if (onClose) {
//       onClose();
//     } else {
//       navigate(-1); // Go back if no onClose provided
//     }
//   };

//   return (
//     <div className="fixed inset-0 z-50 flex items-center justify-center bg-black">
//       <div className="relative aspect-video max-h-[90vh] w-full max-w-[90vw]">
//         <MediaController
//           className="aspect-video h-full w-full"
//           defaultsubtitles
//         >
//           <div
//             slot="top-chrome"
//             className="absolute inset-x-0 top-0 flex justify-between bg-gradient-to-b from-black/80 via-black/50 to-transparent py-10"
//           >
//             <h1 className="px-3  py-2 text-lg font-semibold text-white">
//               {title}
//             </h1>
//             <button
//               onClick={handleClose}
//               className=" rounded-full p-2 text-white duration-200 ease-in-out hover:scale-150"
//               button-name="close"
//               title="Close"
//             >
//               <Icon
//                 name="close"
//                 color="white"
//                 size={24}
//                 weight="bold"
//                 mirrored={false}
//               />
//             </button>
//           </div>
//           <hls-video
//             ref={videoRef}
//             slot="media"
//             className="h-full w-full object-contain"
//             crossOrigin="use-credentials"
//             muted
//             src={src}
//             autoPlay
//           />
//           <MediaLoadingIndicator slot="centered-chrome" noautohide />
//           <MediaCaptionsMenu hidden anchor="auto" />
//           <MediaRenditionMenu hidden anchor="auto" />
//           <MediaControlBar>
//             <MediaPlayButton />
//             {!isLive && (
//               <>
//                 <MediaSeekBackwardButton />
//                 <MediaSeekForwardButton />
//                 <MediaTimeRange />
//                 <MediaTimeDisplay showduration remaining />
//               </>
//             )}
//             {isLive && <MediaLiveButton />}
//             <MediaMuteButton />
//             <MediaVolumeRange />
//             <MediaPlaybackRateMenu
//               rates=".5 .75 1 1.25 1.5 2"
//               hidden
//               anchor="auto"
//             />
//             {!isLive && <MediaPlaybackRateMenuButton />}
//             <MediaRenditionMenuButton />
//             <MediaCaptionsButton />
//             <MediaFullscreenButton />
//           </MediaControlBar>
//         </MediaController>
//       </div>
//     </div>
//   );
// };

export const WatchPage: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { contentId } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const currentPlaybackItem = searchParams.get("p_l");
  const title = searchParams.get("title");
  const type = searchParams.get("type");

  const onClose = () => {
    queryClient.refetchQueries(["playactivity"]);
    navigate(-1);
  };

  const {
    playBackLink,
    playBackActivity,
    playBackIsLoading,
    playBackIsError,
    playBackError,
  } = usePlayBackLink({
    _play: currentPlaybackItem as string,
    contentId,
    enabled: true,
  });

  if (!currentPlaybackItem) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-red-500">No video source provided</p>
      </div>
    );
  }

  if (playBackIsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size={10} />
      </div>
    );
  }

  if (playBackIsError) {
    console.log(playBackError);
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-red-500">Error loading video</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen items-center justify-center">
      <VideoPlayer
        src={playBackLink?.link as string}
        title={title as string}
        isLive={type === "livestream" ? true : false}
        onClose={onClose}
        playBackLink={playBackLink as PlayBackLinkResponse}
        playBackActivity={playBackActivity as PlayBackActivityResponse}
        privateFetch={privateFetch}
      />
      ;
    </div>
  );
};

export default WatchPage;
