import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import QueryString from "qs";
import { useUserStore, storeTokens } from "@/store/useUserStore";
import { useMaintenanceStore } from "@/store/useMaintenanceStore";

/* Endpoints that don't require authentication */
export const publicFetch = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

export const privateFetch = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
});

export const searchFetch = axios.create({
  baseURL: import.meta.env.VITE_APP_SEARCH_URL,
});

// Function to handle maintenance mode detection
const handleMaintenanceMode = (error) => {
  if (error.response && error.response.status === 503) {
    useMaintenanceStore.getState().setMaintenanceMode(true);
  }
  return Promise.reject(error);
};

publicFetch.interceptors.request.use(
  (config) => {
    config.headers["x-client-id"] = import.meta.env.VITE_APP_CLIENT_ID;
    return config;
  },
  (error) => Promise.reject(error),
);

//Response interceptor to detect maintenance mode
publicFetch.interceptors.response.use(
  (response) => response,
  handleMaintenanceMode
);

privateFetch.interceptors.request.use(
  (config) => {
    const { accessToken } = useUserStore.getState();
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["x-client-id"] = import.meta.env.VITE_APP_CLIENT_ID;
    return config;
  },
  (error) => Promise.reject(error),
);

// Add response interceptor to detect maintenance mode
privateFetch.interceptors.response.use(
  (response) => response,
  handleMaintenanceMode
);

searchFetch.interceptors.request.use(
  (config) => {
    config.headers["x-api-key"] = import.meta.env.VITE_APP_SEARCH_KEY;
    config.headers["Version"] = "2";

    return config;
  },
  (error) => Promise.reject(error),
);

// Add response interceptor to detect maintenance mode
searchFetch.interceptors.response.use(
  (response) => response,
  handleMaintenanceMode
);

export const requestNewToken = async () => {
  const { refreshTokenString } = useUserStore.getState();
  let qsData = QueryString.stringify({
    grant_type: "refresh_token",
    refresh_token: refreshTokenString,
    client_id: import.meta.env.VITE_APP_CLIENT_ID,
  });
  let config = {
    method: "post",
    url: "auth/token/",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: qsData,
  };
  try {
    const { data } = await publicFetch.request(config);
    return data;
  } catch (error) {
    console.log("Error refreshing token", error);
    throw error;
  }
};

const getNewTokenForRequest = async (failedRequest) => {
  try {
    const data = await requestNewToken();

    failedRequest.response.config.headers.Authorization = `Bearer ${data.access_token}`;
    storeTokens(data.access_token, data.refresh_token);

    return Promise.resolve();
  } catch (error) {
    console.log("Failed to refresh token", error);
    //TODO handle refresh fail. clear stored tokens, redirect to login.
    return Promise.reject(error);
  }
};

createAuthRefreshInterceptor(privateFetch, getNewTokenForRequest, {
  pauseInstanceWhileRefreshing: false,
});
