import React from "react";
import PropTypes from "prop-types";
import parseUrl from "url-parse";
import { useNavigate, useMatch } from "react-router-dom";
import { Icon } from "@mcvod-apps/icons";
import Progress from "@/components/Progress";
import { IMG_URI } from "@/utils/constants";

// Placeholder component
const Placeholder = ({ children }) => (
  <div className="_PLACEHOLDER relative overflow-hidden rounded after:absolute after:inset-0 after:transition after:duration-100 after:ease-in after:hover:bg-gray-800/30">
    <img
      src={IMG_URI}
      alt="Image with Corco+ logo "
      className="h-full w-full"
    />
  </div>
);

// Thumbnail Types
// Movie, Show, Season, Episode, Topic, Livestream, Watchlist

export default function Thumbnail({
  content_model: contentModel,
  title,
  image,
  overlay_text: overlayText,
  progress,
  onClick,
  style,
  _detail,
  className,
  _play,
  children,
  growOnHover,
  ...props
}) {
  const navigate = useNavigate();
  const { pathname } = parseUrl(_detail || "");
  const path = pathname.replace("/v2/content", "");
  const searchRoute = useMatch("/search");

  function handleThumbnailClick() {
    // Do something if coming from /search
    if (searchRoute) {
      navigate(path);
    } else if (contentModel && contentModel === "episode") {
      navigate(
        `/watch/${props.pk}?p_l=${_play}&title=${title}&type=${contentModel}`,
      );
    } else if (contentModel && contentModel === "saveactivity") {
      const savePathname = parseUrl(props.content_object._detail);
      const savePath = savePathname.pathname.replace("/v2/content", "");
      navigate(savePath);
    } else {
      navigate(path);
    }
  }

  function handleInfoClick() {
    navigate(path);
  }

  return (
    <div
      className={`relative flex w-full flex-col  ${
        growOnHover ? "transition duration-300 hover:scale-105" : ""
      }`}
    >
      <div
        className={`group relative cursor-pointer overflow-hidden rounded ${
          className ? className : ""
        }`}
      >
        {/* {!image ? (
                    <Placeholder/>
                 ):( */}
        <>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={handleThumbnailClick}
            onClick={handleThumbnailClick}
            className="relative overflow-hidden rounded after:absolute after:inset-0 after:transition after:duration-100 after:ease-in after:hover:bg-gray-800/30"
          >
            <img
              src={!image ? IMG_URI : image}
              alt={title}
              className="h-full w-full"
              onError={(e) => (e.target.src = IMG_URI)}
            />
            {/* {children} */}
          </div>
          {contentModel && contentModel === "episode" ? (
            <div className="group absolute bottom-8 right-2 z-10 flex items-end justify-between">
              <button
                type="button"
                tabIndex={0}
                onKeyDown={handleInfoClick}
                onClick={handleInfoClick}
                title="Episode Details"
              >
                <div className="z-10 rounded-full bg-black/50 opacity-0  transition duration-200 ease-in hover:scale-105 hover:bg-black/70 group-hover:opacity-100 ">
                  <Icon
                    name="info"
                    alt="info"
                    size={24}
                    className="text-gray-300 opacity-0 transition duration-100 ease-in group-hover:opacity-100  "
                  />
                </div>
              </button>
            </div>
          ) : null}

          {overlayText && (
            <div className="absolute inset-x-0 bottom-0 flex items-center justify-center bg-indigo-700/90 py-1 text-xs uppercase tracking-wide text-gray-100">
              {overlayText}
            </div>
          )}
        </>
        {/* )} */}
        {/* : (
                    overlayText && (
                        <div className="flex absolute inset-x-0 bottom-0 justify-center items-center h-1/6 text-gray-100 uppercase bg-indigo-700">
                            {overlayText}
                        </div>
                    )
                )} */}
      </div>
      {children}
      {!!progress && <Progress progress={progress} />}
    </div>
  );
}

Thumbnail.propTypes = {
  _detail: PropTypes.string,
  _play: PropTypes.any,
  className: PropTypes.any,
  content_model: PropTypes.string,
  image: PropTypes.any,
  onClick: PropTypes.any,
  overlay_text: PropTypes.any,
  progress: PropTypes.any,
  style: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
  growOnHover: PropTypes.bool,
};
