import { useQuery } from "@tanstack/react-query";
import { publicFetch } from "@/utils/fetch";

export function useTrailers(searchParams) {
  function parseUrlQueryParams(paramKey) {
    return new URLSearchParams(searchParams).get(paramKey);
  }

  return useQuery(
    ["trailers"],
    ({ isKids = parseUrlQueryParams("kids") || "", ordering = "recent" }) =>
      publicFetch
        .get(`/content/trailers/?ordering=${ordering}&is_kids=${isKids}`)
        .then((res) => res.data),
    {
      enabled: searchParams !== null,
      refetchOnWindowFocus: true,
      staleTime: 3600000,
    },
  );
}
