import React from "react";
import { Link, useNavigate, useSubmit } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@mcvod-apps/icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useUserStore, useUserSelectors } from "@/store/useUserStore";
// Custom link component to navigate via react-routers LINk or via html
// <a> element based on whether an element is external link
const CustomLink = ({ isExternal, to, children, ...props }) =>
  isExternal ? (
    <a href={to} target="_blank" rel="noopener noreferrer" {...props}>
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );

const UserDropdown = () => {
  const { t } = useTranslation();
  const isLoggedIn = useUserSelectors.useIsLoggedIn();
  const { email } = useUserSelectors.userUserProfile();
  // Get pathname to highlight current location in dropdown.
  const pathname = window.location.pathname;
  const submit = useSubmit();

  const handleLogout = (e) => {
    e.preventDefault();
    console.log("Handling logout with useSubmit");
    submit(null, { method: "post", action: "/logout" });
  };

  // Dropdown Item
  const dropdownItems = [
    {
      id: 1,
      title: t("menu.settings.account"),
      icon: "settings",
      location: "/account",
      isExternal: false,
      isPrivate: true,
    },
    {
      id: 3,
      title: "Help Desk",
      icon: "info",
      location: "https://help.corco.com/",
      isExternal: true,
      isPublic: true,
      isPrivate: true,
    },
    {
      id: 4,
      title: t("menu.settings.signout"),
      icon: "signout",
      location: "/logout",
      isExternal: false,
      isPrivate: true,
      onClick: handleLogout,
    },
    {
      id: 5,
      title: t("menu.settings.signin"),
      icon: "signin",
      location: "/login",
      isExternal: false,
      isPublic: true,
      isPrivate: false,
    },
    {
      id: 6,
      title: "Donate",
      icon: "gift",
      location: "https://miraclechannel.kindful.com/?campaign=1118917",
      isExternal: true,
      isLast: true,
      isPublic: true,
      isPrivate: true,
    },
  ];

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button
          className="group mb-1 flex cursor-pointer items-center py-1 text-sm text-gray-300 hover:text-gray-100 focus:text-gray-100 focus:outline-none  "
          aria-label="user dropdown"
        >
          <div className="mr-2 rounded-full border border-gray-800 bg-gray-900 p-1 group-hover:bg-gray-800 group-focus:bg-gray-800">
            <Icon name="user" size={20} />
          </div>
          <div className="">
            <Icon name="chevronDown" size={20} />
          </div>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          className="absolute right-0 z-10 flex origin-top-right flex-col overflow-hidden rounded-lg bg-gray-900 shadow outline outline-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          {isLoggedIn ? (
            <div
              key={email}
              className="flex w-full flex-col p-3 text-xs text-gray-500"
            >
              Signed in as:
              <div className="text-xs">{email ? email : null}</div>
              <div className="text-[10px]">v{APP_VERSION}</div>
            </div>
          ) : null}
          {!isLoggedIn
            ? dropdownItems.map((item) =>
                item.isPublic ? (
                  <DropdownMenu.Item
                    className="focus:ring-1 text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focus:ring-black focus:ring-opacity-5"
                    key={item.id}
                  >
                    <CustomLink
                      isExternal={item.isExternal}
                      to={item.location}
                      onClick={item.onClick}
                      className={`
                                flex w-full items-center p-3 text-sm  focus:bg-gray-700 focus:text-gray-100 focus:outline-none
                                ${item.isLast && "bg-indigo-700 text-gray-100"}
                                ${
                                  pathname === item.location
                                    ? "text-gray-100"
                                    : ""
                                }
                                `}
                    >
                      <Icon name={item.icon} size={20} />
                      <p className="mb-0 ml-2">{item.title}</p>
                    </CustomLink>
                  </DropdownMenu.Item>
                ) : null,
              )
            : dropdownItems.map((item) =>
              item.isPrivate ? (
                <DropdownMenu.Item
                className="focus:ring-1 text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focus:ring-black focus:ring-opacity-5"
                key={item.id}
                >
                  {item.location === "/logout" ? (
                    <Link
                      to="/logout"
                      className={`
                        flex w-full items-center p-3 text-sm focus:bg-gray-700 focus:text-gray-100 focus:outline-none
                        ${item.isLast && "bg-indigo-700 text-gray-100"}
                        ${pathname === item.location ? "text-gray-100" : ""}
                      `}
                    >
                      <Icon name={item.icon} size={20} />
                      <p className="mb-0 ml-2">{item.title}</p>
                    </Link>
                  ) : (
                    <CustomLink
                      isExternal={item.isExternal}
                      to={item.location}
                      onClick={item.onClick}
                      className={`
                        flex w-full items-center p-3 text-sm focus:bg-gray-700 focus:text-gray-100 focus:outline-none
                        ${item.isLast && "bg-indigo-700 text-gray-100"}
                        ${pathname === item.location ? "text-gray-100" : ""}
                      `}
                    >
                      <Icon name={item.icon} size={20} />
                      <p className="mb-0 ml-2">{item.title}</p>
                    </CustomLink>
                  )}
                  </DropdownMenu.Item>
                ) : null,
              )}
          <DropdownMenu.Arrow className="fill-white" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default UserDropdown;
