import React from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "@mcvod-apps/icons";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useGenres } from "@/api/useGenres";

/*
 *
 * Default ordering for Kids = popular
 * Default ordering for Movies = recent
 * Default ordering for Shows = recent
 *
 */

const FilterDropdown = ({ className }) => {
  const { t } = useTranslation();
  const { data: genreAPIData, isFetching } = useGenres();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const { pathname } = useLocation();

  const pageType = pathname.slice(1).toLowerCase(); // Convert '/Shows' to 'shows', etc.

  const sortByItems = React.useMemo(
    () => [
      { id: 1, title: t("menu.sortBy.recentAdded"), key: "recent" },
      { id: 2, title: t("menu.sortBy.popular"), key: "popular" },
      { id: 3, title: t("menu.sortBy.featured"), key: "is_featured" },
      { id: 4, title: t("menu.sortBy.aToZ"), key: "title" },
      { id: 5, title: t("menu.sortBy.zToA"), key: "-title" },
    ],
    [t],
  );

  // Remove 'recent' for kids page
  const adjustedSortByItems =
    pageType === "kids"
      ? sortByItems.filter((item) => item.key !== "recent")
      : sortByItems;

  const filterByItems = React.useMemo(
    () => [
      { id: 1, title: "All", key: "all" },
      { id: 2, title: t("menu.filterBy.movies"), key: "movie" },
      { id: 3, title: t("menu.filterBy.shows"), key: "show" },
    ],
    [t],
  );

  const getInitialSortKey = () => {
    switch (pageType) {
      case "kids":
        return "popular";
      case "movies":
      case "shows":
        return "recent";
      default:
        return "recent";
    }
  };

  const [sorterChecked, setSorterChecked] = React.useState(() => {
    const currentOrdering =
      searchParams.get("ordering") ||
      (pageType === "kids" ? "popular" : "recent");
    return adjustedSortByItems.map((item) => ({
      ...item,
      checked:
        item.key === currentOrdering ||
        (item.key === "is_featured" &&
          searchParams.get("is_featured") === "true"),
    }));
  });

  const [availableGenres, setAvailableGenres] = React.useState([]);

  React.useEffect(() => {
    const setupFiltersAndGenres = async () => {
      const queryParams = new URLSearchParams(searchParams);

      // Handle initial sort setup
      if (!queryParams.has("ordering") && !queryParams.has("is_featured")) {
        const initialSort = getInitialSortKey();
        queryParams.set("ordering", initialSort);
        const sortItem = adjustedSortByItems.find(
          (item) => item.key === initialSort,
        );
        if (sortItem) {
          setTitle(sortItem.title);
        }
        // Update URL immediately to prevent additional render
        setSearchParams(queryParams, { replace: true });
      }

      // Set initial sorter checked state
      const currentOrdering =
        searchParams.get("ordering") ||
        (pageType === "kids" ? "popular" : "recent");
      setSorterChecked(
        adjustedSortByItems.map((item) => ({
          ...item,
          checked:
            item.key === currentOrdering ||
            (item.key === "is_featured" &&
              searchParams.get("is_featured") === "true"),
        })),
      );

      // Handle genres setup only if we have genre data
      if (genreAPIData) {
        let genres;
        if (pageType === "kids") {
          genres = filterByItems;
          const currentContentModel = searchParams.get("content_model");
          if (currentContentModel) {
            genres = genres.map((genre) => ({
              ...genre,
              checked: genre.key === currentContentModel,
            }));
          }
        } else {
          genres = genreAPIData.pages[0].data.results
            .filter((val) => val[pageType]?.count > 0)
            .map((val, index) => ({
              id: index,
              title: val.title,
              key: val.slug || val.title.toLowerCase().replace(/\s/g, "-"),
              checked: false,
              content_model: pageType,
            }));

          const currentGenre = searchParams.get("genre");
          if (currentGenre) {
            genres = genres.map((genre) => ({
              ...genre,
              checked: genre.key === currentGenre,
            }));
          }
        }
        setAvailableGenres(genres);
      }
    };

    setupFiltersAndGenres();
  }, [genreAPIData, pageType, searchParams]);

  const handleFilterOpenAndClose = () => {
    if (filterOpen) {
      const queryParams = new URLSearchParams(searchParams);
      const checkedSorter = sorterChecked.find((sort) => sort.checked);
      if (checkedSorter) {
        if (checkedSorter.key === "is_featured") {
          queryParams.set("is_featured", "true");
          queryParams.delete("ordering");
        } else {
          queryParams.set("ordering", checkedSorter.key);
          queryParams.delete("is_featured");
        }
        setTitle(checkedSorter.title);
      }

      const checkedGenres = availableGenres.filter((item) => item.checked);
      if (checkedGenres.length > 0) {
        if (pageType === "kids") {
          if (
            checkedGenres[0].key === "movie" ||
            checkedGenres[0].key === "show"
          ) {
            queryParams.set("content_model", checkedGenres[0].key);
          } else if (checkedGenres[0].key === "all") {
            queryParams.delete("content_model");
          }
        } else {
          queryParams.set("genre", checkedGenres[0].key);
        }
      } else {
        if (pageType === "kids") {
          queryParams.delete("content_model");
        } else {
          queryParams.delete("genre");
        }
      }

      if (pageType === "kids") {
        queryParams.set("is_kids", "true");
      } else {
        queryParams.delete("is_kids");
      }
      setSearchParams(queryParams, { replace: true });
    }
    setFilterOpen(!filterOpen);
  };

  const handleSorterClick = (sorterId) => {
    setSorterChecked((prev) =>
      prev.map((sorter) => ({
        ...sorter,
        checked: sorter.id === sorterId,
      })),
    );
  };

  const handleFilterClick = (filterId) => {
    setAvailableGenres((prev) =>
      prev.map((item) => ({
        ...item,
        checked: item.id === filterId ? !item.checked : false,
      })),
    );
  };

  return (
    <DropdownMenu.Root open={filterOpen}>
      <DropdownMenu.Trigger asChild>
        <button
          className={`group mb-1 flex cursor-pointer items-center py-1 text-sm text-gray-300 hover:text-gray-100 focus:text-gray-100 focus:outline-none ${className}`}
          onClick={handleFilterOpenAndClose}
          aria-label="Filter dropdown"
        >
          <div className="ml-5 rounded-full border border-gray-800 bg-gray-900 px-4 pb-1 group-hover:bg-gray-800 group-focus:bg-gray-800">
            <div className="flex items-center justify-center">
              <h5 className="mr-1 mt-1">{title || t("menu.sortBy.title")}</h5>
              <Icon name={filterOpen ? "chevronUp" : "chevronDown"} size={16} />
            </div>
          </div>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="center"
          onInteractOutside={handleFilterOpenAndClose}
          className="z-10 mt-1 flex w-64 origin-top-right flex-col overflow-hidden rounded-lg bg-gray-900 shadow outline outline-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <DropdownMenu.Label className="DropdownMenuLabel pl-2 pt-3 text-sm font-semibold text-gray-50 focus:ring-black">
            {t("menu.sortBy.sorterTitle")}
          </DropdownMenu.Label>
          {sorterChecked.map((item) => (
            <DropdownMenu.CheckboxItem
              key={item.id}
              checked={item.checked}
              onCheckedChange={() => handleSorterClick(item.id)}
              className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focus:ring-1 focus:ring-black focus:ring-opacity-5"
            >
              <div className="flex w-full items-center p-3 text-sm focus:bg-gray-700 focus:text-gray-100 focus:outline-none">
                <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                  <Icon name="check" size={12} weight="bold" />
                </DropdownMenu.ItemIndicator>
                <p className="mb-0 ml-2">{item.title}</p>
              </div>
            </DropdownMenu.CheckboxItem>
          ))}

          {(pathname === "/movies" ||
            pathname === "/shows" ||
            pathname === "/kids") && (
            <>
              <DropdownMenu.Label className="DropdownMenuLabel pl-2 pt-3 text-sm font-semibold text-gray-50 focus:ring-black">
                {t("menu.sortBy.filterTitle")}
              </DropdownMenu.Label>
              {availableGenres.map((item) => (
                <DropdownMenu.CheckboxItem
                  key={item.id}
                  checked={item.checked}
                  onCheckedChange={() => handleFilterClick(item.id)}
                  className="text-gray-400 hover:bg-gray-800 hover:text-gray-200 hover:outline-none focus:outline-none focus:ring-1 focus:ring-black focus:ring-opacity-5"
                >
                  <div className="flex w-full items-center p-3 text-sm focus:bg-gray-700 focus:text-gray-100 focus:outline-none">
                    <DropdownMenu.ItemIndicator className="DropdownMenuItemIndicator">
                      <Icon name="check" size={12} weight="bold" />
                    </DropdownMenu.ItemIndicator>
                    <p className="mb-0 ml-2">{item.title}</p>
                  </div>
                </DropdownMenu.CheckboxItem>
              ))}
            </>
          )}

          <button
            type="button"
            onClick={handleFilterOpenAndClose}
            className="h-12 bg-indigo-700 text-indigo-100 focus:bg-gray-700 focus:text-gray-100"
          >
            Apply Filter
          </button>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default React.memo(FilterDropdown);
