import config from "../../../mcvod.config.json";

export const APP_NAME = config.name;
export const TAGLINE_MAIN = config.tagline;
export const ORGANIZATION = config.organization;
export const ORGANIZATION_ID = config.organizationId;
export const SUPPORT_EMAIL_ADDRESS = config.supportEmailAddress;
export const PAGE_SIZE_ROW = 24;
export const PAGE_SIZE = 20;
export const NUM_SLIDES = 6;
export const MC_CAMPAIGN_URL =
  "https://miraclechannel.kindful.com/?campaign=1118917";
export const BASE_IMG_URL = "https://images.corcoplus.com";
export const BASE_STATIC_URL = "https://static.corcoplus.com";
export const POLLING_INTERVAL = 600000; // 10 minutes
export const LOGO = config.logo;
export const LOGO_ICON = config.logo_icon;

// (milli * sec)* mins = hrs
// (1000 * 60)*60 = 3,600,000 (1hr)
export const STALE_TIME = 3600000;

export const IMG_URI = config.imgUri;
