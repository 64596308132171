import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMaintenanceStore } from '@/store/useMaintenanceStore';
import config from '../../../../mcvod.config.json';
import { LoaderFunctionArgs } from 'react-router-dom';


/**
 * Loader for the maintenance page
 * If not in maintenance mode, redirect to home
 */
export const loader = ({ request }: LoaderFunctionArgs) => {
  // Only allow access to this page if we're in maintenance mode
  if (!isInMaintenanceMode()) {
    return { redirect: '/' };
  }
  return { maintenance: true };
};

interface MaintenancePageProps {
  isVisible?: boolean;
}

export const MaintenancePage: React.FC<MaintenancePageProps> = ({ isVisible }) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center min-h-screen w-full overflow-hidden text-white"
      style={{ overscrollBehavior: 'none' }} // Prevents scrolling
    >

      {/* Content Overlay */}
      <div className="relative z-10 max-w-2xl mx-auto px-4 text-center bg-card/50 rounded-xl shadow-lg py-8 outline outline-card-foreground/10 backdrop-blur-sm">
        <h1 className="text-4xl md:text-5xl font-bold mb-6 text-foreground">
          {t('maintenance.title', 'System Maintenance')}
        </h1>
        <div className="flex justify-center gap-2 mb-8">
          <div className="size-2 bg-primary rounded-full animate-bounce" style={{ animationDelay: '0s' }} />
          <div className="size-2 bg-primary rounded-full animate-bounce" style={{ animationDelay: '0.15s' }} />
          <div className="size-2 bg-primary rounded-full animate-bounce" style={{ animationDelay: '0.3s' }} />
        </div>
        <p className="text-xl md:text-2xl text-muted-foreground mb-6">
          {t('maintenance.message', 'Our system is currently undergoing maintenance. We apologize for the inconvenience and will be back shortly.')}
        </p>
        <p className="text-muted-foreground text-sm md:text-base">
          {t('maintenance.retry', 'Please try again later.')} <br />
          Expected return: March 8, 2025 <br />
          For questions or concerns, contact:{' '}
          <a href={`mailto:${config.supportEmailAddress}`} className="text-link hover:text-link-foreground">
            {config.supportEmailAddress}
          </a>
        </p>
      </div>
    </div>
  );
};

/**
 * Helper function to check if the application is in maintenance mode
 * This can be used in route loaders to redirect to a maintenance page
 */
export const isInMaintenanceMode = (): boolean => {
  return useMaintenanceStore.getState().isInMaintenance;
};

export default MaintenancePage;