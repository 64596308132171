/* eslint-disable camelcase */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import common_en from "./translation/en/common.json";
import common_fr from "./translation/fr/common.json";
// Import new language file here

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: common_en, // 'tanslation' is our custom namespace
      },
      fr: {
        translation: common_fr,
      },
    },

    // lng: localStorage.getItem('language'),
    fallbackLng: "en",
    debug: import.meta.env.NODE_ENV === "development",
    detection: {
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      order: ["queryString", "cookie", "localStorage"],
      cache: ["localStorage", "cookie"],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by defualt
    },
  });

export default i18n;
