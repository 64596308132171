import {
  ArrowDown,
  ArrowLeft,
  ArrowLineDown,
  ArrowLineLeft,
  ArrowLineRight,
  ArrowLineUp,
  ArrowRight,
  ArrowUp,
  Baby,
  BookmarksSimple,
  BookOpen,
  Broadcast,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  Check,
  ClosedCaptioning,
  Compass,
  DotsThreeVertical,
  Eye,
  EyeSlash,
  FastForward,
  FilmSlate,
  GearSix,
  Gift,
  IconWeight,
  Info,
  List,
  MagnifyingGlass,
  PaperPlaneTilt,
  Pause,
  IconProps as PhosphorIconProps,
  Play,
  PlayCircle,
  Plus,
  Rewind,
  SignIn,
  SignOut,
  SkipBack,
  SkipForward,
  SmileySad,
  SpeakerHigh,
  SpeakerLow,
  SpeakerNone,
  SpeakerSlash,
  TelevisionSimple,
  User,
  X,
  XCircle,
} from "phosphor-react";

const ICON_NAMES = [
  "discover",
  "movie",
  "series",
  "kid",
  "topic",
  "user",
  "settings",
  "chevronLeft",
  "chevronRight",
  "chevronUp",
  "chevronDown",
  "info",
  "gift",
  "bookmarks",
  "check",
  "arrowLineLeft",
  "arrowLineRight",
  "arrowLineUp",
  "arrowLineDown",
  "play",
  "playCircle",
  "pause",
  "skipForward",
  "skipBack",
  "fastForward",
  "rewind",
  "boadcast",
  "closedCaptioning",
  "volumeMute",
  "volumeNone",
  "volumeLow",
  "volumeHigh",
  "dotsThreeVertical",
  "signout",
  "signin",
  "close",
  "closeCircle",
  "frown",
  "search",
  "plus",
  "list",
  "send",
  "show",
  "hide",
  "arrowLeft",
  "arrowRight",
  "arrowUp",
  "arrowDown",
] as const;

type IconName = (typeof ICON_NAMES)[number];

interface IconProps extends Omit<PhosphorIconProps, "weight"> {
  name: IconName;
  alt?: string;
  className?: string;
  size?: number | string;
  weight?: IconWeight;
  color?: string;
}

/**
 * Icon component that renders Phosphor icons
 * @example
 * ```tsx
 * <Icon name="play" size={24} color="red" />
 * <Icon name="settings" weight="bold" className="text-blue-500" />
 * ```
 */

export const Icon = ({ name, ...restProps }: IconProps) => {
  const Icons: Record<IconName, JSX.Element> = {
    discover: <Compass {...restProps} />,
    movie: <FilmSlate {...restProps} />,
    series: <TelevisionSimple {...restProps} />,
    kid: <Baby {...restProps} />,
    topic: <BookOpen {...restProps} />,
    user: <User {...restProps} />,
    settings: <GearSix {...restProps} />,
    info: <Info {...restProps} />,
    bookmarks: <BookmarksSimple {...restProps} />,
    gift: <Gift {...restProps} />,
    check: <Check {...restProps} />,
    chevronLeft: <CaretLeft {...restProps} />,
    chevronRight: <CaretRight {...restProps} />,
    chevronUp: <CaretUp {...restProps} />,
    chevronDown: <CaretDown {...restProps} />,
    arrowLineLeft: <ArrowLineLeft {...restProps} />,
    arrowLineRight: <ArrowLineRight {...restProps} />,
    arrowLineUp: <ArrowLineUp {...restProps} />,
    arrowLineDown: <ArrowLineDown {...restProps} />,
    play: <Play {...restProps} />,
    playCircle: <PlayCircle {...restProps} />,
    pause: <Pause {...restProps} />,
    skipForward: <SkipForward {...restProps} />,
    skipBack: <SkipBack {...restProps} />,
    fastForward: <FastForward {...restProps} />,
    rewind: <Rewind {...restProps} />,
    boadcast: <Broadcast {...restProps} />,
    closedCaptioning: <ClosedCaptioning {...restProps} />,
    volumeMute: <SpeakerSlash {...restProps} />,
    volumeNone: <SpeakerNone {...restProps} />,
    volumeLow: <SpeakerLow {...restProps} />,
    volumeHigh: <SpeakerHigh {...restProps} />,
    dotsThreeVertical: <DotsThreeVertical {...restProps} />,
    signout: <SignOut {...restProps} />,
    signin: <SignIn {...restProps} />,
    close: <X {...restProps} />,
    closeCircle: <XCircle {...restProps} />,
    frown: <SmileySad {...restProps} />,
    search: <MagnifyingGlass {...restProps} />,
    plus: <Plus {...restProps} />,
    list: <List {...restProps} />,
    send: <PaperPlaneTilt {...restProps} />,
    show: <Eye {...restProps} />,
    hide: <EyeSlash {...restProps} />,
    arrowLeft: <ArrowLeft {...restProps} />,
    arrowRight: <ArrowRight {...restProps} />,
    arrowUp: <ArrowUp {...restProps} />,
    arrowDown: <ArrowDown {...restProps} />,
  };
  return Icons[name];
};
export type { IconName };
export { ICON_NAMES };
