import { create } from 'zustand';

interface MaintenanceState {
  isInMaintenance: boolean;
  setMaintenanceMode: (isInMaintenance: boolean) => void;
}

// Check if maintenance mode is enabled via environment variable
const isMaintenanceModeEnabled = () => {
  try {
    // Check for maintenance flag in environment variables
    return import.meta.env.VITE_APP_MAINTENANCE_MODE === 'true';
  } catch (error) {
    // If there's an error accessing env vars, default to false
    console.error('Error checking maintenance mode:', error);
    return false;
  }
};

export const useMaintenanceStore = create<MaintenanceState>((set) => ({
  // Initialize with environment variable if available
  isInMaintenance: isMaintenanceModeEnabled(),
  setMaintenanceMode: (isInMaintenance) => set({ isInMaintenance }),
}));