/*
  Content Page Component:
  - Renders the following pages:
    - Watchlist
    - Movies
    - Shows
    - Topics
    - Kids
*/

import React from "react";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDeleteWatchlist } from "@/api/mutations/watchlist";
import { useUserStore } from "@/store/useUserStore";
import { ContentGrid } from "./ContentGrid";
import { Spinner } from "@/components/common";
import useIntersectionObserver from "@/hooks/useIntersectionObserver";
import { APP_NAME } from "@/utils/constants";
import Thumbnail from "@/components/Thumbnail";
import ThumbnailTopic from "@/components/ThumbnailTopic";
import { ButtonRound } from "@/components/common/Button";
import { Icon } from "@mcvod-apps/icons";
import { requireAuth } from "@/utils/requireAuth";
import { contentQuery } from "@/features/content/queries/content";
import { queryClient } from "@/routes/routes";

export const pageLoader = (pageType) => {
  return async ({ request }) => {
    // Add this wrapper function
    document.title = `${pageType} | ${APP_NAME}`;
    await requireAuth(request);
    const url = new URL(request.url);
    const searchParams = url.searchParams;
    const query = contentQuery(pageType, searchParams);
    return queryClient.ensureQueryData(query);
  };
};

const EmptyState = ({ pageType }) => {
  if (pageType === "Watchlist") {
    return <WatchlistEmptyState />;
  }
  return <DefaultEmptyState />;
};

const ContentPageLayout = ({ children, pageType, pageDesc }) => {
  return (
    <ContentGrid pageType={pageType} pageDesc={pageDesc}>
      {children}
    </ContentGrid>
  );
};

export default function ContentPage({ pageType, pageDesc }) {
  const [searchParams] = useSearchParams();
  const { id: userId } = useUserStore((state) => state);
  const { mutate } = useDeleteWatchlist();

  const {
    data: contentData,
    error: contentError,
    isFetching: contentFetching,
    isLoading: contentLoading,
    isError: contentIsError,
    fetchNextPage: contentFetchNextPage,
    hasNextPage: contentHasNextPage,
    isFetchingNextPage: contentFetchingNextPage,
  } = useInfiniteQuery(contentQuery(pageType, searchParams.toString(), userId));

  const loadMoreButtonRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: () => {
      if (contentHasNextPage && !contentFetchingNextPage) {
        contentFetchNextPage();
      }
    },
    enabled: contentHasNextPage && !contentFetchingNextPage,
  });

  const renderThumbnail = (item) => {
    if (pageType === "Topics") {
      return (
        <ThumbnailTopic
          key={item.pk}
          {...item}
          image={item.images.thumbnail}
          count={item.episodes.count}
          className="relative h-full text-white"
        >
          <h2 className="absolute inset-0 flex items-center justify-center">
            {item.title}
          </h2>
        </ThumbnailTopic>
      );
    }

    if (pageType === "Watchlist") {
      return (
        <Thumbnail
          key={item.pk}
          {...item}
          image={item.content_object.images.thumbnail}
        >
          <div className="group absolute bottom-2 right-2 z-10 flex items-center justify-between">
            <ButtonRound
              size="rnd"
              varient="plain"
              IconStart={<Icon name="close" size={24} weight="bold" />}
              onClick={() => mutate(item.content_object._detail)}
              title="Remove"
            />
          </div>
        </Thumbnail>
      );
    }

    return (
      <Thumbnail
        key={item.pk}
        {...item}
        image={item.images.thumbnail}
        growOnHover
      />
    );
  };

  const hasNoResults = (data, pageType) => {
    if (pageType === "Watchlist") {
      return !data?.results || data.results.length === 0;
    }
    return !data?.pages?.[0]?.results || data.pages[0].results.length === 0;
  };

  if (contentLoading)
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <Spinner size={8} />
      </div>
    );
  if (contentIsError && contentError.statusCode !== 400)
    return (
      <div className="flex h-screen w-full items-center justify-center text-gray-500">
        <p>Error: {contentError.message}</p>
      </div>
    );
  if (hasNoResults(contentData, pageType) && pageType === "Watchlist") {
    return (
      <div className="flex h-screen w-full flex-col items-center justify-center text-gray-400">
        <Icon name="plus" size={100} weight="fill" className="text-gray-800" />
        <h4 className="text-3xl font-bold text-white">
          Your watchlist is empty{" "}
        </h4>
        <p> Add content to your watchlist and it will appear here.</p>
      </div>
    );
  }

  return (
    <ContentPageLayout pageType={pageType} pageDesc={pageDesc}>
      {contentLoading ? (
        <div className="flex h-screen w-full items-center justify-center">
          <Spinner size={8} />
        </div>
      ) : !contentData?.pages || contentData.pages.length === 0 ? (
        <div className="flex w-full items-center justify-center py-20 text-gray-500">
          <p>
            It looks like there are no results matching your criteria. Try
            adjusting your filter settings.
          </p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8">
            {contentData.pages.map((item) => (
              <React.Fragment key={item.pk}>
                {renderThumbnail(item)}
              </React.Fragment>
            ))}
          </div>
          <div
            className="flex items-center justify-center py-10 text-gray-500"
            ref={loadMoreButtonRef}
          >
            {contentFetchingNextPage ? (
              <div className="flex w-full items-center justify-center">
                <Spinner size={8} />
              </div>
            ) : contentHasNextPage ? (
              <button
                type="button"
                onClick={() => contentFetchNextPage()}
                disabled={!contentHasNextPage || contentFetchingNextPage}
              >
                Load more
              </button>
            ) : null}
          </div>
        </>
      )}
    </ContentPageLayout>
  );
}
